import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { getToken } from "./utils/auth";
import AuthProvider from "./providers/auth";
import { Toaster } from "react-hot-toast";
import DefaultDataProvider from "./providers/data";
import ProjectProvider from "./providers/project";
import { Button, createTheme, ThemeProvider, Typography, Box } from "@mui/material";
import * as Sentry from "@sentry/react";
import ErrorFallBack from "./pages/error/errorFallBack";
import { DatabaseProvider } from "./indexedDB/dexieWrapper";
import { Provider as JotaiProvider } from 'jotai';
import { useDataSyncCheck } from "./providers/dataSyncCheck";

if (navigator.onLine) {
  Sentry.init({
    dsn: "https://e868857b5d35415d01ff67e4f685a808@o4507889727832064.ingest.us.sentry.io/4507889764532224",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const requestPersistentStorage = async () => {
  if (navigator.storage && navigator.storage.persist) {
    const isPersisted = await navigator.storage.persisted();
    if (!isPersisted) {
      const granted = await navigator.storage.persist();
      if (granted) {
        console.log("Persistent storage granted.");
      } else {
        console.log("Persistent storage not granted.");
      }
    } else {
      console.log("Already using persistent storage.");
    }
  } else {
    console.log("Persistent storage is not supported on this browser.");
  }
};

// Call the persistent storage function before rendering the app
requestPersistentStorage();


axios.interceptors.request.use(async (request) => {
  request.baseURL = process.env["REACT_APP_API"] + "/api";
  let token = await getToken();
  request.headers["X-Device"] = "tablet";
  if (token && request.headers) {
    request.headers["Authorization"] = `Bearer ${token}`;
  }
  if (localStorage.getItem("project_id")) {
    request.headers["project_id"] = `${localStorage.getItem("project_id")}`;
  }
  return request;
});
const themeLight = createTheme({
  typography: {
    fontFamily: ["Rubik", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#F16253",
      color: "#1B1819",
    },
    mode: "light",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "50px",
        },
      },
    },
  },
});


const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  <JotaiProvider>
    <ThemeProvider theme={themeLight}>
      <DatabaseProvider>
        <Sentry.ErrorBoundary fallback={<ErrorFallBack />}>
          <AuthProvider>
            <ProjectProvider>
              <DefaultDataProvider>
                  <App />
                  <Toaster
                    position="bottom-center"
                    toastOptions={{ style: { color: "white", background: "#262223" } }}
                  />
              </DefaultDataProvider>
            </ProjectProvider>
          </AuthProvider>
        </Sentry.ErrorBoundary>
      </DatabaseProvider>
    </ThemeProvider>
  </JotaiProvider>
);

// root.render(
//   <JotaiProvider>
//   <ThemeProvider theme={themeLight}>
//     <DatabaseProvider>
//       <Sentry.ErrorBoundary fallback={<ErrorFallBack />}>
//         <AuthProvider>
//           <ProjectProvider>
//             <DefaultDataProvider>
//               <App />
//               <Toaster
//                 position="bottom-center"
//                 toastOptions={{ style: { color: "white", background: "#262223" } }}
//               />
//             </DefaultDataProvider>
//           </ProjectProvider>
//         </AuthProvider>
//       </Sentry.ErrorBoundary>
//     </DatabaseProvider>
//   </ThemeProvider>
//   </JotaiProvider>
// );


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
