import { atom } from "jotai";
import produce from "immer";
import suryadb from "../indexedDB/module";
import { get_construction_progress_activitywise_for_blocks, get_construction_progress_activitywise_for_project, get_construction_progress_activitywise_for_svg_block,  get_activity_milestone_for_selected_tracker_rows, get_activity_milestone_block, get_activity_milestone_project, get_distributed_and_installed_bom_project, get_total_installed_distributed_quantities, get_existing_distributed_installed_quantity_for_trackerrows, get_part_per_tracker_typewise, get_checklist_activity_for_construction, update_existing_distributed_installed_quantities, get_table_wise_pier_part_bom, get_activity_milestone_for_selected_blocks } from "../dal/constructionProgress";
import { get_tracker_types_for_block, get_tracker_types_for_project, get_tracker_types_name } from "../dal/trackerTypes";
import { get_blocks, get_bulk_piers_torquetubes, get_d3_xscale_yscale_blockwise, get_pier_legend_details, get_zimmerman_bulk_piers, get_zimmerman_table_coordinates_blockwise } from "../dal/layout";

const dashboardAtom = atom({
  block: [],
  currentBlock: "A",
  pierLegends: {},
  constructionProgressForProject: [],
  constructionProgressForBlock: [],
  trackerTypesForProject: [],
  trackerTypeNames: [],
  trackerTypesForBlock: [],
  activityMilestoneForBlock: [],
  activityMilestoneForProject: [],
  distributedInstalledBom: [],
  total_installed_distributed: [],
  existing_bom_per_tracker_row: {},
  part_per_tracker: [],
  selectedTrackersBom: [],
  getChecklistActivity: [],
  activityId: "All",
  svgBlockProgressActivityWise: [],
  constructionActId: "All",
  profileDetails: [],
  isAllTrackersSelected: false,
  updateDistributedAndInstalledQuantity: {},
  selectedPiersTorqueTubesData: {},
  listOfPiersTorqueTubeIdsWithStatus: {},
  bulkPiersTorqueTubesBlockData: {},
  d3XscaleYscaleSize: [],
  isGPSLocationEnabled: false,
  selectedTrackerTypeIds: [],
  previousSelectedTrackerTypeId: [],
  highLightSelectedTrackerTypeIds: [],
  zimmermanTableCoordinates:{},
  zimmermanBulkPiers:[],
  zimmermanSelectedPiersData:[],
  listOfZimmermanPierIdsWithStatus:[],
  zimmermanTableWisePierPartBom:{},
  selectedBlocksInSVG:[],
  bomForSelectedBlocks:[],
  clearSelectedTrackerRows:false,
  inprogressDeployedCount:{},
  isSubmitInprogressData:false
});

export const fetchConstructionProgressForProjectAtom = atom(null, async (get, set, args) => {
  let data = await get_construction_progress_activitywise_for_project()
  set(
    dashboardAtom,
    produce((draft) => {
      draft.constructionProgressForProject = data
    })
  );
});
export const fetchTrackerTypesForProjectAtom = atom(null, async (get, set, args) => {
  let data = await get_tracker_types_for_project()
  set(
    dashboardAtom,
    produce((draft) => {
      draft.trackerTypesForProject = data;
    })
  );
});
export const fetchTrackerTypeNamesAtom = atom(null, async (get, set, args) => {
  let data = await get_tracker_types_name()
  set(
    dashboardAtom,
    produce((draft) => {
      draft.trackerTypeNames = data;
    })
  );
});
export const fetchConstructionProgressForBlockAtom = atom(null, async (get, set, args) => {
  let data = await get_construction_progress_activitywise_for_blocks()
  set(
    dashboardAtom,
    produce((draft) => {
      draft.constructionProgressForBlock = data;
    })
  );
});

export const fetchTrackerTypesForBlockAtom = atom(null, async (get, set, args) => {
  let data = await get_tracker_types_for_block(args)
  set(
    dashboardAtom,
    produce((draft) => {
      draft.trackerTypesForBlock = data;
    })
  );
});

export const fetchBlockAtom = atom(null, async (get, set, args) => {
  let data = await get_blocks()
  set(
    dashboardAtom,
    produce((draft) => {
      draft.block = data;
    })
  );
});


export const fetchActivityMilestoneForBlockAtom = atom(null, async (get, set, args) => {
  let data = await get_activity_milestone_block()
  set(
    dashboardAtom,
    produce((draft) => {
      draft.activityMilestoneForBlock = data;
    })
  );
});
export const fetchActivityMilestoneForProjectAtom = atom(null, async (get, set, args) => {
  let data = await get_activity_milestone_project()
  set(
    dashboardAtom,
    produce((draft) => {
      draft.activityMilestoneForProject = data;
    })
  );
});

export const fetchDistributedInstalledBomAtom = atom(null, async (get, set, args) => {
  let data = await get_distributed_and_installed_bom_project()
  set(
    dashboardAtom,
    produce((draft) => {
      draft.distributedInstalledBom = data;
    })
  );
});

export const fetchTotalInstalledorDistributedAtom = atom(
  null,
  async (get, set, args) => {
    let data = await get_total_installed_distributed_quantities()
    set(
      dashboardAtom,
      produce((draft) => {
        draft.total_installed_distributed = data;
      })
    );
  }
);
export const fetchExistingDistrbutedInstalledAtom = atom(
  null,
  async (get, set, args) => {
    let data = await get_existing_distributed_installed_quantity_for_trackerrows()
    set(
      dashboardAtom,
      produce((draft) => {
        draft.existing_bom_per_tracker_row = data;
      })
    );
  }
);

export const updateExistingDistrbutedInstalledAtom = atom(
  null,
  async (get, set, args) => {
    let get_existing_data = await update_existing_distributed_installed_quantities(args)
  }
);


export const fetchPartPerTrackerAtom = atom(null, async (get, set, args) => {
  let data = await get_part_per_tracker_typewise()
  set(
    dashboardAtom,
    produce((draft) => {
      draft.part_per_tracker = data;
    })
  );
});
export const fetchPierLegendAtom = atom(null, async (get, set, args) => {
  let data = await get_pier_legend_details()
  set(
    dashboardAtom,
    produce((draft) => {
      draft.pierLegends = data;
    })
  );
});
export const fetchSelectedTrackerBomAtom = atom(null, async (get, set, args) => {
  let data = await get_activity_milestone_for_selected_tracker_rows(args)
  set(
    dashboardAtom,
    produce((draft) => {
      draft.selectedTrackersBom = data;
    })
  );
});



export const fetchChecklistActivityAtom = atom(null, async (get, set, args) => {
  let data = await get_checklist_activity_for_construction()
  set(
    dashboardAtom,
    produce((draft) => {
      draft.getChecklistActivity = data;
    })
  );
});

export const fetchActivityIdAtom = atom(null, async (get, set, args) => {
  set(
    dashboardAtom,
    produce((draft) => {
      draft.activityId = args;
    })
  );
});

export const fetchConstructionActivityIdAtom = atom(null, async (get, set, args) => {
  set(
    dashboardAtom,
    produce((draft) => {
      draft.constructionActId = args;
    })
  );
});
export const fetchSvgBlockProgressActivityWiseAtom = atom(null, async (get, set, args) => {
  let data = await get_construction_progress_activitywise_for_svg_block()
  set(
    dashboardAtom,
    produce((draft) => {
      draft.svgBlockProgressActivityWise = data
    })
  );
});

export const fetchProfileDetailsAtom = atom(null, async (get, set, args) => {
  let data = await suryadb().get_user_details.toArray();
  set(
    dashboardAtom,
    produce((draft) => {
      draft.profileDetails = data[0].data
    })
  );
});


export const fetchIsAllTrackersSelectedAtom = atom(null, async (get, set, args) => {
  set(
    dashboardAtom,
    produce((draft) => {
      draft.isAllTrackersSelected = args
    })
  );
});

export const updateDistributedAndInstalledQuantityAtom = atom(null, async (get, set, args) => {
  set(dashboardAtom,
    produce((draft) => {
      draft.updateDistributedAndInstalledQuantity = args
    }))
})

export const getListOfPiersTorqueTubeIdsWithStatusAtom = atom(null, async (get, set, args) => {
  set(
    dashboardAtom,
    produce((draft) => {
      draft.listOfPiersTorqueTubeIdsWithStatus = args
    })
  );
});
export const getSelectedPiersTorqueTubesDataAtom = atom(null, async (get, set, args) => {
  set(
    dashboardAtom,
    produce((draft) => {
      draft.selectedPiersTorqueTubesData = args
    })
  );
});

export const getListOfZimmermanPierIdsWithStatusAtom = atom(null, async (get, set, args) => {
  set(
    dashboardAtom,
    produce((draft) => {
      draft.listOfZimmermanPierIdsWithStatus = args
    })
  );
});

export const getZimmermanSelectedPiersDataAtom = atom(null, async (get, set, args) => {
  set(
    dashboardAtom,
    produce((draft) => {
      draft.zimmermanSelectedPiersData = args
    })
  );
});

export const fetchBulkPiersTorqueTubesBlockDataAtom = atom(null, async (get, set, args) => {
  let data = await get_bulk_piers_torquetubes(args)
  set(
    dashboardAtom,
    produce((draft) => {
      draft.bulkPiersTorqueTubesBlockData = data
    })
  );
});

export const fetchD3XscaleYscaleSizeAtom = atom(null, async (get, set, args) => {
  let data = await get_d3_xscale_yscale_blockwise(args)
  set(
    dashboardAtom,
    produce((draft) => {
      draft.d3XscaleYscaleSize = data
    })
  );
});
export const fetchGPSLocationToggleStatusAtom = atom(null, async (get, set, args) => {
  set(
    dashboardAtom,
    produce((draft) => {
      draft.isGPSLocationEnabled = args
    })
  );
});

export const fetchSelectedTrackerTypeIdsAtom = atom(null, async (get, set, args) => {
  set(
    dashboardAtom,
    produce((draft) => {
      draft.selectedTrackerTypeIds = args
    })
  );
});

export const fetchPreviousSelectedTrackerTypeIdAtom = atom(null, async (get, set, args) => {
  set(
    dashboardAtom,
    produce((draft) => {
      draft.previousSelectedTrackerTypeId = args
    })
  );
});

export const fetchHighLightSelectedTrackerTypeIdsAtom = atom(null, async (get, set, args) => {
  set(
    dashboardAtom,
    produce((draft) => {
      draft.highLightSelectedTrackerTypeIds = args
    })
  );
});

export const fetchZimmermanTableCoordinatesAtom = atom(null, async (get, set, args) => {
  let data = await get_zimmerman_table_coordinates_blockwise(args)
  set(
    dashboardAtom,
    produce((draft) => {
      draft.zimmermanTableCoordinates = data
    })
  );
});

export const fetchZimmermanBulkPiersAtom = atom(null, async (get, set, args) => {
  let data = await get_zimmerman_bulk_piers(args)
  set(
    dashboardAtom,
    produce((draft) => {
      draft.zimmermanBulkPiers = data
    })
  );
});

export const fetchZimmermanTableWisePierPartBomAtom = atom(null, async (get, set, args) => {
  let data = await get_table_wise_pier_part_bom()
  set(
    dashboardAtom,
    produce((draft) => {
      draft.zimmermanTableWisePierPartBom = data
    })
  );
});

export const updateSelectedBlocksInSVGAtom = atom(null, async (get, set, args) => {
  set(
    dashboardAtom,
    produce((draft) => {
      draft.selectedBlocksInSVG = args
    })
  );
});


export const fetchBomForSelectedBlocksAtom = atom(null, async (get, set, args) => {
  let data = await get_activity_milestone_for_selected_blocks(args)
  set(
    dashboardAtom,
    produce((draft) => {
      draft.bomForSelectedBlocks = data;
    })
  );
});

export const updateClearSelectedTrackerRowsAtom = atom(null, async (get, set, args) => {
  set(
    dashboardAtom,
    produce((draft) => {
      draft.clearSelectedTrackerRows = args;
    })
  );
});

export const updateInprogressDeployedCountAtom = atom(null, async (get, set, args) => {
  set(
    dashboardAtom,
    produce((draft) => {
      draft.inprogressDeployedCount = args;
    })
  );
});

export const updateSubmitInprogressAtom = atom(null, async (get, set, args) => {
  set(
    dashboardAtom,
    produce((draft) => {
      draft.isSubmitInprogressData = args;
    })
  );
});


export default dashboardAtom;
