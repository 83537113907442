import {
    Box,
    Grid,
    Typography,
    Divider,
    TableHead,
    TableRow,
    TableCell,
    Table,
    TableBody,
    TableContainer,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TextField,
    Button,
    IconButton,
    Modal,
    Checkbox,
    CircularProgress,
    Paper,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import Header from "../../components/appBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import MessageIcon from "@mui/icons-material/Message";
import ImageViewer from "react-simple-image-viewer";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import SegmentedControl from "../../components/qcPunchlist/segmentedControl";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import { MdOutlineSubdirectoryArrowRight } from "react-icons/md"
import { useAtom } from "jotai";
import { produce } from "immer";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import activitiesAtom, { fetchActivitiesAtom } from "../../data/activitiesAtom";
import { insertQcQueueAtom, insertImagesQueueAtom, deletePunchlistAtom } from "../../data/qcQueueAtom";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from 'react-hook-form';
import { TbFilterOff } from "react-icons/tb"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import convertEpochToTimeZone from "../../utils/epocTimeConvertor";
import projectAtom, { fetchProjectDetailsAtom } from "../../data/projectAtom";
import { delete_images, get_list_of_images, get_user_generated_id_for_uploaded_images, offline_add_images } from "../../dal/images";
import qcChecklistAtom, { fetchQcPunchlistAtom, fetchQcImageUrlsAtom, deleteQcImageUrlsAtom, fetchQCPunchlistFilterConditionsAtom, fetchQCD3ColorAtom, fetchQCBlockIdAtom, fetchQCViewAtom } from "../../data/qualityControlAtom";
import { get_blockId, get_label_name, get_punchlist_key, insert_qc_d3_color } from "../../dal/qualityCheckProgress";
import dashboardAtom, { fetchBlockAtom, fetchHighLightSelectedTrackerTypeIdsAtom, fetchPreviousSelectedTrackerTypeIdAtom, fetchSelectedTrackerTypeIdsAtom } from "../../data/dashboardAtom";
import { MdEditNote } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { MdAddAPhoto } from "react-icons/md";
import selectedRowsAtom, { updateSelectedRowsAtom } from "../../data/selectedRowsAtom";
import QCMapView from "./qualityControl/qcMapView";
import { isOnline } from "../../dal/checkIsOnline";
import ListAltIcon from '@mui/icons-material/ListAlt';
import MapIcon from '@mui/icons-material/Map';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import '../../components/customDatePicker.css';
import "react-datepicker/dist/react-datepicker.css";

const QCPunchlist = () => {


    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const { register, handleSubmit, reset } = useForm()
    const navigate = useNavigate();
    const ref = useRef()
    const [dashboard] = useAtom(dashboardAtom)
    const [, fetchActivities] = useAtom(fetchActivitiesAtom);
    const [, insertQcQueue] = useAtom(insertQcQueueAtom);
    const [, fetchQcImageUrls] = useAtom(fetchQcImageUrlsAtom)
    const [projectDetails] = useAtom(projectAtom);
    const [, fetchProjectDetails] = useAtom(fetchProjectDetailsAtom)
    const [, deletePunchlist] = useAtom(deletePunchlistAtom)
    const [qcChecklist, setQcChecklist] = useAtom(qcChecklistAtom)
    const [, fetchQcPunchlist] = useAtom(fetchQcPunchlistAtom)
    const [photoIdsArray, setPhotoIdsArray] = useState([])
    const [imagesUrl, setImagesUrl] = useState({})
    const [, insertImagesQueue] = useAtom(insertImagesQueueAtom)
    const [, deleteQcImageUrls] = useAtom(deleteQcImageUrlsAtom)
    const [currentImage, setCurrentImage] = useState(0);
    const [visibility, setVisibility] = useState(0);
    const [commentValue, setCommentValue] = useState("");
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [ImageView, setImage] = useState([]);
    const [blockIdsList, setBlockIdsList] = useState([])
    const [setionIdsList, setSectionIdsList] = useState([])
    const [rowIdsList, setRowIdsList] = useState([])
    const [sectionId, setSectionId] = useState("All");
    const [scopeFilter, setScopeFilter] = useState("All");
    const [scopeList, setScopeList] = useState([])
    const [checkpointFilter, setCheckpointFilter] = useState("All")
    const [checkpointList, setCheckpointList] = useState([])
    const [blockName, setBlockName] = useState("All");
    const [blockId, setBlockId] = useState("All")
    const [rowId, setRowId] = useState("All");
    const [statusFilter, setStatusFilter] = useState("All");
    const [openCommentsPhotosModal, setOpenCommentsPhotosModal] = useState(false);
    const [loading, setLoading] = useState(false)
    const [selectedPunchlistItem, setSelectedPunchlistItem] = useState({})
    const [currentRowData, setCurrentRowData] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [timeZone, setTimeZone] = useState("")
    const [view, setView] = useState(1)
    const [scope, setScope] = useState("All")
    const [showSVG, setShowSVG] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null);
    const [, fetchQCPunchlistFilterConditions] = useAtom(fetchQCPunchlistFilterConditionsAtom)
    const [, fetchBlocks] = useAtom(fetchBlockAtom)
    const [, fetchSelectedTrackerTypeIds] = useAtom(fetchSelectedTrackerTypeIdsAtom)
    const [, fetchPreviousSelectedTrackerTypeId] = useAtom(fetchPreviousSelectedTrackerTypeIdAtom)
    const [, fetchHighLightSelectedTrackerTypeIds] = useAtom(fetchHighLightSelectedTrackerTypeIdsAtom)
    const [, updateSelectedRows] = useAtom(updateSelectedRowsAtom)
    const [, fetchQCD3Color] = useAtom(fetchQCD3ColorAtom)
    const [, fetchQCBlockId] = useAtom(fetchQCBlockIdAtom)
    const [, fetchQCView] = useAtom(fetchQCViewAtom)
    const [selectedRows] = useAtom(selectedRowsAtom);
    const [punchlistDefaultIndex, setPunchlistDefaultIndex] = useState(0)
    const [viewDefaultIndex, setViewDefaultIndex] = useState(0)
    const params = useParams()
    const punchlistControlRef = useRef();
    const viewControlRef = useRef();

    // Fetch the accesstokens to cross check org_id
    let projectId = localStorage.getItem("project_id")
    const handleDeleteModalOpen = () => {
        setOpenDeleteModal(true)
    }
    const handleDeleteModalClose = () => {
        setOpenDeleteModal(false)
    }


    const findBlockNameByBlockId = (blockId) => {
        if (dashboard?.block.length !== 0) {
            let array = dashboard?.block
            const item = array.find(element => element?.id === blockId);
            return item ? item.data.name : null;
        }
    };
    useEffect(() => {
        setVisibility(qcChecklist?.qcPunchlistFilterConditions?.visibility)
        if (qcChecklist?.qcPunchlistFilterConditions?.section !== undefined) {
            setSectionId(qcChecklist?.qcPunchlistFilterConditions?.section)
        }
        if (qcChecklist?.qcPunchlistFilterConditions?.row !== undefined) {
            setRowId(qcChecklist?.qcPunchlistFilterConditions?.row)
        }
        if (qcChecklist?.qcPunchlistFilterConditions?.scope !== undefined) {
            setScopeFilter(qcChecklist?.qcPunchlistFilterConditions?.scope)
        }
        if (qcChecklist?.qcPunchlistFilterConditions?.checkpoint_name !== undefined) {
            setCheckpointFilter(qcChecklist?.qcPunchlistFilterConditions?.checkpoint_name)
        }
        if (qcChecklist?.qcPunchlistFilterConditions?.block_id !== undefined) {
            if (dashboard?.block) {
                for (let i of dashboard?.block) {
                    if (parseInt(i?.id) === qcChecklist?.qcPunchlistFilterConditions?.block_id) {
                        setBlockName(i?.data?.name)
                        if (qcChecklist?.qcPunchlistFilterConditions?.punchlist_status !== undefined) {
                            setStatusFilter(qcChecklist?.qcPunchlistFilterConditions?.punchlist_status)
                        }
                    }
                }
            }
        }

    }, [dashboard?.block, view, visibility])

    useEffect(() => {
        let index = 1
        if (parseInt(visibility) === 1) {
            index = 0
        }
        setPunchlistDefaultIndex(index)
    }, [visibility])
    const handleBackButton = async () => {
        await fetchPreviousSelectedTrackerTypeId([])
        await fetchSelectedTrackerTypeIds([])
        await fetchHighLightSelectedTrackerTypeIds([])
        await updateSelectedRows([])
        localStorage.setItem("punchlist-visibility-index", 0)
        if (params.id !== undefined) {
            navigate(`/home/gpslocation/${params.id}/qcdata`)
        } else {
            navigate(`/home/qcdata`)
        }
    }
    const updateSelectedRow = async () => {
        await updateSelectedRows([])
    }
    useEffect(() => {
        if (qcChecklist?.qcView === 1) {
            setViewDefaultIndex(0)
            setView(1)
            handleChangeView(1)
            updateSelectedRow()
        }
    }, [qcChecklist.qcView])

    useEffect(() => {
        if (openCommentsPhotosModal === true) {
            // let pierOrTorqueTubeId = currentRowData[0]?.pier_id
            // if (currentRowData[0]?.scope === "structure" || currentRowData[0]?.scope === "module") {
            //     pierOrTorqueTubeId = currentRowData[0]?.tt_id
            // }
            let key = get_punchlist_key(currentRowData[0])
            setCurrentRowData([qcChecklist?.qcPunchlist[key]])
        }
    }, [qcChecklist?.qcPunchlist])

    const init = async () => {
        setLoading(true)
        let filterConditions = qcChecklist?.qcPunchlistFilterConditions
        try {
            await fetchActivities();
            await fetchQcPunchlist(filterConditions)
            await fetchBlocks()
            await fetchProjectDetails()
            setLoading(false)
            setSelectedPunchlistItem({})
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (parseInt(view) === 1) {
            getFilteredQcPunchlistData()
        }
    }, [visibility, qcChecklist?.qcPunchlistFilterConditions])

    useEffect(() => {
        if (Object.keys(qcChecklist?.qcPunchlist).length !== 0) {
            getFilteredListOfIds()
        }
    }, [qcChecklist?.qcPunchlist])

    const handleVisibilityFilter = async (value) => {
        let index = 0
        if (value === 0) {
            index = 1
        }
        setPunchlistDefaultIndex(index)
        localStorage.setItem("punchlist-visibility-index", index)
        let filterConditionObj = {}
        setVisibility(value);
        setBlockName("All");
        setRowId("All");
        setSectionId("All");
        setBlockId("All")
        if (Object.keys(qcChecklist?.qcPunchlistFilterConditions).length === 0) {
            filterConditionObj["visibility"] = value
            if (blockId !== "All") {
                filterConditionObj["block_id"] = blockId
            }
        } else {
            filterConditionObj = { ...qcChecklist?.qcPunchlistFilterConditions, visibility: value }
        }
        await fetchQCPunchlistFilterConditions(filterConditionObj)
    }

    const handleBlockIdFilter = async (e) => {
        let filterConditionObj = {}
        setBlockName(e.target.value)
        let selectedBlockId = await get_blockId(e?.target.value)
        setBlockId(selectedBlockId)
        if (e.target.value !== "All") {
            if (Object.keys(qcChecklist?.qcPunchlistFilterConditions).length === 0) {
                // setFilterConditions({ block_id: selectedBlockId })
                filterConditionObj["block_id"] = selectedBlockId
            } else {
                // setFilterConditions(previousValue => ({ ...previousValue, block_id: selectedBlockId }))
                filterConditionObj = { ...qcChecklist?.qcPunchlistFilterConditions, block_id: selectedBlockId }
            }

        } else {
            for (let i in qcChecklist?.qcPunchlistFilterConditions) {
                if (i !== "block_id") {
                    filterConditionObj[i] = qcChecklist?.qcPunchlistFilterConditions[i]
                }
            }
        }
        await fetchQCPunchlistFilterConditions(filterConditionObj)
    }
    const handleSectionIdFilter = async (e) => {
        setSectionId(e.target.value);
        let filterConditionObj = {}
        // if (Object.keys(filterConditions).length === 0) {
        //     setFilterConditions({ section: e.target.value })
        // } else {
        //     setFilterConditions(previousValue => ({ ...previousValue, section: e.target.value }))
        // }
        if (e.target.value !== "All") {
            if (Object.keys(qcChecklist?.qcPunchlistFilterConditions).length === 0) {
                filterConditionObj["section"] = e.target.value
            } else {
                filterConditionObj = { ...qcChecklist?.qcPunchlistFilterConditions, section: e.target.value }
            }
        }
        else {
            for (let i in qcChecklist?.qcPunchlistFilterConditions) {
                if (i !== "section") {
                    filterConditionObj[i] = qcChecklist?.qcPunchlistFilterConditions[i]
                }
            }
        }
        await fetchQCPunchlistFilterConditions(filterConditionObj)
    }

    const handleRowIdFilter = async (e) => {
        let filterConditionObj = {}
        setRowId(e.target.value);
        // if (Object.keys(filterConditions).length === 0) {
        //     setFilterConditions({ row: e.target.value })
        // } else {
        //     setFilterConditions(previousValue => ({ ...previousValue, row: e.target.value }))
        // }
        if (e.target.value !== "All") {
            if (Object.keys(qcChecklist?.qcPunchlistFilterConditions).length === 0) {
                filterConditionObj["row"] = e.target.value
            } else {
                filterConditionObj = { ...qcChecklist?.qcPunchlistFilterConditions, row: e.target.value }
            }
        } else {
            for (let i in qcChecklist?.qcPunchlistFilterConditions) {
                if (i !== "row") {
                    filterConditionObj[i] = qcChecklist?.qcPunchlistFilterConditions[i]
                }
            }
        }
        await fetchQCPunchlistFilterConditions(filterConditionObj)

    }

    const handleScopeFilter = async (e) => {
        let filterConditionObj = {}
        setScopeFilter(e.target.value);

        if (e.target.value !== "All") {
            if (Object.keys(qcChecklist?.qcPunchlistFilterConditions).length === 0) {
                filterConditionObj["scope"] = e.target.value
            } else {
                filterConditionObj = { ...qcChecklist?.qcPunchlistFilterConditions, scope: e.target.value }
            }
        } else {
            for (let i in qcChecklist?.qcPunchlistFilterConditions) {
                if (i !== "scope") {
                    filterConditionObj[i] = qcChecklist?.qcPunchlistFilterConditions[i]
                }
            }
        }
        await fetchQCPunchlistFilterConditions(filterConditionObj)

    }
    const handleCheckpointFilter = async (e) => {
        let filterConditionObj = {}
        setCheckpointFilter(e.target.value);

        if (e.target.value !== "All") {
            if (Object.keys(qcChecklist?.qcPunchlistFilterConditions).length === 0) {
                filterConditionObj["checkpoint_name"] = e.target.value
            } else {
                filterConditionObj = { ...qcChecklist?.qcPunchlistFilterConditions, checkpoint_name: e.target.value }
            }
        } else {
            for (let i in qcChecklist?.qcPunchlistFilterConditions) {
                if (i !== "checkpoint_name") {
                    filterConditionObj[i] = qcChecklist?.qcPunchlistFilterConditions[i]
                }
            }
        }
        await fetchQCPunchlistFilterConditions(filterConditionObj)

    }

    const getFilteredQcPunchlistData = async () => {
        try {
            let filterConditions = qcChecklist?.qcPunchlistFilterConditions
            setLoading(true)
            await fetchQcPunchlist(filterConditions)
            setLoading(false)
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        initData()
    }, [visibility, blockId])

    const initData = async () => {
        try {

            await fetchQcImageUrls({ blockId, visibility })

        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (projectDetails?.projectDetailsDb) {
            for (let i of projectDetails.projectDetailsDb) {
                if (i?.id === parseInt(projectId)) {
                    setTimeZone(i?.data?.timezone)
                }
            }
        }
    }, [visibility])

    // Punchlist status filter
    const handlePunchlistStatusFilter = async (e) => {
        let filterConditionObj = {}
        setStatusFilter(e.target.value);
        // if (Object.keys(filterConditions).length === 0) {
        //     setFilterConditions({ punchlist_status: e.target.value })
        // } else {
        //     setFilterConditions(previousValue => ({ ...previousValue, punchlist_status: e.target.value }))
        // }
        if (e.target.value !== "All") {
            if (Object.keys(qcChecklist?.qcPunchlistFilterConditions).length === 0) {
                filterConditionObj["punchlist_status"] = e.target.value
            } else {
                filterConditionObj = { ...qcChecklist?.qcPunchlistFilterConditions, punchlist_status: e.target.value }
            }

        }
        else {
            for (let i in qcChecklist?.qcPunchlistFilterConditions) {
                if (i !== "punchlist_status") {
                    filterConditionObj[i] = qcChecklist?.qcPunchlistFilterConditions[i]
                }
            }
        }
        await fetchQCPunchlistFilterConditions(filterConditionObj)
    };


    // Previous Image is showed in Image Viewer
    const onHandleLeftArrow = (index, img) => {
        if (index === 0) {
            setCurrentImage(index + img.length - 1);
        } else {
            setCurrentImage(index - 1);
        }
    };
    // Next Image is showed in Image Viewer
    const onHandleRightArrow = (index, img) => {
        if (index === img.length - 1) {
            setCurrentImage(index - img.length + 1);
        } else {
            setCurrentImage(index + 1);
        }
    };

    const getMapType = async (data) => {
        let map_type = 1
        if (data?.pier_id === undefined && data?.tt_id === undefined) {
            map_type = 0
        }
        return map_type
    }
    // Deletes Image
    const handleImageDelete = async (currentImageIndex) => {
        let data = currentRowData[0]
        const blockId = data?.block_id
        let key = get_punchlist_key(data)
        let pierTorqueTubeKey = await getPierOrTorqueTubeKeyName(data)
        let currentPhotosArray = []
        let currentImageUrls = {}
        let uploadId = 0
        if (data?.document && data?.document.length > 0) {
            currentPhotosArray = [...data?.document];
            currentPhotosArray = currentPhotosArray.filter((_, index) => {
                uploadId = currentPhotosArray[currentImageIndex]
                return index !== currentImageIndex
            });
        }
        for (let i of currentPhotosArray) {
            currentImageUrls[i] = qcChecklist?.qcImageUrls[i]
        }

        setQcChecklist((produce((draft) => {
            draft.qcPunchlist[key].document = currentPhotosArray
        })))
        await deleteQcImageUrls({ blockId, visibility, uploadId })
        await initData()
        // Add To Queue
        await insertQcQueue({
            checkpoint_id: data?.checkpoint_id,
            acceptance_criteria: data?.acceptance_crieteria ? data?.acceptance_crieteria : data?.acceptance_criteria,
            checkpoint_name: data?.checkpoint_name,
            status: data?.status,
            comments: commentValue ? commentValue : data?.comments,
            photos: currentPhotosArray,
            imageUrls: currentImageUrls,
            visibility: visibility,
            punchlist_status: data?.punchlist_status,
            row: data?.row,
            section: data?.section,
            block_name: data?.block_name,
            block_id: data?.block_id,
            tracker_row_id: data?.tracker_row_id,
            scope: data?.scope,
            [pierTorqueTubeKey]: data[pierTorqueTubeKey],
            created_at: data?.created_at,
            qc_type: "qc",
            map_type: data?.map_type ? data?.map_type : await getMapType(data)
        });
        setIsViewerOpen(false);
    };

    // Open Edit Modal
    const handleModal = () => {
        setOpenCommentsPhotosModal(false)
        setCurrentRowData([])
    }

    //   Opens modal and stores previous comment data in state 
    const handleClickComment = (data) => {
        setOpenCommentsPhotosModal(true)
        setCurrentRowData([data])
    };


    // Select punchlist item
    const handleSelectPunchlistItem = (e, data) => {
        let selectedPunchlistItemKeys = []

        if (Object.keys(selectedPunchlistItem).length > 0 && selectedPunchlistItem[data?.block_id]) {
            if (selectedPunchlistItem[data?.block_id].includes(e.target.name)) {
                selectedPunchlistItemKeys = selectedPunchlistItem[data?.block_id].filter((item) => item !== e.target.name)
            } else {
                let previousPunchlistItemKeys = selectedPunchlistItem[data?.block_id]
                selectedPunchlistItemKeys = [...previousPunchlistItemKeys, e?.target.name]
            }
        }
        else {
            selectedPunchlistItemKeys.push(e.target.name)
        }

        selectedPunchlistItemKeys = [...new Set(selectedPunchlistItemKeys)]
        if (Object.keys(selectedPunchlistItem).length > 0) {
            if (selectedPunchlistItemKeys.length === 0) {
                setSelectedPunchlistItem({})
            } else {
                setSelectedPunchlistItem(previousState => ({ ...previousState, [data?.block_id]: selectedPunchlistItemKeys }))
            }
        } else {

            setSelectedPunchlistItem({ [data?.block_id]: selectedPunchlistItemKeys })

        }

    }
    useEffect(() => {
        getSeletedPunchlistItemsLength()
    }, [selectedPunchlistItem])

    const getSeletedPunchlistItemsLength = () => {
        let lengthCount = 0
        Object.values(selectedPunchlistItem).map((i) => {
            lengthCount += i?.length
        })
        return lengthCount
    }

    //Moves to public/internal punchlist
    const onSubmit = async (value) => {
        let selectedRowId = []
        Object.keys(value).map((j) => {
            if (value[j] === true) {
                selectedRowId.push(j)
            }
        })

        try {
            setLoading(true)

            for (let i of selectedRowId) {
                if (qcChecklist.qcPunchlist[i]) {
                    let pierTorqueTubeKey = await getPierOrTorqueTubeKeyName(qcChecklist.qcPunchlist[i])
                    await insertQcQueue({
                        checkpoint_id: qcChecklist.qcPunchlist[i].checkpoint_id,
                        acceptance_criteria: qcChecklist.qcPunchlist[i]?.acceptance_crieteria ? qcChecklist.qcPunchlist[i]?.acceptance_crieteria : qcChecklist.qcPunchlist[i]?.acceptance_criteria,
                        checkpoint_name: qcChecklist.qcPunchlist[i].checkpoint_name,
                        status: qcChecklist.qcPunchlist[i].status,
                        comments: qcChecklist.qcPunchlist[i].comments,
                        photos: qcChecklist.qcPunchlist[i]?.document,
                        imageUrls: {},
                        visibility: visibility === 0 ? 1 : 0,
                        punchlist_status: qcChecklist.qcPunchlist[i].punchlist_status,
                        row: qcChecklist.qcPunchlist[i].row,
                        section: qcChecklist.qcPunchlist[i].section,
                        block_name: qcChecklist.qcPunchlist[i].block_name,
                        block_id: qcChecklist.qcPunchlist[i].block_id,
                        tracker_row_id: qcChecklist.qcPunchlist[i].tracker_row_id,
                        scope: qcChecklist.qcPunchlist[i].scope,
                        [pierTorqueTubeKey]: qcChecklist.qcPunchlist[i][pierTorqueTubeKey],
                        created_at: qcChecklist.qcPunchlist[i].created_at,
                        qc_type: "qc",
                        map_type: qcChecklist.qcPunchlist[i]?.map_type ? qcChecklist.qcPunchlist[i]?.map_type : await getMapType(qcChecklist.qcPunchlist[i])
                    });
                    setQcChecklist((produce((draft) => {
                        draft.qcPunchlist[i].visibility = visibility === 0 ? 1 : 0
                    })))
                }
            }
            setLoading(false)
            setSelectedPunchlistItem({})
            reset()
        }
        catch (err) {
            console.log(err)
        }

    }
    const getPierOrTorqueTubeKeyName = async (data) => {
        let pierOrTorqueTubeIdKeyName = "tracker_row_id"
        if (data?.pier_id !== undefined) {
            pierOrTorqueTubeIdKeyName = "pier_id"
        }
        if (data?.tt_id !== undefined) {
            pierOrTorqueTubeIdKeyName = "tt_id"
        }
        return pierOrTorqueTubeIdKeyName

    }


    // Add Comment
    const handleSubmitCommentsPhotos = async (i) => {
        let key = get_punchlist_key(i)
        let pierTorqueTubeKey = await getPierOrTorqueTubeKeyName(i)

        if (commentValue) {
            setQcChecklist(produce((draft) => {
                draft.qcPunchlist[key].comments = commentValue
            }))
        }

        try {
            await insertQcQueue({
                checkpoint_id: i?.checkpoint_id,
                acceptance_criteria: i?.acceptance_crieteria ? i?.acceptance_crieteria : i?.acceptance_criteria,
                checkpoint_name: i?.checkpoint_name,
                status: i?.status,
                comments: commentValue ? commentValue : i?.comments,
                photos: photoIdsArray.length !== 0 ? photoIdsArray : i?.document,
                imageUrls: await get_list_of_images(i?.block_id, visibility, i?.document),
                visibility: visibility,
                punchlist_status: i?.punchlist_status,
                row: i?.row,
                section: i?.section,
                block_name: i?.block_name,
                block_id: i?.block_id,
                tracker_row_id: i?.tracker_row_id,
                scope: i?.scope,
                [pierTorqueTubeKey]: i[pierTorqueTubeKey],
                created_at: i?.created_at,
                qc_type: "qc",
                map_type: i?.map_type ? i?.map_type : await getMapType(i)

            });
            setCurrentRowData([])

        } catch (err) {
            console.log(err);
        }
    };

    // Open image viewer 
    const openImageViewer = (index, i) => {
        setCurrentRowData([i])
        let imagesList = []
        if (i?.document.length > 0) {
            for (let j of i?.document) {
                if (qcChecklist?.qcImageUrls[j]) {
                    imagesList.push(qcChecklist?.qcImageUrls[j])
                }
            }
        }
        setImage(imagesList);
        setCurrentImage(index);
        setIsViewerOpen(true);
    };

    // Close image viewer
    const closeImageViewer = () => {
        setImage(0);
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    // Add Images
    const onImageChange = async (e, i) => {
        const blockId = i?.block_id
        let file = [...e.target.files];
        let key = get_punchlist_key(i)
        let pierTorqueTubeKey = await getPierOrTorqueTubeKeyName(i)
        let photoIds = []
        let existingPhotos = i?.document
        let imageObj = await get_user_generated_id_for_uploaded_images(file)
        if (existingPhotos !== undefined && existingPhotos.length > 0) {
            for (let i of existingPhotos) {
                imageObj[i] = qcChecklist?.qcImageUrls[i]
            }
        }
        for (let i in imageObj) {
            photoIds.push(i)
        }
        setPhotoIdsArray(photoIds)
        setImagesUrl(imageObj)

        setQcChecklist((produce((draft) => {
            draft.qcPunchlist[key].document = photoIds
        })))
        try {
            // await updateImage(imageObj)
            await insertImagesQueue({ blockId, visibility, imageObj })
            await initData()
            // Add data to queue
            await insertQcQueue({
                checkpoint_id: i?.checkpoint_id,
                acceptance_criteria: i?.acceptance_crieteria ? i?.acceptance_crieteria : i?.acceptance_criteria,
                checkpoint_name: i?.checkpoint_name,
                status: i?.status,
                comments: commentValue ? commentValue : i?.comments,
                photos: photoIds,
                imageUrls: imageObj,
                visibility: visibility,
                punchlist_status: i?.punchlist_status,
                row: i?.row,
                section: i?.section,
                block_name: i?.block_name,
                block_id: i?.block_id,
                tracker_row_id: i?.tracker_row_id,
                scope: i?.scope,
                [pierTorqueTubeKey]: i[pierTorqueTubeKey],
                created_at: i?.created_at,
                qc_type: "qc",
                map_type: i?.map_type ? i?.map_type : await getMapType(i)
            });

        } catch (err) {
            console.log(err);
        }
    }

    // Punchlist status change
    const handlePunchlistStatusChange = async (e, i) => {
        let key = get_punchlist_key(i)
        let pierTorqueTubeKey = await getPierOrTorqueTubeKeyName(i)
        // store the punchlist in the local state
        setQcChecklist((produce((draft) => {
            draft.qcPunchlist[key].punchlist_status = e.target.value
        })))
        if (e.target.value === 2)
            setQcChecklist((produce((draft) => {
                draft.qcPunchlist[key].status = 1
            })))

        try {

            let updatedStatus = i?.status

            if (e.target.value === 2) {
                updatedStatus = 1;
            }
            await insertQcQueue({
                checkpoint_id: i?.checkpoint_id,
                acceptance_criteria: i?.acceptance_crieteria ? i?.acceptance_crieteria : i?.acceptance_criteria,
                checkpoint_name: i?.checkpoint_name,
                status: updatedStatus,
                comments: i?.comments,
                photos: i?.document,
                imageUrls: await get_list_of_images(i?.block_id, visibility, i?.document),
                visibility: visibility,
                punchlist_status: e.target.value,
                row: i?.row,
                section: i?.section,
                block_name: i?.block_name,
                block_id: i?.block_id,
                tracker_row_id: i?.tracker_row_id,
                scope: i?.scope,
                [pierTorqueTubeKey]: i[pierTorqueTubeKey],
                created_at: i?.created_at,
                qc_type: "qc",
                map_type: i?.map_type ? i?.map_type : await getMapType(i)
            });

        } catch (err) {
            console.log(err);
        }
    };

    // filtered data 
    const getFilteredListOfIds = async () => {
        if (qcChecklist?.qcPunchlist) {
            let blockIds = new Set()
            let sectionIds = new Set()
            let rowIds = new Set()
            let scopeName = new Set()
            let checkpointName = new Set()
            Object.values(qcChecklist?.qcPunchlist).map(item => {
                let blockName = findBlockNameByBlockId(item?.block_id)
                blockIds.add(blockName);
                sectionIds.add(item?.section);
                rowIds.add(item?.row);
                scopeName.add(item?.scope)
                checkpointName.add(item?.checkpoint_name)

            });

            setBlockIdsList(Array.from(blockIds))
            setRowIdsList(Array.from(rowIds))
            setSectionIdsList(Array.from(sectionIds))
            setScopeList(Array.from(scopeName))
            setCheckpointList(Array.from(checkpointName))
        }

    }

    // Clear filter
    const handleClearFilter = async () => {
        setBlockName("All")
        setBlockId("All")
        setSectionId("All")
        setRowId("All")
        setStatusFilter("All")
        setScopeFilter("All")
        setCheckpointFilter("All")
        setSelectedDate(null)
        await fetchQCPunchlistFilterConditions({ visibility: visibility })
    }

    // Delete Punchlist
    const handleDeletePunchlist = async (statusValue) => {
        try {
            setLoading(true)
            await deletePunchlist({
                punchlistKey: selectedPunchlistItem,
                visibility: visibility,
                status: statusValue,
                qc_type: "qc"
            })
            await getFilteredQcPunchlistData()
            setSelectedPunchlistItem({})
            setLoading(false)
            setOpenDeleteModal(false)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (parseInt(view) === 0 && scope !== "All") {
            getQCProgressColors(visibility, scope)
        }
    }, [view, visibility, scope])
    const handleChangeView = async (val) => {
        let index = 0
        if (val === 0) {
            index = 1
        }
        setViewDefaultIndex(index)
        setView(val)

        // if (parseInt(val) === 0) {
        //     navigate(`/home/gpslocation/qcpunchlist/mapview`)
        // }
    }
    const handleSelectScope = (e) => {
        setScope(e.target.value)
    }
    const getQCProgressColors = async (qcVisibility, qcType) => {
        setLoading(true)
        try {
            await fetchQCD3Color({ visibility: qcVisibility, type: qcType, block_id: parseInt(qcChecklist?.qcBlockId) })
            setLoading(false)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (parseInt(view) === 0 && qcChecklist?.qcBlockId === "All") {
            updateSelectedQCBlockId()
        }
        updateQCView()
    }, [view])

    const updateQCView = async () => {
        if (parseInt(view) === 0) {
            await fetchQCView(0)
        }
    }
    useEffect(() => {
        if (selectedRows.length === 1 && view === 0) {
            getFilteredListView()
        }
    }, [selectedRows])
    const getFilteredListView = async () => {
        let trackerrowId = parseInt(selectedRows[0].split("-")[1])
        let rowId = parseInt(selectedRows[0].split("-")[3])
        let sectionId = parseInt(selectedRows[0].split("-")[4])
        setBlockId(qcChecklist?.qcBlockId)
        setSectionId(sectionId)
        setRowId(rowId)
        setStatusFilter("All")
        // setVisibility(visibility)
        let filterConditionObj = { visibility: visibility, block_id: qcChecklist?.qcBlockId, tracker_row_id: trackerrowId, section: sectionId, row: rowId }
        await fetchQCPunchlistFilterConditions(filterConditionObj)
        await fetchQcPunchlist(filterConditionObj)
    }

    const updateSelectedQCBlockId = async () => {
        await fetchQCBlockId("All")
    }
    const handleGetD3ColorOnline = async () => {
        isOnline(
            function () {
                toast.error("Please connect the device to the internet and try again")
            },
            async function () {
                try {
                    await insert_qc_d3_color(parseInt(qcChecklist?.qcBlockId))

                } catch (err) {
                    console.log(err)
                }
                getQCProgressColors(visibility, scope)
            }
        )
    }


    useEffect(() => {
        if (parseInt(view) === 0 && qcChecklist?.qcBlockId !== "All") {
            setScope("piers")
        } else {
            setScope("All")
        }

    }, [qcChecklist?.qcBlockId])

    const getBlockName = () => {
        let blockDetails = dashboard.block.find((y) => parseInt(y.id) === parseInt(qcChecklist?.qcBlockId));
        return blockDetails?.data?.name

    }

    const handleBackToSVGMapView = async () => {
        setShowSVG(!showSVG)
        await updateSelectedQCBlockId()
    }

    const handleDateChange = async (date) => {
        let selectedDate = new Date(date)
        setSelectedDate(selectedDate);
        selectedDate = formatDate(selectedDate)
        let filterConditionObj = {}
        if (date !== "All") {
            if (Object.keys(qcChecklist?.qcPunchlistFilterConditions).length === 0) {
                filterConditionObj["created_at"] = { date: selectedDate, timeZone: timeZone }
            } else {
                filterConditionObj = { ...qcChecklist?.qcPunchlistFilterConditions, created_at: { date: selectedDate, timeZone: timeZone } }
            }
        }
        else {
            for (let i in qcChecklist?.qcPunchlistFilterConditions) {
                if (i !== "created_at") {
                    filterConditionObj[i] = qcChecklist?.qcPunchlistFilterConditions[i]
                }
            }
        }
        await fetchQCPunchlistFilterConditions(filterConditionObj)
    };

    const formatDate = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    return (
        <Box sx={{ height: "100%", width: "100%" }}>
            <Header />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: "#FFFFFF",
                            color: "#1B1819",
                            height: "90px",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >



                            {view === 0 && qcChecklist?.qcBlockId !== "All"
                                ? <Box
                                    sx={{
                                        backgroundColor: "#262223",
                                        color: "white",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "flex-start",
                                        padding: "12px 5px 8px",
                                        height: "70px",
                                        width: "70px",
                                        marginRight: "20px"
                                    }}
                                    onClick={handleBackToSVGMapView}
                                >
                                    <ArrowBackIcon />
                                    <Typography>{getBlockName()}</Typography>
                                </Box> : <ArrowBackIcon
                                    sx={{ padding: " 0px 20px" }}
                                    onClick={handleBackButton}
                                />}
                            <Typography sx={{ fontWeight: "600", fontSize: "20px" }}>
                                QC Punchlist
                            </Typography>
                        </Box>

                        <Box sx={{ display: Object.keys(selectedPunchlistItem).length > 0 ? "block" : "none" }}>
                            <Box sx={{ display: "flex", alignItems: "center", paddingX: "10px" }}>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Typography>Selected Items :</Typography>
                                    <Typography sx={{ paddingLeft: "5px" }}>{getSeletedPunchlistItemsLength()}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center", marginLeft: "15px", }}>
                                    <Button sx={{ fontSize: "18px", textTransform: "none" }} onClick={handleDeleteModalOpen}>Delete</Button>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ margin: "10px", }}>
                            <SegmentedControl
                                name="view"
                                callback={(val) => handleChangeView(val)}
                                controlRef={viewControlRef}
                                segments={[
                                    {
                                        label: (
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <ListAltIcon
                                                    sx={{ fontSize: "26px", paddingRight: "7px" }}
                                                />
                                                <Typography>List View</Typography>
                                            </Box>
                                        ),
                                        value: 1,
                                        ref: useRef(),
                                    },
                                    {
                                        label: (
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <MapIcon sx={{ fontSize: "26px", paddingRight: " 7px" }} />
                                                <Typography>Map View</Typography>
                                            </Box>
                                        ),
                                        value: 0,
                                        ref: useRef(),
                                    },
                                ]}
                                defaultIndex={viewDefaultIndex}
                            />
                        </Box>
                        <Box sx={{ display: Object.keys(selectedPunchlistItem).length === 0 ? "block" : "none" }}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>

                                {qcChecklist.isPermissionGranted ?
                                    <Box sx={{ margin: "10px 50px" }}>
                                        <Box sx={{ backgroundColor: "#FEF6F6", padding: "10px", border: "1px solid #FCE3E3", borderRadius: "8px" }}>
                                            <Button
                                                variant="contained"
                                                sx={{ borderRadius: "5px", padding: "10px 20px", textTransform: "none" }}
                                                startIcon={<GroupsIcon fontSize="30px" />}>Public </Button>
                                        </Box>
                                    </Box> : null}

                                <Box sx={{ margin: "10px", display: qcChecklist.isPermissionGranted ? "none" : "block" }}>
                                    <SegmentedControl
                                        name="punchlist"
                                        callback={(val) => handleVisibilityFilter(val)}
                                        controlRef={punchlistControlRef}
                                        segments={[
                                            {
                                                label: (
                                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                                        <GroupsIcon
                                                            sx={{ fontSize: "30px", paddingRight: "10px" }}
                                                        />
                                                        <Typography>External</Typography>
                                                    </Box>
                                                ),
                                                value: 1,
                                                ref: useRef(),
                                            },
                                            {
                                                label: (
                                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                                        <PersonIcon sx={{ paddingRight: " 10px" }} />
                                                        <Typography>Internal</Typography>
                                                    </Box>
                                                ),
                                                value: 0,
                                                ref: useRef(),
                                            },
                                        ]}
                                        defaultIndex={punchlistDefaultIndex}
                                    />
                                </Box>
                                {view === 0 &&
                                    <Box sx={{ paddingX: "20px", display: "flex", alignItems: "center" }}>
                                        <Select
                                            value={scope}
                                            onChange={(e) => handleSelectScope(e)}
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: 500,
                                                paddingRight: "0px",
                                                color: "#696F79",
                                                width: "120px"
                                            }}
                                        >

                                            <MenuItem value="All"
                                            // disabled={qcChecklist?.qcBlockId === "All" ? false : true}
                                            >All</MenuItem>
                                            <MenuItem value="piers">Piers</MenuItem>
                                            <MenuItem value="structure">Structure</MenuItem>
                                            <MenuItem value="module">Module</MenuItem>
                                        </Select>
                                        <Box sx={{ paddingLeft: "20px" }}>
                                            <Button variant="contained" sx={{ textTransform: "none", borderRadius: "5px" }} onClick={handleGetD3ColorOnline}>Refresh</Button>
                                        </Box>
                                    </Box>
                                }
                                {/* {view === 1 &&
                                    <Box sx={{ paddingX: "15px", display: "flex" }}>
                                        <Typography sx={{ paddingX: "5px" }}>Total Items:</Typography>
                                        <Typography>{Object.keys(qcChecklist?.qcPunchlist).length}</Typography>
                                    </Box>} */}
                                {view === 1 &&
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Box sx={{ paddingX: "15px", display: "flex" }}>
                                            <Typography sx={{ paddingX: "5px" }}>Total Items:</Typography>
                                            <Typography>{Object.keys(qcChecklist?.qcPunchlist).length}</Typography>
                                        </Box>
                                        <Box>
                                            {blockName !== "All" || sectionId !== "All" || rowId !== "All" || statusFilter !== "All" || scopeFilter !== "All" || checkpointFilter !== "All" || selectedDate !== null ?
                                                <Box sx={{ paddingX: "10px", display: "flex", alignItems: "center" }} onClick={handleClearFilter}>
                                                    <TbFilterOff />
                                                    <Typography >Clear Filter</Typography>
                                                </Box>
                                                :
                                                <Box sx={{ paddingX: "10px", display: "flex", alignItems: "center" }}>
                                                    <TbFilterOff color="#696F79" />
                                                    <Typography sx={{ color: "#696F79", }}>Clear Filter</Typography>
                                                </Box>
                                            }
                                        </Box>
                                    </Box>

                                }
                            </Box>
                        </Box>

                    </Box>
                    <Divider />
                    {view === 1 ?
                        <Box>
                            <Grid container>
                                <TableContainer sx={{ height: "82vh" }} component={Paper}>
                                    {loading ?
                                        < Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80vh", width: "100vw", fontSize: "30px" }}>
                                            <CircularProgress size={50}
                                                thickness={5} />
                                        </Box> :

                                        <Table
                                            sx={{
                                                overflowX: "scroll",
                                                overflowY: "hidden",
                                                position: "relative"
                                            }}
                                            size="small"
                                        >
                                            <TableHead
                                                sx={{
                                                    backgroundColor: "rgba(105, 111, 121, 0.03)",
                                                    padding: "10px",
                                                }}
                                            >
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    {/* <TableCell sx={{ padding: "4px 5px 4px 5px", color: "#696F79", width: 100 }}>
                                                        <Box sx={{ width: 100, color: "#696F79" }}>
                                                            <DatePicker
                                                                // showIcon={selectedDate !== null ? true : false}
                                                                selected={selectedDate}
                                                                onChange={(date) => handleDateChange(date)}
                                                                placeholderText="Time Stamp"
                                                                dateFormat="dd/MM/yyyy"
                                                                className="custom-date-picker"
                                                            // isClearable
                                                            />
                                                        </Box>

                                                    </TableCell> */}
                                                    <TableCell
                                                        sx={{ padding: "4px 0px", color: "#696F79", width: 10 }}
                                                    >
                                                        <Select
                                                            value={blockName}
                                                            defaultValue="block"
                                                            onChange={(e) => handleBlockIdFilter(e)}
                                                            sx={{
                                                                fontSize: blockName !== "All" ? "16px" : "14px",
                                                                fontWeight: 500,
                                                                color: blockName !== "All" ? " #F16253" : "#696F79",
                                                                paddingRight: "0px",
                                                                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                            }}
                                                        >
                                                            <MenuItem value="All">Block</MenuItem>
                                                            {blockIdsList?.map((i) => {
                                                                return <MenuItem value={i} >{i}</MenuItem>;
                                                            })}
                                                        </Select>
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ padding: "4px 0px", color: "#696F79", width: 10 }}
                                                    >
                                                        <Select
                                                            value={sectionId}
                                                            onChange={(e) => handleSectionIdFilter(e)}
                                                            sx={{
                                                                fontSize: sectionId !== "All" ? "16px" : "14px",
                                                                fontWeight: 500,
                                                                p: "5px",
                                                                color: sectionId !== "All" ? " #F16253" : "#696F79",
                                                                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                            }}
                                                        >
                                                            <MenuItem value="All">Section</MenuItem>
                                                            {setionIdsList?.map((i) => {
                                                                return <MenuItem value={i}>{i}</MenuItem>;
                                                            })}
                                                        </Select>
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ padding: "4px 0px", color: "#696F79", width: 10 }}
                                                    >
                                                        <Select
                                                            value={rowId}

                                                            onChange={(e) => handleRowIdFilter(e)}
                                                            sx={{
                                                                fontSize: rowId !== "All" ? "16px" : "14px",
                                                                fontWeight: 500,
                                                                color: rowId !== "All" ? " #F16253" : "#696F79",
                                                                paddingRight: "0px",
                                                                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                            }}
                                                        >
                                                            <MenuItem value="All">Row</MenuItem>
                                                            {rowIdsList.map((i) => {
                                                                return <MenuItem value={i}>{i}</MenuItem>;
                                                            })}
                                                        </Select>
                                                    </TableCell>
                                                    {/* <TableCell sx={{ padding: "4px 5px", color: "#696F79", width: 10 }}>
                                                        <Select
                                                            value={scopeFilter}
                                                            defaultValue="Scope"
                                                            onChange={(e) => handleScopeFilter(e)}
                                                            sx={{
                                                                fontSize: scopeFilter !== "All" ? "16px" : "14px",
                                                                fontWeight: 500,
                                                                color: scopeFilter !== "All" ? " #F16253" : "#696F79",
                                                                paddingRight: "0px",
                                                                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                            }}
                                                        >
                                                            <MenuItem value="All">Scope</MenuItem>
                                                            {scopeList.map((i) => {
                                                                return <MenuItem value={i}>{i?.charAt(0).toUpperCase() + i?.slice(1)}</MenuItem>;
                                                            })}
                                                        </Select>
                                                    </TableCell> */}
                                                    <TableCell
                                                        sx={{
                                                            padding: "4px 5px",
                                                            color: "#696F79",
                                                            // width: 100,
                                                            width: 150
                                                        }}
                                                    >
                                                        <Select
                                                            value={checkpointFilter}
                                                            defaultValue="Checkpoint"
                                                            onChange={(e) => handleCheckpointFilter(e)}
                                                            sx={{
                                                                fontSize: checkpointFilter !== "All" ? "16px" : "14px",
                                                                fontWeight: 500,
                                                                color: checkpointFilter !== "All" ? " #F16253" : "#696F79",
                                                                paddingRight: "0px",
                                                                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                            }}
                                                        >
                                                            <MenuItem value="All">Checkpoint</MenuItem>
                                                            {checkpointList.map((i) => {
                                                                return <MenuItem value={i}>{i}</MenuItem>;
                                                            })}
                                                        </Select>
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            padding: "4px 8px",
                                                            color: "#696F79",
                                                            // width: 100
                                                            width: 150
                                                        }}
                                                    >
                                                        Acceptance Criteria
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            padding: "4px 8px",
                                                            color: "#696F79",
                                                            width: 200
                                                        }}
                                                    >
                                                        Comments
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ padding: "4px 8px", color: "#696F79", width: 200 }}
                                                    >
                                                        Photos
                                                    </TableCell>
                                                    <TableCell sx={{ padding: "4px 8px", color: "#696F79" }}>
                                                        <FormControl>
                                                            <Select
                                                                value={statusFilter}
                                                                displayEmpty
                                                                inputProps={{ "aria-label": "Without label" }}
                                                                sx={{
                                                                    fontSize: statusFilter !== "All" ? "16px" : "14px",
                                                                    fontWeight: 500,
                                                                    color: statusFilter !== "All" ? " #F16253" : "#696F79",
                                                                    paddingRight: "5px",
                                                                    marginLeft: "8px",
                                                                    // color: "#696F79",
                                                                    width: " 100px ",
                                                                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                                }}
                                                                onChange={(e) => handlePunchlistStatusFilter(e)}
                                                            >
                                                                <MenuItem value="All">Status</MenuItem>

                                                                <MenuItem value={0}>Open</MenuItem>
                                                                <MenuItem value={1}>Ready for Inspection</MenuItem>

                                                                <MenuItem value={2} >
                                                                    Resolved
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Object.values(qcChecklist?.qcPunchlist).length >= 1 ?
                                                    (qcChecklist?.qcPunchlist && Object.values(qcChecklist?.qcPunchlist)?.map((i, index) => (
                                                        <TableRow key={index} sx={{ padding: "5px 10px" }}>
                                                            <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
                                                                <Checkbox
                                                                    {...label}
                                                                    icon={< CircleOutlinedIcon />}
                                                                    checkedIcon={<CheckCircleIcon sx={{ color: selectedPunchlistItem[i?.block_id] ? selectedPunchlistItem[i?.block_id].includes(get_punchlist_key(i)) ? "green" : null : null }} />}
                                                                    onClick={e => handleSelectPunchlistItem(e, i)} name={get_punchlist_key(i)}
                                                                />
                                                            </TableCell>

                                                            {/* <TableCell sx={{ padding: "5px 10px" }}>{i?.created_at ? convertEpochToTimeZone(i?.created_at * 1000, timeZone, "qc") : null}</TableCell> */}
                                                            <TableCell> {findBlockNameByBlockId(i?.block_id)}</TableCell>
                                                            <TableCell sx={{ paddingLeft: "20px" }}>{i?.section}</TableCell>
                                                            <TableCell sx={{ paddingLeft: "15px" }}>{i?.row}</TableCell>
                                                            {/* <TableCell sx={{ paddingLeft: "5px" }}>{i?.scope?i?.scope.charAt(0).toUpperCase() + i?.scope.slice(1):"Scope"}</TableCell> */}
                                                            <TableCell>
                                                                {/* {i?.pier_id !== undefined || i?.tt_id !== undefined ?
                                                                    <Typography>
                                                                        {i?.label ? i?.label : "A"}
                                                                        <Box sx={{ display: "flex", marginLeft: "6px", justifyContent: "start", alignItems: "center" }}>
                                                                            <MdOutlineSubdirectoryArrowRight />
                                                                            <Typography>{i?.checkpoint_name}</Typography>
                                                                        </Box>
                                                                    </Typography> : */}
                                                                <Typography>
                                                                    {i?.checkpoint_name}
                                                                </Typography>
                                                                {/* } */}
                                                            </TableCell>
                                                            <TableCell>{i?.acceptance_crieteria ? i?.acceptance_crieteria : i?.acceptance_criteria}</TableCell>
                                                            <TableCell >

                                                                <p className="nextline" style={{ margin: "5px 0" }}>{i?.comments}</p>
                                                                <Button
                                                                    onClick={() => handleClickComment(i)}
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "start",
                                                                        alignItems: "center",
                                                                        // textTransform: "none",
                                                                        padding: "0px"

                                                                    }}
                                                                // disabled={qcChecklist.isPermissionGranted === false && visibility === 1 ? true : false}
                                                                >

                                                                    <MdModeEdit
                                                                        style={{
                                                                            color: "#696F79"
                                                                        }}
                                                                        size={24}
                                                                    />
                                                                </Button>
                                                            </TableCell>
                                                            <TableCell sx={{ padding: "5px 7px" }}>
                                                                {i?.document?.length > 0 && i?.document?.map((imgId, index) => (

                                                                    < img
                                                                        src={qcChecklist?.qcImageUrls[imgId]}
                                                                        onClick={() =>
                                                                            openImageViewer(
                                                                                index,
                                                                                i
                                                                            )
                                                                        }
                                                                        width="60"
                                                                        key={index}
                                                                        style={{ margin: "2px" }}
                                                                        alt=""
                                                                    />

                                                                ))}
                                                                {isViewerOpen && (
                                                                    <ImageViewer
                                                                        id={i.id}
                                                                        src={ImageView}
                                                                        currentIndex={currentImage}
                                                                        disableScroll={true}
                                                                        closeOnClickOutside={true}
                                                                        leftArrowComponent={
                                                                            <IconButton
                                                                                onClick={() => {
                                                                                    onHandleLeftArrow(
                                                                                        currentImage,
                                                                                        ImageView
                                                                                    );
                                                                                }}
                                                                                sx={{ color: "white" }}
                                                                            >
                                                                                <ArrowBackIosNewIcon
                                                                                    fontSize="large"
                                                                                    style={{ color: "white" }}
                                                                                />
                                                                            </IconButton>
                                                                        }
                                                                        rightArrowComponent={
                                                                            <IconButton
                                                                                onClick={() => {
                                                                                    onHandleRightArrow(
                                                                                        currentImage,
                                                                                        ImageView
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <ArrowForwardIosIcon
                                                                                    fontSize="large"
                                                                                    style={{ color: "white" }}
                                                                                />
                                                                            </IconButton>
                                                                        }
                                                                        closeComponent={
                                                                            <Box sx={{ display: "flex" }}>
                                                                                <IconButton
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        color: "white",
                                                                                    }}
                                                                                >
                                                                                    <DeleteIcon
                                                                                        fontSize="large"
                                                                                        onClick={() =>
                                                                                            handleImageDelete(currentImage)
                                                                                        }
                                                                                    />
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontSize: "24px",
                                                                                            paddingLeft: "7px",
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        Delete
                                                                                    </Typography>
                                                                                </IconButton>
                                                                                <IconButton
                                                                                    onClick={closeImageViewer}
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        justifyContent: "center",
                                                                                        color: "white",
                                                                                    }}
                                                                                >
                                                                                    <CloseIcon fontSize="large" />
                                                                                </IconButton>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: "24px",
                                                                                        paddingTop: "7px",
                                                                                    }}
                                                                                >
                                                                                    Close
                                                                                </Typography>
                                                                            </Box>
                                                                        }
                                                                    />

                                                                )}
                                                                <form action="/form/sumbit" method="get">
                                                                    <label class="label">
                                                                        <input
                                                                            type="file"
                                                                            multiple
                                                                            required
                                                                            onChange={(e) => onImageChange(e, i)}
                                                                        // disabled={qcChecklist.isPermissionGranted === false && visibility === 1 ? true : false}
                                                                        />
                                                                        <MdAddAPhoto
                                                                            style={{
                                                                                color: "#696F79"
                                                                            }}
                                                                            size={23}
                                                                        />
                                                                    </label>
                                                                </form>
                                                            </TableCell>
                                                            <TableCell>
                                                                <FormControl>
                                                                    <Select
                                                                        key={i?.id}
                                                                        defaultValue={i?.punchlist_status}
                                                                        inputProps={{
                                                                            name:
                                                                                i?.punchlist_status === 0
                                                                                    ? "open"
                                                                                    : i?.punchlist_status === 1
                                                                                        ? "Ready For Inception"
                                                                                        : "Resolved",
                                                                        }}
                                                                        onChange={(e) => handlePunchlistStatusChange(e, i)}
                                                                        sx={{ width: 140 }}
                                                                    // disabled={qcChecklist.isPermissionGranted === false && visibility === 1 ? true : false}
                                                                    >
                                                                        {/* <MenuItem hidden value={i.status ===  0 ? "Open" : i.status === 1 ? "Ready For Inception" : "Resolved"}>{i.status ===  0 ? "Open" : i.status === 1 ? "Ready For Inception" : "Resolved"}</MenuItem> */}
                                                                        <MenuItem
                                                                            value={0}
                                                                        // disabled={qcChecklist.isPermissionGranted === false && visibility === 1 && i?.QCProgress?.punchlist_status === 2 ? true : false}
                                                                        >
                                                                            Open
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            // disabled={qcChecklist.isPermissionGranted === false && visibility === 1 && i?.QCProgress?.punchlist_status === 2 ? true : false}
                                                                            value={1}
                                                                        >
                                                                            Ready for Inspection
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            value={2}
                                                                        // disabled={qcChecklist.isPermissionGranted === false && visibility === 1 ? true : false}
                                                                        >

                                                                            Resolved
                                                                        </MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </TableCell>
                                                        </TableRow>
                                                    )))
                                                    : (
                                                        <TableRow >
                                                            <TableCell colSpan={12} align="center" style={{ verticalAlign: "middle" }}>
                                                                <Box
                                                                    display="grid"
                                                                    placeItems="center"
                                                                    height="100%"
                                                                    width="100%"
                                                                >
                                                                    <Typography variant="h6">No punchlist Items Found</Typography>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>)
                                                }
                                            </TableBody>

                                        </Table>
                                    }
                                </TableContainer>
                            </Grid>
                        </Box> :
                        <QCMapView
                            visibility={visibility}
                            scope={scope}
                            showSVG={showSVG}
                            qcD3ColorData={qcChecklist?.qcD3Color}
                        />}
                </Box>
            </form >
            <Modal
                open={openCommentsPhotosModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ visibility: isViewerOpen ? "hidden" : "visible" }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "45%",
                        backgroundColor: "#FFFFFF",
                        color: "black",
                        padding: "20px",
                        borderRadius: "20px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            padding: "15px 20px 0px 0px ",
                        }}
                    >
                        <CloseIcon onClick={handleModal} />
                    </Box>
                    <Box>
                        {
                            currentRowData?.map((i, index) => {

                                return (
                                    <>
                                        <Box sx={{ padding: "10px 15px" }}>
                                            <InputLabel>Enter Comment</InputLabel>
                                            <TextField
                                                key={index}
                                                defaultValue={i?.comments ? i?.comments : commentValue}
                                                multiline
                                                onChange={(e) => {
                                                    setCommentValue(e.target.value);
                                                }}
                                                sx={{
                                                    width: "350px",
                                                    borderRadius: "6px",
                                                    paddingY: "10px",
                                                }}
                                            />

                                        </Box>
                                        <Box sx={{ padding: "15px 15px 10px 16px" }}>

                                            {i?.document?.length > 0 && i?.document.map((imgId, index) => {

                                                return (
                                                    < img
                                                        src={qcChecklist?.qcImageUrls[imgId]}
                                                        onClick={() =>
                                                            openImageViewer(
                                                                index,
                                                                i
                                                            )
                                                        }
                                                        width="60"
                                                        key={index}
                                                        style={{ margin: "2px" }}
                                                        alt=""
                                                    />
                                                )
                                            })}

                                            {isViewerOpen && (
                                                <ImageViewer
                                                    id={i.id}
                                                    src={ImageView}
                                                    currentIndex={currentImage}
                                                    disableScroll={true}
                                                    closeOnClickOutside={true}
                                                    leftArrowComponent={
                                                        <IconButton
                                                            onClick={() => {
                                                                onHandleLeftArrow(
                                                                    currentImage,
                                                                    ImageView
                                                                );
                                                            }}
                                                            sx={{ color: "white" }}
                                                        >
                                                            <ArrowBackIosNewIcon
                                                                fontSize="large"
                                                                style={{ color: "white" }}
                                                            />
                                                        </IconButton>
                                                    }
                                                    rightArrowComponent={
                                                        <IconButton
                                                            onClick={() => {
                                                                onHandleRightArrow(
                                                                    currentImage,
                                                                    ImageView
                                                                );
                                                            }}
                                                        >
                                                            <ArrowForwardIosIcon
                                                                fontSize="large"
                                                                style={{ color: "white" }}
                                                            />
                                                        </IconButton>
                                                    }
                                                    closeComponent={
                                                        <Box sx={{ display: "flex" }}>
                                                            <IconButton
                                                                sx={{
                                                                    display: "flex",
                                                                    color: "white",
                                                                }}
                                                            >
                                                                <DeleteIcon
                                                                    fontSize="large"
                                                                    onClick={() =>
                                                                        handleImageDelete(currentImage)
                                                                    }
                                                                />
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: "24px",
                                                                        paddingLeft: "7px",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    Delete
                                                                </Typography>
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={closeImageViewer}
                                                                sx={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    color: "white",
                                                                }}
                                                            >
                                                                <CloseIcon fontSize="large" />
                                                            </IconButton>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: "24px",
                                                                    paddingTop: "7px",
                                                                }}
                                                            >
                                                                Close
                                                            </Typography>
                                                        </Box>
                                                    }
                                                />

                                            )}
                                            <form action="/form/sumbit" method="get">
                                                <label class="label">
                                                    <input
                                                        type="file"
                                                        multiple
                                                        required
                                                        onChange={(e) => onImageChange(e, i)}
                                                    // disabled={qcChecklist.isPermissionGranted === false && visibility === 1 ? true : false}
                                                    />
                                                    <MdAddAPhoto
                                                        style={{
                                                            color: "#696F79"
                                                        }}
                                                        size={23}
                                                    />
                                                </label>
                                            </form>
                                        </Box >
                                        <Box sx={{
                                            padding: "15px",
                                            display: "flex",
                                            justifyContent: "end",
                                            alignItems: "center",
                                        }}>
                                            <Button onClick={() => {
                                                handleSubmitCommentsPhotos(i)
                                                setOpenCommentsPhotosModal(false)
                                            }}
                                                variant="contained"
                                            >{i?.comments ? "Update" : "Submit"}</Button>

                                        </Box>
                                    </>
                                )
                            })}
                    </Box>
                </Box>
            </Modal >

            <Modal
                open={openDeleteModal}
                onClose={handleDeleteModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "50%",
                    backgroundColor: "#FFFFFF",
                    color: "black",
                    padding: "10px"
                }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            padding: "10px 10px 0px 0px ",
                        }}
                    >
                        <CloseIcon onClick={handleDeleteModalClose} />
                    </Box>
                    <Box sx={{ padding: "10px" }}>
                        <Typography sx={{ fontSize: "18px" }} >
                            Deleting the item will delete the photos and comments as well.<br />
                            Please confirm if the status of this item should be changed to "Not Started" or "Pass" in the QC checklist
                        </Typography>
                    </Box>
                    <Box sx={{ padding: "30px 20px", display: "flex", alignItems: "center", justifyContent: "end" }}>
                        <Button variant="contained" sx={{ marginRight: "10px" }} onClick={() => handleDeletePunchlist(0)}> Mark as Not Started</Button>
                        <Button variant="contained" onClick={() => handleDeletePunchlist(1)}> Mark as Pass</Button>
                    </Box>

                </Box>
            </Modal>

            <ToastContainer
                position="bottom-right"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={true}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                style={{ width: "440px" }}
            />
        </Box >
    );
};

export default QCPunchlist;
