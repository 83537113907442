import {
  Box,
  Grid,
  Typography,
  Divider,
  InputLabel,
  TextField,
  Tab,
  Tabs,
  Modal,
  Button,
  Select,
  MenuItem,
  FormHelperText
} from "@mui/material";
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from "react";
import Header from "../../../components/appBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import { useAtom } from "jotai";
import selectedRowsAtom, { updateSelectedRowsAtom } from "../../../data/selectedRowsAtom";
import dashboardAtom, { fetchBlockAtom, fetchBulkPiersTorqueTubesBlockDataAtom, fetchHighLightSelectedTrackerTypeIdsAtom, fetchPierLegendAtom, fetchPreviousSelectedTrackerTypeIdAtom, fetchSelectedTrackerTypeIdsAtom, fetchTrackerTypeNamesAtom, fetchZimmermanBulkPiersAtom } from "../../../data/dashboardAtom";
import { ToastContainer, toast } from "react-toastify";
import SegmentedControl from "../../../components/qcPunchlist/segmentedControl";
import QCChecklistTable from "../../../components/qc/qcChecklistTable";
import qcChecklistAtom, { fetchQcChecklistAtom, fetchQcCustomResponseAtom, fetchQcImageUrlsAtom } from "../../../data/qualityControlAtom";
import IndividualTrackerRow from "../../pierList/individualTrackerRow";
import lodash from "lodash";
import { get_qc_checklist_trackertypewise } from "../../../dal/qualityCheckProgress";
import { insertQcQueueAtom } from "../../../data/qcQueueAtom";
import produce from "immer";
import syncStatusAtom from "../../../data/syncStatusAtom";
import { GrCircleInformation } from "react-icons/gr";
import { IS_PROJECT_TYPE_ZIMMERMAN_FLAG } from "../../../featureFlags/featureFlags";
import { STORAGE_KEYS, clearQCStorageData } from "../../../utils/storageUtils";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const getInitialIds = (data, type, mapType) => {
  if (!data || Object.keys(data).length === 0) return mapType === 1 ? 0 : [];

  const items = type === 0 ? data.piers : data.torque_tube;
  if (!items || items.length === 0) return mapType === 1 ? 0 : [];

  return mapType === 1 ?
    parseInt(items[0].id) :
    items.map(item => parseInt(item.id));
};

const getInitialTypeId = (data, type) => {
  if (!data || Object.keys(data).length === 0) return 0;

  const items = type === 0 ? data.piers : data.torque_tube;
  return items?.length > 0 ?
    (type === 0 ? items[0].pier_type_id : items[0].tt_type_id) :
    0;
};

const initializeStateFromStorage = () => {
  try {
    const storedMapType = parseInt(localStorage.getItem(STORAGE_KEYS.MAP_TYPE)) || 1;
    const storedType = JSON.parse(localStorage.getItem(STORAGE_KEYS.TYPE)) || 0;
    const storedPierId = JSON.parse(localStorage.getItem(STORAGE_KEYS.PIER_ID));
    const storedVisibility = parseInt(localStorage.getItem("punchlist-visibility-index")) || 1;

    return {
      mapType: storedMapType,
      type: storedType,
      visibility: storedVisibility,
      selectedIds: storedPierId ?
        (storedMapType === 1 ?
          (Array.isArray(storedPierId) ? storedPierId[0] : storedPierId) :
          (Array.isArray(storedPierId) ? storedPierId : [storedPierId])) :
        (storedMapType === 1 ? 0 : []),
      selectedTypeId: JSON.parse(localStorage.getItem(STORAGE_KEYS.PIER_TYPE_ID)) || 0
    };
  } catch (error) {
    console.error('Error initializing state from storage:', error);
    return {
      mapType: 1,
      type: 0,
      visibility: 1,
      selectedIds: 0,
      selectedTypeId: 0
    };
  }
};

const QcDataChecksheet = () => {
  const navigate = useNavigate();
  const {
    mapType: initialMapType,
    type: initialType,
    visibility: initialVisibility,
    selectedIds: initialSelectedIds,
    selectedTypeId: initialSelectedTypeId
  } = initializeStateFromStorage();

  const [qcChecklistData, setQcChecklistData] = useAtom(qcChecklistAtom);
  const [, fetchPierLegend] = useAtom(fetchPierLegendAtom)
  const [, fetchQcChecklist] = useAtom(fetchQcChecklistAtom);
  const [selectedRows] = useAtom(selectedRowsAtom);
  const [, fetchBulkPiersTorqueTubesBlockData] = useAtom(fetchBulkPiersTorqueTubesBlockDataAtom)
  const [, fetchQcImageUrls] = useAtom(fetchQcImageUrlsAtom)
  const [, fetchQcCustomResponse] = useAtom(fetchQcCustomResponseAtom)
  const [dashboard] = useAtom(dashboardAtom);
  const [, fetchBlocks] = useAtom(fetchBlockAtom)
  const [, insertQcQueue] = useAtom(insertQcQueueAtom)
  const [, fetchTrackerTypeNames] = useAtom(fetchTrackerTypeNamesAtom)

  const [back, setBack] = useState(false);
  const [backModal, setBackModal] = useState(false)
  const [pierTorqueTubeKey, setPierTorqueTubeKey] = useState("")
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [customInputId, setCustomInputId] = useState(0)
  const [errorMessage, setErrorMessage] = useState("")
  const [syncStatusData] = useAtom(syncStatusAtom);
  const [, fetchZimmermanBulkPiers] = useAtom(fetchZimmermanBulkPiersAtom)
  const params = useParams()
  const [adjustableBoxWidth, setAdjustableBoxWidth] = useState(0);
  const [tooltipState, setTooltipState] = useState({ id: null, name: '', show: false });
  const [mapType, setMapType] = useState(initialMapType);
  const [type, setType] = useState(initialType);
  const [visibility, setVisibility] = useState(initialVisibility);
  const [selectedPierOrTorqueTubeId, setSelectedPierOrTorqueTubeId] = useState(initialSelectedIds);
  const [selectedPierOrTorqueTubeTypeId, setSelectedPierOrToruqueTubeTypeId] = useState(initialSelectedTypeId);
  const [piersTorqueTubesDetails, setPiersTorqueTubeDetails] = useState({});
  const [isInitialized, setIsInitialized] = useState(false);
  const [blockDetails, setBlockDetails] = useState();
  const [typeName, setTypeName] = useState("piers");
  const [isZimmerman, setIsZimmerman] = useState(false);
  const [pierLabel, setPierLabel] = useState("")
  const [torqueTubeLabel, setTorqueTubeLabel] = useState("")

  const labelRefs = useRef({});
  const containerRef = useRef(null);
  const fixedBoxWidth = 100;



  // Main initialization effect
  useEffect(() => {
    const initializeComponent = async () => {
      const blockId = parseInt(params.id);
      const trackertypeid = parseInt(params.trackertypeid);

      try {
        // Fetch initial data
        await Promise.all([
          fetchBlocks(),
          fetchBulkPiersTorqueTubesBlockData(blockId),
          fetchQcImageUrls({ blockId, visibility }),
          fetchPierLegend(),
          fetchTrackerTypeNames(),
          fetchZimmermanBulkPiers(blockId)
        ]);

        // Get map type for current tracker and type
        const isTrackerrowLevelChecklist = await get_qc_checklist_trackertypewise(trackertypeid, typeName);
        const newMapType = parseInt(isTrackerrowLevelChecklist);

        setMapType(newMapType);
        localStorage.setItem(STORAGE_KEYS.MAP_TYPE, newMapType);

        const isZimmermanFlag = await IS_PROJECT_TYPE_ZIMMERMAN_FLAG();
        setIsZimmerman(isZimmermanFlag);

        setIsInitialized(true);
      } catch (err) {
        console.error('Error in initialization:', err);
      }
    };

    initializeComponent();
  }, []);

  // Handle pier/torque tube data updates
  useEffect(() => {
    if (!piersTorqueTubesDetails || Object.keys(piersTorqueTubesDetails).length === 0) return;

    if (!syncStatusData.syncing) {
      const newIds = getInitialIds(piersTorqueTubesDetails, type, mapType);
      const newTypeId = getInitialTypeId(piersTorqueTubesDetails, type);

      setSelectedPierOrTorqueTubeId(newIds);
      setSelectedPierOrToruqueTubeTypeId(newTypeId);

      localStorage.setItem(STORAGE_KEYS.PIER_ID, JSON.stringify(newIds));
      localStorage.setItem(STORAGE_KEYS.PIER_TYPE_ID, JSON.stringify(newTypeId));
    }
  }, [piersTorqueTubesDetails, type, mapType, syncStatusData.syncing]);
  // Handle selection of pier/torque tube items
  const handleSelectPierTrqueTubeItem = (e) => {
    if (!e?.id || !e?.type_id) return;

    const currentData = type === 0 ? piersTorqueTubesDetails.piers : piersTorqueTubesDetails.torque_tube;

    const newIds = mapType === 0 ?
      currentData.map(item => parseInt(item.id)) :
      parseInt(e.id);

    setSelectedPierOrTorqueTubeId(newIds);
    setSelectedPierOrToruqueTubeTypeId(e.type_id);

    localStorage.setItem(STORAGE_KEYS.PIER_ID, JSON.stringify(newIds));
    localStorage.setItem(STORAGE_KEYS.PIER_TYPE_ID, JSON.stringify(e.type_id));
  };

  // Fetch QC checklist data
  const getQCChecklistData = async () => {
    if (!blockDetails || selectedPierOrTorqueTubeId === 0) return;

    try {
      const blockId = blockDetails.block_id;
      const trackerrowid = parseInt(params.trackerrowid);
      const trackertypeid = parseInt(params.trackertypeid);

      const currentMapType = parseInt(await get_qc_checklist_trackertypewise(trackertypeid, typeName));

      // Update storage
      setMapType(currentMapType);
      localStorage.setItem(STORAGE_KEYS.MAP_TYPE, currentMapType);
      localStorage.setItem(STORAGE_KEYS.TYPE, JSON.stringify(type));
      localStorage.setItem(STORAGE_KEYS.PIER_ID, JSON.stringify(selectedPierOrTorqueTubeId));
      localStorage.setItem(STORAGE_KEYS.PIER_TYPE_ID, JSON.stringify(selectedPierOrTorqueTubeTypeId));

      const requestParams = {
        typeName,
        visibility,
        block_id: blockId,
        trackerrowid,
        mapType: currentMapType
      };

      if (currentMapType === 1) {
        requestParams.selectedPierOrTorqueTubeId = Array.isArray(selectedPierOrTorqueTubeId) ?
          selectedPierOrTorqueTubeId[0] : selectedPierOrTorqueTubeId;
      }

      await fetchQcCustomResponse(requestParams);
      await fetchQcChecklist({
        ...requestParams,
        trackertypeid,
        ...(currentMapType === 1 && { selectedPierOrTorqueTubeTypeId, selectedPierOrTorqueTubeId })
      });
    } catch (err) {
      console.error('Error fetching QC checklist data:', err);
    }
  };

  // Update QC data when relevant state changes
  useEffect(() => {
    if (isInitialized && type !== undefined && selectedPierOrTorqueTubeId !== 0) {
      getQCChecklistData();
    }
  }, [isInitialized, type, selectedPierOrTorqueTubeId, visibility, typeName]);

  // Handle type changes
  useEffect(() => {
    if (type === 0) setTypeName("piers");
    else if (type === 1) setTypeName("structure");
    else if (type === 2) setTypeName("module");
  }, [type]);

  // Handle storage sync
  useEffect(() => {
    if (!syncStatusData.syncing) {
      const {
        mapType: storedMapType,
        type: storedType,
        visibility: storedVisibility,
        selectedIds,
        selectedTypeId
      } = initializeStateFromStorage();

      setMapType(storedMapType);
      setType(storedType);
      setVisibility(storedVisibility);
      setSelectedPierOrTorqueTubeId(selectedIds);
      setSelectedPierOrToruqueTubeTypeId(selectedTypeId);
    }
  }, [syncStatusData.syncing])
  const isCommentsEmpty = async () => {
    let isEmpty = false;
    if (qcChecklistData?.qcChecklistProgress && qcChecklistData?.qcChecklistProgress?.checkpoints.length !== 0) {
      qcChecklistData?.qcChecklistProgress?.checkpoints.map((j) => {
        if (parseInt(j?.status) === 2 && (j?.comments === "" || j?.comments === undefined)) {
          isEmpty = true
        }
      });
    }
    return isEmpty
  }


  useEffect(() => {
    // Set a delay (e.g., 5000ms) before running the code inside useEffect
    const delay = setTimeout(() => {
      if (mapType === 0 && type === 0) {
        setPierLabel("");
      }
      if (mapType === 0 && (type === 1 || type === 2)) {
        setTorqueTubeLabel("");
      }
      if (mapType === 1 && selectedPierOrTorqueTubeId !== 0 && Object.keys(piersTorqueTubesDetails).length !== 0 && type === 0) {
        const pierData = piersTorqueTubesDetails?.piers.find((pier) => pier?.id === selectedPierOrTorqueTubeId);
        if (pierData && pierData?.pier_part_label) {
          setPierLabel(pierData?.pier_part_label);
        }
      }
      if (mapType === 1 && selectedPierOrTorqueTubeId !== 0 && Object.keys(piersTorqueTubesDetails).length !== 0 && (type === 1 || type === 2)) {
        const ttData = piersTorqueTubesDetails?.torque_tube.find((tt) => tt?.id === selectedPierOrTorqueTubeId);
        if (ttData && ttData.tt_part_label) {
          const ttPartLabelNumberOnly = ttData?.tt_part_label.replace(/\D/g, '');
          setTorqueTubeLabel(ttPartLabelNumberOnly);
        }
      }
    }, 500); // Delay time in milliseconds (1000ms = 1 second)
  
    // Clear the timeout if dependencies change or component unmounts
    return () => clearTimeout(delay);
  }, [selectedPierOrTorqueTubeId, piersTorqueTubesDetails]);


  const handleBack = async () => {
    try {
      const isEmpty = await isCommentsEmpty();
      if (isEmpty === false) {
        clearQCStorageData(); // Clear storage only after confirming it's safe to leave
        setBack(false);
        setBackModal(false);
        navigate(`/home/gpslocation/${params.id}/qcdata`);
      } else {
        setBackModal(true);
      }
    } catch (error) {
      console.error('Error in handleBack:', error);
    }
  };
  let tracker_type = dashboard?.trackerTypeNames?.find(
    (j) => j?.id === parseInt(params.trackertypeid)
  );


  // Add this effect to initialize piersTorqueTubesDetails
  useEffect(() => {
    if (isZimmerman === false &&
      dashboard?.bulkPiersTorqueTubesBlockData &&
      Object.keys(dashboard?.bulkPiersTorqueTubesBlockData).length !== 0) {

      const trackerrowid = parseInt(params.trackerrowid);
      const piersTorqueTubeData = dashboard?.bulkPiersTorqueTubesBlockData;

      if (piersTorqueTubeData["piers"][trackerrowid] && piersTorqueTubeData["tt"][trackerrowid]) {
        // Create object with sorted piers and torque tube data
        const pierTTObj = {
          piers: lodash.sortBy(piersTorqueTubeData["piers"][trackerrowid], [
            function (o) {
              return o.pier_part_order;
            },
          ]),
          torque_tube: lodash.sortBy(piersTorqueTubeData["tt"][trackerrowid], [
            function (o) {
              return o.tt_part_order;
            },
          ])
        };

        // Update piersTorqueTubesDetails state
        setPiersTorqueTubeDetails(pierTTObj);
        setBlockDetails(piersTorqueTubeData["piers"][trackerrowid][0]);

        // Set initial values if no stored data exists
        if (selectedPierOrTorqueTubeId === 0 || selectedPierOrTorqueTubeId.length === 0) {
          const newIds = mapType === 1
            ? parseInt(pierTTObj.piers[0].id)
            : pierTTObj.piers.map(pier => parseInt(pier.id));

          setSelectedPierOrTorqueTubeId(newIds);
          localStorage.setItem(STORAGE_KEYS.PIER_ID, JSON.stringify(newIds));
        }
      }
    }
  }, [isZimmerman, dashboard?.bulkPiersTorqueTubesBlockData, mapType]);

  const handleChange = async (event, newValue) => {
    // Check for empty comments first
    const isEmpty = await isCommentsEmpty();

    if (isEmpty) {
      setBackModal(true);
      return;
    }

    // Helper function to get IDs based on type and mapType
    const getNewIds = (type, data, currentMapType) => {
      const items = type === 0 ? data.piers : data.torque_tube;
      if (!items?.length) return null;

      return {
        ids: currentMapType === 1 ?
          parseInt(items[0].id) :
          items.map(item => parseInt(item.id)),
        typeId: type === 0 ? items[0].pier_type_id : items[0].tt_type_id
      };
    };

    // Update type in state and storage
    setType(newValue);
    localStorage.setItem(STORAGE_KEYS.TYPE, JSON.stringify(newValue));

    // Update IDs if we have valid data
    if (piersTorqueTubesDetails && Object.keys(piersTorqueTubesDetails).length > 0) {
      const newIds = getNewIds(newValue, piersTorqueTubesDetails, mapType);

      if (newIds) {
        // Update states
        setSelectedPierOrTorqueTubeId(newIds.ids);
        setSelectedPierOrToruqueTubeTypeId(newIds.typeId);

        // Update storage
        localStorage.setItem(STORAGE_KEYS.PIER_ID, JSON.stringify(newIds.ids));
        localStorage.setItem(STORAGE_KEYS.PIER_TYPE_ID, JSON.stringify(newIds.typeId));

        // Update type name based on new value
        setTypeName(newValue === 0 ? "piers" : newValue === 1 ? "structure" : "module");
      }
    }
  };
  const handleCloseBackModal = () => {
    setBackModal(false)
  }

  useEffect(() => {
    if (parseInt(type) === 0) {
      setPierTorqueTubeKey("pier_id")
    } else {
      setPierTorqueTubeKey("tt_id")
    }
  }, [type])

  const validateNumericInput = (value, type) => {
    if (type === 0) { // Integer
      return {
        isValid: /^-?\d*$/.test(value),
        errorMessage: "Please enter integer value"
      };
    } else if (type === 1) { // Float
      // Check if it's a valid float with up to 6 decimal places
      if (!/^-?\d*\.?\d*$/.test(value)) {
        return {
          isValid: false,
          errorMessage: "Please enter float value"
        };
      }
      if (/^-?\d*\.?\d{7,}$/.test(value)) {
        return {
          isValid: false,
          errorMessage: "Float value should not be more than 6 digits"
        };
      }
      return {
        isValid: true,
        errorMessage: ""
      };
    }
    return { isValid: true, errorMessage: "" };
  };


  // const handleChangeCustomInputValue = async (e, data) => {
  //   console.log(e.target.value)

  //   let customResponseData = {}
  //   let value = e.target.value
  //   let updateCustomResponse = true
  //   if (parseInt(data?.type) === 0 || parseInt(data?.type) === 1) {
  //     if (parseInt(data?.type) === 0) {
  //       if (value.includes(".") || /[^0-9]/.test(value)) {
  //         setShowErrorMessage(true)
  //         updateCustomResponse = false
  //         setCustomInputId(parseInt(data?.id))
  //         setErrorMessage("Please enter integer value")

  //       }
  //       else {
  //         setShowErrorMessage(false)
  //         updateCustomResponse = true
  //         setCustomInputId(parseInt(data?.id))
  //       }
  //     } else {
  //       if (parseInt(data?.type) === 1) {

  //         // if (/^-?\d*\.?\d*$/.test(value)) {
  //         //   if (/^-?\d*\.?\d{0,6}$/.test(value)) {
  //         if (/^-?\d*(\.\d*)?$/.test(value)) {
  //           if (/^-?\d*\.?\d{0,6}$/.test(value)) {
  //             setShowErrorMessage(false)
  //             setCustomInputId(parseInt(data?.id))
  //             updateCustomResponse = true

  //           } else {
  //             updateCustomResponse = false
  //             setShowErrorMessage(true)
  //             setCustomInputId(parseInt(data?.id))
  //             setErrorMessage("Float value should not be more than 6 digits")
  //           }
  //         } else {
  //           updateCustomResponse = false
  //           setShowErrorMessage(true)
  //           setCustomInputId(parseInt(data?.id))
  //           setErrorMessage("Please enter float value")
  //         }

  //       }
  //     }
  //   }

  //   customResponseData = {
  //     row: blockDetails?.row,
  //     section: blockDetails?.section,
  //     block_name: blockDetails?.block,
  //     block_id: blockDetails?.block_id,
  //     created_at: Date.now() / 1000,
  //     qc_type: "custom_response",
  //     custom_inputs_id: data?.id,
  //     tracker_row_id: parseInt(params.trackerrowid),
  //     visibility: visibility,
  //     response: e?.target.value,
  //     scope: typeName,
  //     custom_inputs_name: data?.name,
  //     custom_inputs_type: data?.type,
  //     custom_inputs_values: data?.values,
  //     checklist_id: data?.checklist_id,
  //     custom_inputs_id: data?.id,
  //     map_type: mapType,
  //     ...(parseInt(mapType) === 1 ? { [pierTorqueTubeKey]: selectedPierOrTorqueTubeId } : null)
  //   }
  //   let update_custom_response = []
  //   const existingObjectIndex = qcChecklistData?.qcCustomResponse.findIndex(obj => obj.custom_inputs_id === data?.custom_inputs_id);
  //   if (existingObjectIndex !== -1) {
  //     update_custom_response = [...qcChecklistData?.qcCustomResponse];
  //     update_custom_response[existingObjectIndex] = { ...update_custom_response[existingObjectIndex], response: e.target.value };
  //   } else {
  //     update_custom_response = [...qcChecklistData?.qcCustomResponse, customResponseData]
  //   }
  //   if (updateCustomResponse === true) {
  //     setQcChecklistData(
  //       produce((draft) => {
  //         draft.qcCustomResponse = update_custom_response
  //       }))

  //     try {
  //       await insertQcQueue(customResponseData)
  //     } catch (err) {
  //       console.log(err)
  //     }
  //   }

  // }

  const handleChangeCustomInputValue = async (e, data) => {
    const value = e.target.value;
    let updateCustomResponse = true;
    let customResponseData = {}

    // Only validate if it's a numeric field
    if (parseInt(data?.type) === 0 || parseInt(data?.type) === 1) {
      const validation = validateNumericInput(value, parseInt(data?.type));

      if (!validation.isValid) {
        setShowErrorMessage(true);
        setCustomInputId(parseInt(data?.id));
        setErrorMessage(validation.errorMessage);
        updateCustomResponse = false;
        return; // Don't proceed with update if validation fails
      } else {
        setShowErrorMessage(false);
        setCustomInputId(parseInt(data?.id));
      }
    }

    // Rest of your existing custom response handling code...
    customResponseData = {
      row: blockDetails?.row,
      section: blockDetails?.section,
      block_name: blockDetails?.block,
      block_id: blockDetails?.block_id,
      created_at: Date.now() / 1000,
      qc_type: "custom_response",
      custom_inputs_id: data?.id,
      tracker_row_id: parseInt(params.trackerrowid),
      visibility: visibility,
      response: value,
      scope: typeName,
      custom_inputs_name: data?.name,
      custom_inputs_type: data?.type,
      custom_inputs_values: data?.values,
      checklist_id: data?.checklist_id,
      map_type: mapType,
      ...(parseInt(mapType) === 1 ? { [pierTorqueTubeKey]: selectedPierOrTorqueTubeId } : null)
    };

    // Update response in state
    if (updateCustomResponse) {
      let update_custom_response = [];
      const existingObjectIndex = qcChecklistData?.qcCustomResponse.findIndex(
        obj => obj.custom_inputs_id === data?.custom_inputs_id
      );

      if (existingObjectIndex !== -1) {
        update_custom_response = [...qcChecklistData?.qcCustomResponse];
        update_custom_response[existingObjectIndex] = {
          ...update_custom_response[existingObjectIndex],
          response: value
        };
      } else {
        update_custom_response = [...qcChecklistData?.qcCustomResponse, customResponseData];
      }

      setQcChecklistData(
        produce((draft) => {
          draft.qcCustomResponse = update_custom_response;
        })
      );

      try {
        await insertQcQueue(customResponseData);
      } catch (err) {
        console.error('Error inserting QC queue:', err);
      }
    }
  };

  useEffect(() => {
    const updateBoxWidth = () => {
      const totalWidth = window.innerWidth;
      const newWidth = totalWidth - fixedBoxWidth;
      setAdjustableBoxWidth(newWidth > 0 ? newWidth : 0);
    };

    // Update box width initially
    updateBoxWidth();

    // Add event listener for window resize
    window.addEventListener('resize', updateBoxWidth);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', updateBoxWidth);
  }, []);

  const getCustomInputValue = (data) => {
    let response = ""
    for (let i of qcChecklistData?.qcCustomResponse) {
      if (i?.custom_inputs_id === data?.id) {
        response = i?.response
      }
    }
    return response
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!Object.values(labelRefs.current).some(ref => ref && ref.contains(event.target))) {
        handleHideCustomLabel();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleShowCustomLabel = (e, input) => {
    e.preventDefault();
    setTooltipState({ id: input.id, name: input.name, show: true });
  };

  const handleHideCustomLabel = () => {
    setTooltipState({ id: null, name: '', show: false });
  };


  return (
    <Box sx={{ width: "100vw", height: "100vh", overflow: "auto" }}>
      <Header menuDisable={back} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "64px",
          backgroundColor: "#262223",
          color: "white",
        }}
      >
        <ArrowBackIcon sx={{ padding: " 0px 18px" }} onClick={handleBack} />
        <Typography sx={{ fontWeight: "600", fontSize: "20px" }}>
          QC Checklist
        </Typography>
      </Box>
      <Box>
        <Grid container>
          <Grid item
            xs={12}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Box sx={{ padding: "10px 20px" }}>
                {isZimmerman === true ?
                  <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>
                    {blockDetails?.block}, Row {selectedRows[0]?.split("-")[4]}, Table
                    {selectedRows[0]?.split("-")[3]}
                  </Typography> :
                  <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>
                    {blockDetails?.block}, Section {blockDetails?.section}, Row{" "}
                    {blockDetails?.row}
                  </Typography>}
                <Typography sx={{ color: "#696F79", fontSize: "14px" }}>
                  {tracker_type?.tracker_type}
                </Typography>
              </Box>

              <Box sx={{ margin: "10px 50px", }}>

                <SegmentedControl
                  name="group"
                  callback={(val) => {
                    setVisibility(val);
                    localStorage.setItem("punchlist-visibility-index", val);
                  }}
                  controlRef={useRef()}
                  segments={[
                    {
                      label: (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <GroupsIcon sx={{ fontSize: "30px", paddingRight: "10px" }} />
                          <Typography>External</Typography>
                        </Box>
                      ),
                      value: 1,
                      ref: useRef(),
                    },
                    {
                      label: (
                        <Box sx={{ display: "flex", alignItems: "center" }} >
                          <PersonIcon sx={{ paddingRight: " 10px" }} />
                          <Typography>Internal</Typography>
                        </Box>
                      ),
                      value: 0,
                      ref: useRef(),
                    },
                  ]}
                  defaultIndex={parseInt(visibility) === 1 ? 0 : 1}
                />
              </Box>
            </Box>
            <Divider />
            <Box sx={{ display: "flex", }}>

              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>

                {/* {isZimmerman === false ? Object.keys(piersTorqueTubesDetails).length > 0 && selectedPierOrTorqueTubeId !== 0 && dashboard?.pierLegends &&
                  <Box sx={{ margin: "10px 20px" }} >
                    <IndividualTrackerRow
                      type="qc"
                      data={piersTorqueTubesDetails}
                      pierData={piersTorqueTubesDetails?.piers}
                      torqueTubeData={piersTorqueTubesDetails?.torque_tube}
                      pierLegend={dashboard?.pierLegends}
                      selectedItem={selectedPierOrTorqueTubeId}
                      selectedTab={typeName}
                      onSelectItem={e => handleSelectPierTrqueTubeItem(e)}
                      mapType={mapType}
                      width={fixedBoxWidth}
                    />
                  </Box> :
                  <Box sx={{ margin: "10px 25px" }}>

                  </Box>
                } */}

                {isZimmerman === false ? (
                  <Box sx={{ margin: "5px 20px" }}>
                    {piersTorqueTubesDetails &&
                      Object.keys(piersTorqueTubesDetails).length > 0 &&
                      dashboard?.pierLegends && Object.keys(dashboard?.pierLegends).length !== 0 && (
                        <IndividualTrackerRow
                          type="qc"
                          data={piersTorqueTubesDetails}
                          pierData={piersTorqueTubesDetails?.piers}
                          torqueTubeData={piersTorqueTubesDetails?.torque_tube}
                          pierLegend={dashboard?.pierLegends}
                          selectedItem={mapType === 0 ?
                            (Array.isArray(selectedPierOrTorqueTubeId) ? selectedPierOrTorqueTubeId : [selectedPierOrTorqueTubeId]) :
                            (Array.isArray(selectedPierOrTorqueTubeId) ? selectedPierOrTorqueTubeId[0] : selectedPierOrTorqueTubeId)
                          }
                          selectedTab={typeName}
                          onSelectItem={handleSelectPierTrqueTubeItem}
                          mapType={mapType}
                          width={fixedBoxWidth}
                        />
                      )}
                  </Box>
                ) : (
                  <Box sx={{ margin: "10px 25px" }} />
                )}
              </Box>
              <Box sx={{ border: "1px solid gray", margin: "10px 20px 20px 0px", width: `${adjustableBoxWidth}px`, height: "calc(100vh-500px)" }}>
                <Box >
                  <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Tabs value={type} onChange={handleChange} aria-label="basic tabs example">
                      <Tab label="piers" {...a11yProps(0)} />
                      <Tab label="structures" {...a11yProps(1)} />
                      <Tab label="module" {...a11yProps(2)} />
                    </Tabs>
                    <Box sx={{
                      display: "flex",
                      alignItems: "center",
                    }}>
                      <GrCircleInformation style={{ padding: "0px 5px", color: "rgba(0, 0, 0, 0.5)" }} fontSize={16} />
                      <Typography sx={{ fontSize: "14px", paddingRight: "16px", color: "rgba(0, 0, 0, 0.5)", fontWeight: "500" }}>Changes will be autosaved</Typography>
                    </Box>
                  </Box>
                  <Box>
                    <CustomTabPanel value={type} index={0}>
                      <Box sx={{ display: "flex", flexDirection: "column", }}>
                        <Box sx={{ padding: "10px 10px 5px 10px" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            {pierLabel === "" ? "Piers" : `Pier ${pierLabel}`}
                          </Typography>
                        </Box>
                        <Box ref={containerRef} sx={{ padding: "0px 10px 5px 10px" }}>
                          {qcChecklistData?.qcChecklistProgress?.custom_inputs &&
                            qcChecklistData?.qcChecklistProgress?.custom_inputs.length > 0 &&
                            qcChecklistData?.qcChecklistProgress?.custom_inputs.map((input, index) => (
                              <Box sx={{ display: "flex", alignItems: "center", margin: "5px 0", position: "relative" }} key={input?.id}>
                                <InputLabel
                                  ref={el => labelRefs.current[input.id] = el}
                                  sx={{
                                    width: "265px",
                                    position: "relative",
                                    cursor: "pointer",
                                    '&::after': {
                                      // content: '"..."',
                                      position: 'absolute',
                                      right: 0,
                                      bottom: 0,
                                      background: 'white',
                                      padding: '0 4px',
                                    }
                                  }}
                                  onClick={(e) => handleShowCustomLabel(e, input)}
                                >
                                  <Typography
                                    sx={{
                                      width: "260px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {input?.name}
                                  </Typography>
                                </InputLabel>
                                {tooltipState.show && tooltipState.id === input.id && (
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      zIndex: 1000,
                                      backgroundColor: "white",
                                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                      padding: "8px 10px",
                                      borderRadius: "4px",
                                      wordWrap: "break-word",
                                      top: "100%",
                                      left: 0,
                                      minWidth: "265px",
                                      maxWidth: "400px",
                                      transform: index === qcChecklistData?.qcChecklistProgress?.custom_inputs.length - 1 ? 'translateY(-100%)' : 'none',
                                    }}
                                  >
                                    <Typography >{tooltipState.name}</Typography>
                                  </Box>
                                )}
                                {parseInt(input?.type) === 0 || parseInt(input?.type) === 1 || parseInt(input?.type) === 2 ? (
                                  <Box sx={{ width: "300px" }}>
                                    {/* <TextField
                                      type={parseInt(input?.type) === 0 || parseInt(input?.type) === 1 ? "number" : "text"}
                                      inputProps={{ pattern: parseInt(input?.type) === 1 ? "[0-9]*[.]?[0-9]+" : null }}
                                      value={getCustomInputValue(input)}
                                      name={input?.name}
                                      onChange={(e) => handleChangeCustomInputValue(e, input)}
                                      sx={{ width: "200px", marginLeft: "10px" }}
                                      size="small"
                                    /> */}

                                    <TextField
                                      type="text" // Changed to text to allow custom handling
                                      value={getCustomInputValue(input)}
                                      name={input?.name}
                                      onChange={(e) => handleChangeCustomInputValue(e, input)}
                                      onKeyPress={(e) => {
                                        if (parseInt(input?.type) === 0) {
                                          // For integers, only allow digits and minus sign
                                          if (!/[-\d]/.test(e.key)) {
                                            e.preventDefault();
                                            setShowErrorMessage(true);
                                            setCustomInputId(parseInt(input?.id));
                                            setErrorMessage("Please enter integer value");
                                          }
                                        } else if (parseInt(input?.type) === 1) {
                                          // For floats, allow digits, minus sign, and decimal point
                                          if (!/[-.\d]/.test(e.key)) {
                                            e.preventDefault();
                                            setShowErrorMessage(true);
                                            setCustomInputId(parseInt(input?.id));
                                            setErrorMessage("Please enter float value");
                                          }
                                          // Prevent multiple decimal points
                                          if (e.key === '.' && getCustomInputValue(input).includes('.')) {
                                            e.preventDefault();
                                          }
                                        }
                                      }}
                                      sx={{ width: "200px", marginLeft: "10px" }}
                                      size="small"
                                    />
                                    {showErrorMessage && parseInt(input?.id) === customInputId ? <FormHelperText error sx={{ paddingLeft: "12px" }}>{errorMessage}</FormHelperText> : null}
                                  </Box>
                                ) : (
                                  <Select
                                    value={getCustomInputValue(input)}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    sx={{ width: "200px", height: "40px", marginLeft: "10px" }}
                                    onChange={(e) => handleChangeCustomInputValue(e, input)}
                                  >
                                    {input?.values?.values?.map((value) => (
                                      <MenuItem value={value} key={value}>{value}</MenuItem>
                                    ))}
                                  </Select>
                                )}
                              </Box>
                            ))}
                        </Box>
                        <Box>
                          <QCChecklistTable
                            qcProgressData={qcChecklistData?.qcChecklistProgress}
                            pierTorqueTubeId={selectedPierOrTorqueTubeId}
                            type={type}
                            typeName={typeName}
                            trackeRowId={parseInt(params.trackerrowid)}
                            trackerTypeId={parseInt(params.trackertypeid)}
                            blockDetails={blockDetails}
                            imageUrls={qcChecklistData?.qcImageUrls}
                            visibility={visibility}
                            mapType={mapType} />
                        </Box>
                      </Box>

                    </CustomTabPanel>
                    <CustomTabPanel value={type} index={1}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{ padding: "10px 10px 5px 10px" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            {torqueTubeLabel === "" ? "Bays" : `Bay ${torqueTubeLabel}`}
                          </Typography>

                        </Box>
                        <Box ref={containerRef} sx={{ padding: "0px 10px 5px 10px" }}>
                          {qcChecklistData?.qcChecklistProgress?.custom_inputs &&
                            qcChecklistData?.qcChecklistProgress?.custom_inputs.length > 0 &&
                            qcChecklistData?.qcChecklistProgress?.custom_inputs.map((input, index) => (
                              <Box sx={{ display: "flex", alignItems: "center", margin: "5px 0", position: "relative" }} key={input?.id}>
                                <InputLabel
                                  ref={el => labelRefs.current[input.id] = el}
                                  sx={{
                                    width: "265px",
                                    position: "relative",
                                    cursor: "pointer",
                                    '&::after': {
                                      // content: '"..."',
                                      position: 'absolute',
                                      right: 0,
                                      bottom: 0,
                                      background: 'white',
                                      padding: '0 4px',
                                    }
                                  }}
                                  onClick={(e) => handleShowCustomLabel(e, input)}
                                >
                                  <Typography
                                    sx={{
                                      width: "260px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {input?.name}
                                  </Typography>
                                </InputLabel>
                                {tooltipState.show && tooltipState.id === input.id && (
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      zIndex: 1000,
                                      backgroundColor: "white",
                                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                      padding: "8px 10px",
                                      borderRadius: "4px",
                                      wordWrap: "break-word",
                                      top: "100%",
                                      left: 0,
                                      minWidth: "265px",
                                      maxWidth: "400px",
                                      transform: index === qcChecklistData?.qcChecklistProgress?.custom_inputs.length - 1 ? 'translateY(-100%)' : 'none',
                                    }}
                                  >
                                    <Typography >{tooltipState.name}</Typography>
                                  </Box>
                                )}
                                {parseInt(input?.type) === 0 || parseInt(input?.type) === 1 || parseInt(input?.type) === 2 ? (
                                  <Box sx={{ width: "300px" }}>
                                    <TextField
                                      type="text" // Changed to text to allow custom handling
                                      value={getCustomInputValue(input)}
                                      name={input?.name}
                                      onChange={(e) => handleChangeCustomInputValue(e, input)}
                                      onKeyPress={(e) => {
                                        if (parseInt(input?.type) === 0) {
                                          // For integers, only allow digits and minus sign
                                          if (!/[-\d]/.test(e.key)) {
                                            e.preventDefault();
                                            setShowErrorMessage(true);
                                            setCustomInputId(parseInt(input?.id));
                                            setErrorMessage("Please enter integer value");
                                          }
                                        } else if (parseInt(input?.type) === 1) {
                                          // For floats, allow digits, minus sign, and decimal point
                                          if (!/[-.\d]/.test(e.key)) {
                                            e.preventDefault();
                                            setShowErrorMessage(true);
                                            setCustomInputId(parseInt(input?.id));
                                            setErrorMessage("Please enter float value");
                                          }
                                          // Prevent multiple decimal points
                                          if (e.key === '.' && getCustomInputValue(input).includes('.')) {
                                            e.preventDefault();
                                          }
                                        }
                                      }}
                                      sx={{ width: "200px", marginLeft: "10px" }}
                                      size="small"
                                    />
                                    {showErrorMessage && parseInt(input?.id) === customInputId ? <FormHelperText error sx={{ paddingLeft: "12px" }}>{errorMessage}</FormHelperText> : null}
                                  </Box>
                                ) : (
                                  <Select
                                    value={getCustomInputValue(input)}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    sx={{ width: "200px", height: "40px", marginLeft: "10px" }}
                                    onChange={(e) => handleChangeCustomInputValue(e, input)}
                                  >
                                    {input?.values?.values?.map((value) => (
                                      <MenuItem value={value} key={value}>{value}</MenuItem>
                                    ))}
                                  </Select>
                                )}
                              </Box>
                            ))}
                        </Box>
                        <Box>
                          <QCChecklistTable
                            qcProgressData={qcChecklistData?.qcChecklistProgress}
                            pierTorqueTubeId={selectedPierOrTorqueTubeId}
                            type={type} typeName={typeName}
                            trackeRowId={parseInt(params.trackerrowid)}
                            trackerTypeId={parseInt(params.trackertypeid)}
                            blockDetails={blockDetails}
                            imageUrls={qcChecklistData?.qcImageUrls}
                            visibility={visibility}
                            mapType={mapType} />
                        </Box>
                      </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={type} index={2}>
                      <Box sx={{ display: "flex", flexDirection: "column", }}>
                        <Box sx={{ padding: "10px 10px 5px 10px" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            {torqueTubeLabel === "" ? "Bays" : `Bay ${torqueTubeLabel}`}
                          </Typography>
                        </Box>
                        <Box ref={containerRef} sx={{ padding: "0px 10px 5px 10px" }}>
                          {qcChecklistData?.qcChecklistProgress?.custom_inputs &&
                            qcChecklistData?.qcChecklistProgress?.custom_inputs.length > 0 &&
                            qcChecklistData?.qcChecklistProgress?.custom_inputs.map((input, index) => (
                              <Box sx={{ display: "flex", alignItems: "center", margin: "5px 0", position: "relative" }} key={input?.id}>
                                <InputLabel
                                  ref={el => labelRefs.current[input.id] = el}
                                  sx={{
                                    width: "265px",
                                    position: "relative",
                                    cursor: "pointer",
                                    '&::after': {
                                      // content: '"..."',
                                      position: 'absolute',
                                      right: 0,
                                      bottom: 0,
                                      background: 'white',
                                      padding: '0 4px',
                                    }
                                  }}
                                  onClick={(e) => handleShowCustomLabel(e, input)}
                                >
                                  <Typography
                                    sx={{
                                      width: "260px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {input?.name}
                                  </Typography>
                                </InputLabel>
                                {tooltipState.show && tooltipState.id === input.id && (
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      zIndex: 1000,
                                      backgroundColor: "white",
                                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                      padding: "8px 10px",
                                      borderRadius: "4px",
                                      wordWrap: "break-word",
                                      top: "100%",
                                      left: 0,
                                      minWidth: "265px",
                                      maxWidth: "400px",
                                      transform: index === qcChecklistData?.qcChecklistProgress?.custom_inputs.length - 1 ? 'translateY(-100%)' : 'none',
                                    }}
                                  >
                                    <Typography >{tooltipState.name}</Typography>
                                  </Box>
                                )}
                                {parseInt(input?.type) === 0 || parseInt(input?.type) === 1 || parseInt(input?.type) === 2 ? (
                                  <Box sx={{ width: "300px" }}>
                                    <TextField
                                      type="text" // Changed to text to allow custom handling
                                      value={getCustomInputValue(input)}
                                      name={input?.name}
                                      onChange={(e) => handleChangeCustomInputValue(e, input)}
                                      onKeyPress={(e) => {
                                        if (parseInt(input?.type) === 0) {
                                          // For integers, only allow digits and minus sign
                                          if (!/[-\d]/.test(e.key)) {
                                            e.preventDefault();
                                            setShowErrorMessage(true);
                                            setCustomInputId(parseInt(input?.id));
                                            setErrorMessage("Please enter integer value");
                                          }
                                        } else if (parseInt(input?.type) === 1) {
                                          // For floats, allow digits, minus sign, and decimal point
                                          if (!/[-.\d]/.test(e.key)) {
                                            e.preventDefault();
                                            setShowErrorMessage(true);
                                            setCustomInputId(parseInt(input?.id));
                                            setErrorMessage("Please enter float value");
                                          }
                                          // Prevent multiple decimal points
                                          if (e.key === '.' && getCustomInputValue(input).includes('.')) {
                                            e.preventDefault();
                                          }
                                        }
                                      }}
                                      sx={{ width: "200px", marginLeft: "10px" }}
                                      size="small"
                                    />
                                    {showErrorMessage && parseInt(input?.id) === customInputId ? <FormHelperText error sx={{ paddingLeft: "12px" }}>{errorMessage}</FormHelperText> : null}
                                  </Box>
                                ) : (
                                  <Select
                                    value={getCustomInputValue(input)}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    sx={{ width: "200px", height: "40px", marginLeft: "10px" }}
                                    onChange={(e) => handleChangeCustomInputValue(e, input)}
                                  >
                                    {input?.values?.values?.map((value) => (
                                      <MenuItem value={value} key={value}>{value}</MenuItem>
                                    ))}
                                  </Select>
                                )}
                              </Box>
                            ))}
                        </Box>
                        <Box>
                          <QCChecklistTable
                            qcProgressData={qcChecklistData?.qcChecklistProgress}
                            pierTorqueTubeId={selectedPierOrTorqueTubeId}
                            type={type} typeName={typeName}
                            trackeRowId={parseInt(params.trackerrowid)}
                            trackerTypeId={parseInt(params.trackertypeid)}
                            blockDetails={blockDetails}
                            imageUrls={qcChecklistData?.qcImageUrls} visibility={visibility} mapType={mapType} />
                        </Box>
                      </Box>
                    </CustomTabPanel>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid >
      </Box >
      <Modal
        open={backModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "35%",
            backgroundColor: "#FFFFFF",
            color: "black",
            borderRadius: "20px",
            padding: "10px 20px"
          }}
        >
          <Box
            sx={{
              padding: "10px",
            }}
          >
            <Typography sx={{ fontSize: "20px" }}>
              {/* Please enter a comment and add the item to a punchlist */}
              Enter comment for the failed item
            </Typography>
          </Box>

          <Box
            sx={{
              // padding: "10px 5px",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button variant="contained" onClick={handleCloseBackModal} sx={{ textTransform: "none", marginTop: "5px" }}>
              Ok
            </Button>
          </Box>
        </Box>
      </Modal>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        style={{ width: "440px" }}
      />
    </Box >
  );
};

export default QcDataChecksheet