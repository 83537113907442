
import {
  Button,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableBody,
  TableRow,
} from "@mui/material";
import { Box, Typography } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import selectedRowsAtom from "../../../data/selectedRowsAtom";
import { GrCircleInformation } from "react-icons/gr";
import SegmentedControl from "../../../components/qcPunchlist/segmentedControl";
import { fetchQCBlockIdAtom, fetchQCPunchlistFilterConditionsAtom } from "../../../data/qualityControlAtom";
import { fetchBlockAtom, fetchPierLegendAtom, fetchBulkPiersTorqueTubesBlockDataAtom } from "../../../data/dashboardAtom";
import { get_qc_punchlist } from "../../../dal/qualityCheckProgress";
import { IS_PROJECT_TYPE_ZIMMERMAN_FLAG } from "../../../featureFlags/featureFlags";
import { clearQCStorageData } from "../../../utils/storageUtils";

const data = [{
  id: 1,
  punchlist_status: 0,
  name: "Open"
}, {
  id: 2,
  punchlist_status: 1,
  name: "Ready for Inspection"
}, {
  id: 3,
  punchlist_status: 2,
  name: "Resolved"
},
]

const QcData = () => {

  const navigate = useNavigate();
  const params = useParams();
  const [selectedRows] = useAtom(selectedRowsAtom);
  const [toggleValue, setToggleValue] = useState(1)
  const [, fetchQCPunchlistFilterConditions] = useAtom(fetchQCPunchlistFilterConditionsAtom)
  const [, fetchBlocks] = useAtom(fetchBlockAtom)
  const [, fetchPierLegend] = useAtom(fetchPierLegendAtom)
  const [, fetchBulkPiersTorqueTubesBlockData] = useAtom(fetchBulkPiersTorqueTubesBlockDataAtom)
  const [, fetchQCBlockId] = useAtom(fetchQCBlockIdAtom)
  const [count, setCount] = useState([])
  const [isZimmerman, setIsZimmerman] = useState(false)
  const location = useLocation()

  useEffect(() => {
    getCountsStatusWiseForProjectandBlock()
  }, [])

  useEffect(() => {
    async function fetchProjectTypeFlag() {
      let is_zimmeran = await IS_PROJECT_TYPE_ZIMMERMAN_FLAG()
      setIsZimmerman(is_zimmeran)
    }
    fetchProjectTypeFlag();
  }, [])

  const getCountsStatusWiseForProjectandBlock = async () => {

    let blockFilterData = [
      { punchlist_status: 0, visibility: 0, block_id: parseInt(params?.id) },
      { punchlist_status: 1, visibility: 0, block_id: parseInt(params?.id) },
      { punchlist_status: 2, visibility: 0, block_id: parseInt(params?.id) },
      { punchlist_status: 0, visibility: 1, block_id: parseInt(params?.id) },
      { punchlist_status: 1, visibility: 1, block_id: parseInt(params?.id) },
      { punchlist_status: 2, visibility: 1, block_id: parseInt(params?.id) }
    ]
    let projectFilterData = [
      { punchlist_status: 0, visibility: 0 },
      { punchlist_status: 1, visibility: 0 },
      { punchlist_status: 2, visibility: 0 },
      { punchlist_status: 0, visibility: 1 },
      { punchlist_status: 1, visibility: 1 },
      { punchlist_status: 2, visibility: 1 }
    ]
    let countsListArray = []
    if (location.pathname === `/home/gpslocation/${params.id}/qcdata`) {
      for (let i of blockFilterData) {
        let data = await get_qc_punchlist(i)
        countsListArray.push({ count: Object.keys(data).length, punchlist_status: i?.punchlist_status, visibility: i?.visibility, type: 1, block_id: i?.block_id })
      }
    }
    for (let i of projectFilterData) {
      let data = await get_qc_punchlist(i)
      countsListArray.push({ count: Object.keys(data).length, punchlist_status: i?.punchlist_status, visibility: i?.visibility, type: 0, })
    }
    setCount(countsListArray)
  }

  const getCounts = (punchlist_status, visibility) => {

    let counts = 0
    if (count.length !== 0) {
      for (let i of count) {
        if (i?.block_id !== undefined && toggleValue === 1) {
          if (i?.punchlist_status === punchlist_status && i?.visibility === visibility && i?.type === toggleValue && i?.block_id === parseInt(params.id)) {
            counts = i?.count
          }
        } else {
          if (i?.punchlist_status === punchlist_status && i?.visibility === visibility && i?.type === toggleValue) {
            counts = i?.count
          }
        }
      }
    }
    return counts
  }

  const handlePunchlistNavigation = async (data, visibility) => {
    let indexValue = 1
    if (visibility === 1) {
      indexValue = 0
    }
    localStorage.setItem("punchlist-visibility-index", indexValue)
    if (parseInt(toggleValue) === 1) {
      await fetchQCBlockId(parseInt(params.id))
    }
    if (location.pathname === `/home/gpslocation/${params.id}/qcdata`) {
      navigate(`/home/gpslocation/${params?.id}/qcpunchlist`)
    } else {
      navigate(`qcpunchlist`)
    }
    let filterConditions = {}
    if (parseInt(toggleValue) === 1) {
      filterConditions = { visibility: visibility, punchlist_status: data?.punchlist_status, block_id: parseInt(params?.id) }
    }
    else {
      filterConditions = { visibility: visibility, punchlist_status: data?.punchlist_status }
    }
    try {
      await fetchQCPunchlistFilterConditions(filterConditions)
      await fetchBlocks()
    } catch (err) {
      console.log(err)
    }
  }

  // useEffect(() => {
  //   // Cleanup when component unmounts
  //   return () => {
  //     clearQCStorageData();
  //   };
  // }, []);


  useEffect(() => {
    init()
    if (location.pathname === `/home/qcdata`) {
      setToggleValue(0)
    }
    localStorage.setItem("punchlist-visibility-index", 0)

  }, [])

  const init = async () => {
    try {
      await fetchBulkPiersTorqueTubesBlockData(parseInt(params?.id))
      await fetchPierLegend()
    } catch (err) {
      console.log(err)
    }
  }

  const handleOpenChecksheet = () => {
    if (selectedRows.length === 1) {
      let trackerrowId = parseInt(selectedRows[0]?.split("-")[1])
      let trackertypeId = parseInt(selectedRows[0]?.split("-")[2])

      navigate(
        `/home/gpslocation/${params.id}/qcdatachecksheet/${trackertypeId}/${trackerrowId}`
      );
    }

  }

  useEffect(() => {
    if (location.pathname === `/home/qcdata`) {
      setToggleValue(0)
    }
    localStorage.setItem("punchlist-visibility-index", 0)

  }, [])

  const handleToggleChange = (value) => {
    setToggleValue(value)
    localStorage.setItem("punchlist-visibility-index", value)
  }


  return (
    <Box>
      <Box
        style={{
          height: "80vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ padding: "12px 20px" }}>
          <Typography sx={{ paddingBottom: "5px", fontWeight: 500, fontSize: "16px" }}>Checklist</Typography>
          <Box sx={{ marginRight: "5px" }}>
            <Button
              disabled={
                selectedRows.length === 0 || selectedRows.length > 1
                  ? true
                  : false
              }
              sx={{
                backgroundColor:
                  selectedRows.length === 0 || selectedRows.length > 1
                    ? "rgba(105, 111, 121, 0.08)"
                    : "#186DEC",
                border: "1px solid rgba(105, 111, 121, 0.48)",
                height: "40px",
                width: "100%",
                padding: "10px",
                textTransform: "none",
                color: selectedRows.length > 1 ? "#1B1819" : "#FFFFFF",
                borderRadius: "5px",
              }}
              onClick={handleOpenChecksheet}
            >
              {
                selectedRows.length === 0 || selectedRows.length > 1 ?
                  <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",

                  }}>
                    <GrCircleInformation style={{ margin: "0px 8px", }} fontSize={18} />
                    <Typography sx={{ fontSize: "14px" }}>{isZimmerman === true ? "Select a table for the checklist" : "Select a tracker for the checklist"}</Typography>
                  </Box> : (
                    <Box sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      padding: "10px",
                    }}>
                      <Typography sx={{ fontSize: "14px" }}>Open Checklist</Typography>
                      <EastIcon />
                    </Box>)}
            </Button>
          </Box>
          <Box>
            <Typography sx={{ paddingTop: "15px", paddingBottom: "2px", fontWeight: 500, fontSize: "16px" }}>Punchlist</Typography>
            <Box sx={{ display: location.pathname === `/home/gpslocation/${params.id}/qcdata` ? "block" : "none" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginY: "10px", }}>

                <SegmentedControl
                  name="group"
                  callback={(val) => {
                    handleToggleChange(val)
                  }}
                  controlRef={useRef()}
                  segments={[
                    {
                      label: (
                        <Box sx={{ display: "flex", alignItems: "center", }}>
                          <Typography>Block</Typography>
                        </Box>
                      ),
                      value: 1,
                      ref: useRef(),
                    },
                    {
                      label: (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography>Project</Typography>
                        </Box>
                      ),
                      value: 0,
                      ref: useRef(),
                    },
                  ]}
                  defaultIndex={0}
                />
              </Box>
            </Box>
            <Box sx={{ display: location.pathname === `/home/qcdata` ? "block" : "none" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginY: "10px", }}>
                <SegmentedControl
                  name="group"
                  callback={(val) => {
                    handleToggleChange(val)
                  }}
                  controlRef={useRef()}
                  segments={[
                    {
                      label: (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography>Project</Typography>
                        </Box>
                      ),
                      value: 0,
                      ref: useRef(),
                    },
                  ]}
                  defaultIndex={0}
                />
              </Box>
            </Box>
            <Box sx={{ marginRight: "5px" }}>
              <TableContainer>
                <Table sx={{ border: "1px solid rgba(224, 224, 224, 1);" }}>
                  <TableHead sx={{ backgroundColor: "rgba(105, 111, 121, 0.08)", }}>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "normal", textAlign: "center", padding: "10px 16px" }}>External</TableCell>
                      <TableCell sx={{ fontWeight: "normal", textAlign: "center", padding: "10px 16px" }}>Internal</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((i, rowIndex) => (
                      <TableRow>
                        <TableCell sx={{ paddingLeft: "30px", textAlign: "left" }} key={rowIndex} onClick={() => handlePunchlistNavigation(i, 1)}>
                          <span style={{ padding: "0px 3px", fontSize: "22px", fontWeight: "500", color: "blue", textDecoration: "underline" }}>{getCounts(i?.punchlist_status, 1)}</span>{i?.name}</TableCell>
                        <TableCell sx={{ paddingLeft: "30px", textAlign: "left" }} key={rowIndex} onClick={() => handlePunchlistNavigation(i, 0)}>
                          <span style={{ padding: "0px 3px", fontSize: "22px", fontWeight: "500", color: "blue", textDecoration: "underline" }}>{getCounts(i?.punchlist_status, 0)}</span>{i?.name}</TableCell>
                      </TableRow>
                    )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box >
  );
};

export default QcData;
