import axios from "axios"
import suryadb from "../indexedDB/module"
import surya_commondb from "../indexedDB/imageModule"
import { fetchImages, get_list_of_images, internal_image_ids_to_be_downloaded, public_image_ids_to_be_downloaded, upload_add_images, backgroundFetchImages } from "./images"
import { get_bulk_piers_torquetubes } from "./layout"
import handleGetTimeStamp from "../utils/epocTimeConvertor"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuid } from 'uuid';
import { convertBase64ToFile } from "../utils/decoder"
// import { convertBase64ToFile, checkUserGeneratedID } from '../utils/helpers'; // Adjust import path





export async function get_qc_progress_for_d3_color(blockId, visibility, scope) {
    let data = await suryadb().get_qc_d3_color.get(blockId)
    let visibilityName = "external"
    if (parseInt(visibility) === 0) {
        visibilityName = "internal"
    }
    let qcD3ColorData = {}
    if (data !== undefined) {
        if (data["data"] && data["data"][visibilityName] && data["data"][visibilityName][scope]) {
            qcD3ColorData = data["data"][visibilityName][scope]

        }
    }

    return qcD3ColorData

}

// export async function get_qc_progress_for_d3_color(blockId, visibility, type) {
//     let qc_progress_data = {}
//     let blockData = await get_bulk_piers_torquetubes(blockId)
//     if (type === "piers") {
//         blockData = blockData?.piers
//     } else {
//         blockData = blockData?.tt
//     }
//     for (let i in blockData) {
//         let trackerRowId_type_key = `${i}_${type}`
//         let tracker_type_id = blockData[i][0].tracker_type_id
//         let qc_cheklist_combined = await get_qc_checklist(tracker_type_id, type)
//         if (Object.keys(qc_cheklist_combined).length > 0) {
//             let qc_progress = await get_qc_progress(type, visibility, blockId, 0)
//             let qcCheckpointsProgress = []
//             for (let y of qc_cheklist_combined?.checkpoints) {
//                 let key = `${i}_${type}_${y?.checkpoint_id}`
//                 let obj = {}
//                 obj.checkpoint_id = y?.checkpoint_id
//                 obj.checkpoint_name = y?.checkpoint_name
//                 obj.qc_progress_visibility = visibility
//                 if (qc_progress[key]) {
//                     obj.qc_progress_status = qc_progress[key].status
//                     obj.qc_progress_comment = qc_progress[key].comments
//                     obj.qc_progress_punchlist_status = qc_progress[key].punchlist_status
//                 } else {
//                     obj.qc_progress_status = null
//                     obj.qc_progress_comment = null
//                     obj.qc_progress_punchlist_status = null
//                 }
//                 qcCheckpointsProgress.push(obj)
//             }
//             qc_progress_data[trackerRowId_type_key] = qcCheckpointsProgress
//         } else {
//             // let qc_progress = await get_qc_progress(type, visibility, blockId, 1)
//             for (let j of blockData[i]) {
//                 let trackerRowId_pierttid_type_key = `${i}_${j?.id}_${type}`
//                 let type_id = 0
//                 if (type === "piers") {
//                     type_id = j?.pier_type_id
//                 }
//                 else {
//                     type_id = j?.tt_type_id
//                 }
//                 let qc_cheklist_individual = await get_qc_checklist(tracker_type_id, type, type_id)
//                 if (Object.keys(qc_cheklist_individual).length > 0) {

//                     let qcCheckpointsProgress = []
//                     for (let y of qc_cheklist_individual?.checkpoints) {
//                         let qc_progress = await get_qc_progress(type, visibility, blockId, 1)
//                         let key = `${i}_${j?.id}_${type}_${y?.checkpoint_id}`
//                         let obj = {}
//                         obj.checkpoint_id = y?.checkpoint_id
//                         obj.checkpoint_name = y?.checkpoint_name
//                         obj.qc_progress_visibility = visibility
//                         if (qc_progress[key]) {
//                             obj.qc_progress_status = qc_progress[key].status
//                             obj.qc_progress_comment = qc_progress[key].comments
//                             obj.qc_progress_punchlist_status = qc_progress[key].punchlist_status
//                         } else {
//                             obj.qc_progress_status = null
//                             obj.qc_progress_comment = null
//                             obj.qc_progress_punchlist_status = null
//                         }
//                         qcCheckpointsProgress.push(obj)
//                     }
//                     qc_progress_data[trackerRowId_pierttid_type_key] = qcCheckpointsProgress
//                 }
//             }
//         }
//     }
//     return qc_progress_data

// }
export async function insert_qc_bulk_checklist_data() {
    if (!localStorage.getItem("get-data-initially")) {
        let data = await axios.get(`/projects/fetch_checklist_mapping_and_checkpoints`)
        data = data?.data
        for (let i in data) {
            await suryadb().qc_checklist_mapping.put({ id: i, data: data[i] })
        }
    }
    localStorage.setItem("get-data-initially", true)
}
export async function insert_pier_torquetube_parts_bulk() {
    let data = await axios.get(`/projects/get_pier_tt_parts?bulk=true`)
    data = data?.data
    for (let i in data) {
        await suryadb().get_pier_torquetube_parts_bulk.put({ id: i, data: data[i] })
    }
}
export async function get_qc_checklist_custom_response(trackerRowId, scope, visibility, block_id, mapType, selectedPierOrTorqueTubeId) {
    let customResponseData = await get_qc_custom_response(scope, visibility, block_id, mapType)
    let key = ""
    let data = []
    if (parseInt(mapType) === 1) {
        key = `${trackerRowId}_${selectedPierOrTorqueTubeId}_${scope}`
    } else {
        key = `${trackerRowId}_${scope}`
    }
    if (customResponseData && Object.keys(customResponseData).length > 0) {
        if (customResponseData[key]) {
            data = customResponseData[key]
        }
    }
    return data
}
export async function get_qc_custom_response(scope, visibility, block_id, mapType) {
    let data = {}
    if (parseInt(mapType) === 1 && parseInt(visibility) === 1 && scope === "piers") {
        let qcExternalPiersCustomResponse = await suryadb().qc_custom_response_piers_external.get(block_id)
        if (qcExternalPiersCustomResponse !== undefined) {
            if (qcExternalPiersCustomResponse?.data && Object.keys(qcExternalPiersCustomResponse?.data).length > 0) {
                data = qcExternalPiersCustomResponse?.data?.custom_response_external_pier
            } else {
                data = qcExternalPiersCustomResponse?.data
            }

        }
    }
    if (parseInt(mapType) === 1 && parseInt(visibility) === 1 && (scope === "structure" || scope === "module")) {
        let qcExternalStructureModuleCustomResponse = await suryadb().qc_custom_response_tt_external.get(block_id)
        if (qcExternalStructureModuleCustomResponse !== undefined) {
            if (qcExternalStructureModuleCustomResponse?.data && Object.keys(qcExternalStructureModuleCustomResponse?.data).length > 0) {
                data = qcExternalStructureModuleCustomResponse?.data?.custom_response_external_tt
            } else {
                data = qcExternalStructureModuleCustomResponse?.data
            }
        }
    }
    if (parseInt(mapType) === 1 && parseInt(visibility) === 0 && scope === "piers") {
        let qcInternalPiersCustomResponse = await suryadb().qc_custom_response_piers_internal.get(block_id)
        if (qcInternalPiersCustomResponse !== undefined) {
            if (qcInternalPiersCustomResponse?.data && Object.keys(qcInternalPiersCustomResponse?.data).length > 0) {
                data = qcInternalPiersCustomResponse?.data?.custom_response_internal_pier
            } else {
                data = qcInternalPiersCustomResponse?.data
            }
        }
    }
    if (parseInt(mapType) === 1 && parseInt(visibility) === 0 && (scope === "structure" || scope === "module")) {
        let qcInternalStructureModuleCustomResponse = await suryadb().qc_custom_response_tt_internal.get(block_id)
        if (qcInternalStructureModuleCustomResponse !== undefined) {
            if (qcInternalStructureModuleCustomResponse?.data && Object.keys(qcInternalStructureModuleCustomResponse?.data).length > 0) {
                data = qcInternalStructureModuleCustomResponse?.data?.custom_response_internal_tt
            } else {
                data = qcInternalStructureModuleCustomResponse?.data
            }
        }
    }
    if (parseInt(mapType) === 0 && parseInt(visibility) === 0) {
        let qcInternalTrackerrowCustomResponse = await suryadb().qc_custom_response_trackerrow_internal.get(block_id)
        if (qcInternalTrackerrowCustomResponse !== undefined) {
            if (qcInternalTrackerrowCustomResponse?.data && Object.keys(qcInternalTrackerrowCustomResponse?.data).length > 0) {
                data = qcInternalTrackerrowCustomResponse?.data?.custom_response_internal_tracker_row
            } else {
                data = qcInternalTrackerrowCustomResponse?.data
            }
        }
    }
    if (parseInt(mapType) === 0 && parseInt(visibility) === 1) {
        let qcExternalTrackerrowCustomResponse = await suryadb().qc_custom_response_trackerrow_external.get(block_id)
        if (qcExternalTrackerrowCustomResponse !== undefined) {
            if (qcExternalTrackerrowCustomResponse?.data && Object.keys(qcExternalTrackerrowCustomResponse?.data).length > 0) {
                data = qcExternalTrackerrowCustomResponse?.data?.custom_response_external_tracker_row
            } else {
                data = qcExternalTrackerrowCustomResponse?.data
            }
        }
    }
    return data
}

export async function get_qc_checklist_progress(trackerTypeId, trackerRowId, scope, visibility, block_id, mapType, typeId, selectedPierOrTorqueTubeId) {

    let arr = []
    let checkpointsObj = {}
    let qcChecklistData = await get_qc_checklist(trackerTypeId, scope, typeId, mapType)
    let qcProgressData = await get_qc_progress(scope, visibility, block_id, mapType)
    if (qcChecklistData?.checkpoints && qcChecklistData?.checkpoints.length > 0) {
        let checkpointsData = qcChecklistData?.checkpoints
        let sortedCheckointsData = checkpointsData.sort((a, b) => a.id - b.id);
        for (let i of sortedCheckointsData) {
            let obj = {}
            let key = ""
            if (parseInt(mapType) === 1) {
                key = `${trackerRowId}_${selectedPierOrTorqueTubeId}_${i?.id}`
            } else {
                key = `${trackerRowId}_${i?.id}`
            }
            obj.checkpoint_id = i?.id
            obj.acceptance_criteria = i?.acceptance_criteria
            obj.checkpoint_name = i?.name
            obj.inspection_type = i?.inspection_type
            if (qcProgressData[key]) {
                obj.comments = qcProgressData[key]?.comments
                obj.photos = qcProgressData[key]?.document
                obj.status = qcProgressData[key]?.status
            }
            else {
                obj.comments = ""
                obj.photos = []
                obj.status = 0
            }
            arr.push(obj)
        }

    }
    checkpointsObj["checkpoints"] = arr

    let customInputsData = qcChecklistData?.custom_inputs
    let sortedCustomInputsData = []
    if (customInputsData !== undefined) {
        sortedCustomInputsData = customInputsData.sort((a, b) => a.id - b.id);
    }
    checkpointsObj["custom_inputs"] = sortedCustomInputsData
    return checkpointsObj

}


export async function get_qc_checklist_trackertypewise(trackerTypeId, scope) {
    let data = await suryadb().qc_checklist_mapping.get("tracker_type_map_type")
    data = data?.data
    let mapType = 1
    let trackerTypeId_type_key = `${trackerTypeId}_${scope}`

    if (data[trackerTypeId_type_key] !== undefined) {
        mapType = data[trackerTypeId_type_key]
    }

    // for (let i of data) {
    //     if (i?.id === trackerTypeId_type_key) {
    //         if (parseInt(i?.data?.map_type) === 0) {
    //             checkPointsData = i?.data
    //         }
    //     }
    // }
    return mapType
}
export async function get_qc_checklist(trackerTypeId, scope, typeId, mapType) {
    let data = await suryadb().qc_checklist_mapping.get("checklists")
    data = data?.data
    let checkPointsData = []
    let key = ""
    if (parseInt(mapType) === 0) {
        key = `${trackerTypeId}_${scope}`
    } else {
        key = `${typeId}_${scope}`
    }
    if (data[key] !== undefined) {
        checkPointsData = data[key]
    }
    // for (let i of data) {
    //     if (i?.id === trackerTypeId_type_key) {
    //         if (parseInt(i?.data?.map_type) === 0) {
    //             checkPointsData = i?.data
    //         }
    //     } else if (i?.id === trackerTypeId_pierTypeId_type_key) {

    //         checkPointsData = i?.data
    //     }
    //     // else if (i?.id === projectId_type) {

    //     //     checkPointsData = i?.data
    //     // }
    // }

    return checkPointsData
}

async function get_qc_progress(scope, visibility, block_id, mapType) {
    let data = {}
    if (parseInt(mapType) === 1 && parseInt(visibility) === 1 && scope === "piers") {
        let qcExternalPiers = await suryadb().qc_progress_external_piers.get(block_id)
        if (qcExternalPiers !== undefined) {
            if (qcExternalPiers?.data && Object.keys(qcExternalPiers?.data).length > 0) {
                data = qcExternalPiers?.data?.qc_progress_external_pier
            } else {
                data = qcExternalPiers?.data
            }

        }
    }
    if (parseInt(mapType) === 1 && parseInt(visibility) === 1 && (scope === "structure" || scope === "module")) {
        let qcExternalStructureModule = await suryadb().qc_progress_external_torquetubes.get(block_id)
        if (qcExternalStructureModule !== undefined) {
            if (qcExternalStructureModule?.data && Object.keys(qcExternalStructureModule?.data).length > 0) {
                data = qcExternalStructureModule?.data?.qc_progress_external_torquetube
            } else {
                data = qcExternalStructureModule?.data
            }
        }
    }
    if (parseInt(mapType) === 1 && parseInt(visibility) === 0 && scope === "piers") {
        let qcInternalPiers = await suryadb().qc_progress_internal_piers.get(block_id)
        if (qcInternalPiers !== undefined) {
            if (qcInternalPiers?.data && Object.keys(qcInternalPiers?.data).length > 0) {
                data = qcInternalPiers?.data?.qc_progress_internal_pier
            } else {
                data = qcInternalPiers?.data
            }
        }
    }
    if (parseInt(mapType) === 1 && parseInt(visibility) === 0 && (scope === "structure" || scope === "module")) {
        let qcInternalStructureModule = await suryadb().qc_progress_internal_torquetubes.get(block_id)
        if (qcInternalStructureModule !== undefined) {
            if (qcInternalStructureModule?.data && Object.keys(qcInternalStructureModule?.data).length > 0) {
                data = qcInternalStructureModule?.data?.qc_progress_internal_torquetube
            } else {
                data = qcInternalStructureModule?.data
            }
        }
    }
    if (parseInt(mapType) === 0 && parseInt(visibility) === 0) {
        let qcInternalTrackerrow = await suryadb().qc_progress_trackerrow_internal.get(block_id)
        if (qcInternalTrackerrow !== undefined) {
            if (qcInternalTrackerrow?.data && Object.keys(qcInternalTrackerrow?.data).length > 0) {
                data = qcInternalTrackerrow?.data?.qc_progress_internal_tracker_row
            } else {
                data = qcInternalTrackerrow?.data
            }
        }
    }

    if (parseInt(mapType) === 0 && parseInt(visibility) === 1) {
        let qcExternalTrackerrow = await suryadb().qc_progress_trackerrow_external.get(block_id)
        if (qcExternalTrackerrow !== undefined) {
            if (qcExternalTrackerrow?.data && Object.keys(qcExternalTrackerrow?.data).length > 0) {
                data = qcExternalTrackerrow?.data?.qc_progress_external_tracker_row
            } else {
                data = qcExternalTrackerrow?.data
            }
        }
    }

    return data

}

export async function get_qc_image_urls(blockId, visibility) {
    let data = {}
    let imagesData = await surya_commondb().images.toArray()
    let projectId = JSON.parse(localStorage.getItem("project_id"))
    let punchlist_name = ""
    if (parseInt(visibility) === 1) {
        punchlist_name = "external"
    } else {
        punchlist_name = "internal"
    }
    if (blockId !== "All") {
        let projectId_blockid_visibility_key = `${projectId}_${blockId}_${punchlist_name}`
        for (let i of imagesData) {
            if (i?.id === projectId_blockid_visibility_key) {
                data = i?.data
            }
        }
    } else {
        for (let i of imagesData) {
            let currentPunchlistName = i?.id.split("_")[2]
            let currentProjectId = i?.id.split("_")[0]
            if (currentPunchlistName === punchlist_name && parseInt(currentProjectId) === projectId) {
                data = { ...data, ...i.data };
            }
        }
    }
    return data
}
export async function update_qc(data) {
    let update_qc_queue_data = await update_qc_queue(data)
    if (data?.qc_type === "qc") {
        let update_qc_progress_data = await update_qc_progress(data)
        if (parseInt(data?.status) === 2 || parseInt(data?.punchlist_status) === 2) {
            let update_qc_punchlist_data = await update_qc_punchlist(data)
        }
    } else {
        let update_qc_custom_inputs = await update_qc_custom_response(data)
    }
}
const getMapType = async (data) => {
    let map_type = 1
    if (data?.pier_id === undefined && data?.tt_id === undefined) {
        map_type = 0
    }
    return map_type
}
async function update_qc_queue(data, isDelete, deletePunchlistStatus, qc_type) {

    let qcData = {}
    let key = ""
    let map_type = null
    if (data?.map_type === undefined) {
        map_type = await getMapType(data)
    } else {
        map_type = data?.map_type
    }
    if (data?.qc_type === "qc" || qc_type === "qc") {
        if (map_type === 1 &&( data?.pier_id || data?.tt_id)) {
            if (data?.pier_id !== undefined) {
                key = `${data.block_id}_${data?.tracker_row_id}_${data?.pier_id}_${data?.checkpoint_id}_${data?.visibility}_qc`
            } else {
                key = `${data.block_id}_${data?.tracker_row_id}_${data?.tt_id}_${data?.checkpoint_id}_${data?.visibility}_qc`
            }
        } else {
            key = `${data.block_id}_${data?.tracker_row_id}_${data?.checkpoint_id}_${data?.visibility}_qc`
        }
    } else {
        if (map_type === 1 && (data?.pier_id || data?.tt_id)) {
            if (data?.pier_id !== undefined) {
                key = `${data.block_id}_${data?.tracker_row_id}_${data?.pier_id}_${data?.custom_inputs_id}_${data?.visibility}_cr`
            } else {
                key = `${data.block_id}_${data?.tracker_row_id}_${data?.tt_id}_${data?.custom_inputs_id}_${data?.visibility}_cr`
            }
        } else {
            key = `${data.block_id}_${data?.tracker_row_id}_${data?.custom_inputs_id}_${data?.visibility}_cr`
        }
    }
    let imagesUrl = await get_list_of_images(data?.block_id, data?.visibility, data?.document)

    if (data?.qc_type === "qc" || qc_type === "qc") {
        if (parseInt(data?.status) === 2 || isDelete === true || parseInt(data?.punchlist_status) === 2) {
            qcData.checkpoint_id = data?.checkpoint_id;
            qcData.comments = data?.comments;
            qcData.status = deletePunchlistStatus !== undefined ? deletePunchlistStatus : data?.status;
            qcData.tracker_row_id = data?.tracker_row_id;
            qcData.images = data?.imageUrls ? data?.imageUrls : imagesUrl;
            qcData.visibility = data?.visibility;
            qcData.punchlist_status = data?.punchlist_status;
            qcData.type = data?.qc_type ? data?.qc_type : qc_type;
            // qcData.created_at = Date.now() / 1000
            if (map_type === 1 || data?.pier_id !== undefined || data?.tt_id !== undefined) {
                if (data?.pier_id !== undefined) {
                    qcData.pier_id = data?.pier_id
                } else {
                    qcData.tt_id = data?.tt_id
                }
            }
            if (isDelete === true) {
                qcData.punchlist_delete = 1
            }

        } else {
            qcData.checkpoint_id = data?.checkpoint_id;
            qcData.comments = data?.comments;
            qcData.status = data?.status;
            qcData.tracker_row_id = data?.tracker_row_id;
            qcData.visibility = data?.visibility;
            qcData.type = data?.qc_type
            if (map_type === 1) {
                if (data?.pier_id !== undefined) {
                    qcData.pier_id = data?.pier_id
                } else {
                    qcData.tt_id = data?.tt_id
                }
            }

        }
    } else {
        qcData.type = data?.qc_type;
        qcData.custom_input_id = data?.custom_inputs_id;
        qcData.visibility = data?.visibility;
        qcData.response = data?.response;
        qcData.tracker_row_id = data?.tracker_row_id;
        if (data?.pier_id) {
            qcData.pier_id = data?.pier_id;
        } else if (data?.tt_id) {
            qcData.tt_id = data?.tt_id;
        }
    }
    await suryadb().update_qc.put({
        id: key,
        data: [qcData], block_name: data?.block_name, block_id: data.block_id
    });

}
async function update_qc_custom_response(data) {
    let qc_custom_response_data = await get_qc_custom_response(data?.scope, data?.visibility, data?.block_id, data?.map_type)
    let key = ""
    let selectedPierOrTorqueTubeId = data?.pier_id
    if (data?.scope === "structure" || data?.scope === "module") {
        selectedPierOrTorqueTubeId = data?.tt_id
    }
    if (parseInt(data?.map_type) === 1) {
        key = `${data?.tracker_row_id}_${selectedPierOrTorqueTubeId}_${data?.scope}`
    } else {
        key = `${data?.tracker_row_id}_${data?.scope}`
    }
    let isKeyPresent = false
    if (qc_custom_response_data[key] === undefined) {
        qc_custom_response_data[key] = [data]
        isKeyPresent = true
    } else {
        if (isKeyPresent === false) {
            const existingObjectIndex = qc_custom_response_data[key].findIndex(obj => obj.custom_inputs_id === data?.custom_inputs_id);
            let responseArray = []
            if (existingObjectIndex !== -1) {
                responseArray = qc_custom_response_data[key].map(obj =>
                    obj.custom_inputs_id === parseInt(data?.custom_inputs_id) ? { ...obj, response: data?.response } : obj
                );
            } else {
                responseArray.push(...qc_custom_response_data[key], data)
            }

            qc_custom_response_data[key] = responseArray
        }
    }

    if (parseInt(data?.map_type) === 1 && parseInt(data?.visibility) === 1 && data?.scope === "piers") {
        let qcExternalPiersCustomResponse = await suryadb().qc_custom_response_piers_external.put({ id: parseInt(data?.block_id), data: { custom_response_external_pier: qc_custom_response_data } })
    }
    if (parseInt(data?.map_type) === 1 && parseInt(data?.visibility) === 1 && (data?.scope === "structure" || data?.scope === "module")) {
        let qcExternalStructureModuleCustomResponse = await suryadb().qc_custom_response_tt_external.put({ id: parseInt(data?.block_id), data: { custom_response_external_tt: qc_custom_response_data } })
    }
    if (parseInt(data?.map_type) === 1 && parseInt(data?.visibility) === 0 && data?.scope === "piers") {
        let qcInternalPiersCustomResponse = await suryadb().qc_custom_response_piers_internal.put({ id: parseInt(data?.block_id), data: { custom_response_internal_pier: qc_custom_response_data } })
    }
    if (parseInt(data?.map_type) === 1 && parseInt(data?.visibility) === 0 && (data?.scope === "structure" || data?.scope === "module")) {
        let qcInternalStructureModuleCustomResponse = await suryadb().qc_custom_response_tt_internal.put({ id: parseInt(data?.block_id), data: { custom_response_internal_tt: qc_custom_response_data } })
    }
    if (parseInt(data?.map_type) === 0 && parseInt(data?.visibility) === 0) {
        let qcInternalTrackerrowCustomResponse = await suryadb().qc_custom_response_trackerrow_internal.put({ id: parseInt(data?.block_id), data: { custom_response_internal_tracker_row: qc_custom_response_data } })
    }
    if (parseInt(data?.map_type) === 0 && parseInt(data?.visibility) === 1) {
        let qcExternalTrackerrowCustomResponse = await suryadb().qc_custom_response_trackerrow_external.put({ id: parseInt(data?.block_id), data: { custom_response_external_tracker_row: qc_custom_response_data } })
    }
}
async function update_qc_progress(data, updatedDeletedPunchlistStatus) {
    let map_type = null
    if (data?.map_type === undefined) {
        map_type = await getMapType(data)
    } else {
        map_type = data?.map_type
    }
    let qc_progress_data = await get_qc_progress(data?.scope, data?.visibility, data?.block_id, parseInt(map_type))

    let key = ""
    let selectedPierOrTorqueTubeId 
    
    if(data?.pier_id || data?.tt_id){
    if (data?.tt_id !== undefined) {
        selectedPierOrTorqueTubeId = data?.tt_id
    }else{
        selectedPierOrTorqueTubeId = data?.pier_id
    }
}


    if (parseInt(map_type) === 1) {
        key = `${data?.tracker_row_id}_${selectedPierOrTorqueTubeId}_${data?.checkpoint_id}`
    } else {
        key = `${data?.tracker_row_id}_${data?.checkpoint_id}`
    }
    let isKeyPresent = false
    if (qc_progress_data[key] === undefined) {
        qc_progress_data[key] = {
            comments: data?.comments,
            status: data?.status,
            document: data?.photos === undefined ? [] : data?.photos,
            checkpoint_id: data?.checkpoint_id
        }
        isKeyPresent = true
    } else {
        if (isKeyPresent === false) {
            qc_progress_data[key].comments = data?.comments;
            qc_progress_data[key].status = updatedDeletedPunchlistStatus !== undefined ? updatedDeletedPunchlistStatus : data?.status;
            qc_progress_data[key].document = data?.photos === undefined ? [] : data?.photos;
        }
    }
    if (parseInt(map_type) === 1 && parseInt(data?.visibility) === 1 && data?.pier_id !== undefined) {
        await suryadb().qc_progress_external_piers.put({ id: data?.block_id, data: { qc_progress_external_pier: qc_progress_data } })
    }
    if (parseInt(map_type) === 1 && parseInt(data?.visibility) === 1 && data?.tt_id !== undefined) {
        await suryadb().qc_progress_external_torquetubes.put({ id: data?.block_id, data: { qc_progress_external_torquetube: qc_progress_data } })
    }
    if (parseInt(map_type) === 1 && parseInt(data?.visibility) === 0 && data?.pier_id !== undefined) {
        await suryadb().qc_progress_internal_piers.put({ id: data?.block_id, data: { qc_progress_internal_pier: qc_progress_data } })
    }
    if (parseInt(map_type) === 1 && parseInt(data?.visibility) === 0 && data?.tt_id !== undefined) {
        await suryadb().qc_progress_internal_torquetubes.put({ id: data?.block_id, data: { qc_progress_internal_torquetube: qc_progress_data } })
    }
    if (parseInt(map_type) === 0 && parseInt(data?.visibility) === 0) {
        await suryadb().qc_progress_trackerrow_internal.put({ id: data?.block_id, data: { qc_progress_internal_tracker_row: qc_progress_data } })
    }
    if (parseInt(map_type) === 0 && parseInt(data?.visibility) === 1) {
        await suryadb().qc_progress_trackerrow_external.put({ id: data?.block_id, data: { qc_progress_external_tracker_row: qc_progress_data } })
    }
}
async function update_qc_punchlist(data) {
    let qc_punchlist_data = await get_qc_punchlist_block_or_project_wise(data?.visibility, data?.block_id)
    let selectedPierOrTorqueTubeId 
    if(data?.pier_id || data?.tt_id){
    if (data?.tt_id !== undefined) {
        selectedPierOrTorqueTubeId = data?.tt_id
    }else{
        selectedPierOrTorqueTubeId = data?.pier_id
    }
}
    let key = ""
    if (parseInt(data?.map_type) === 1) {
        key = `${data?.tracker_row_id}_${selectedPierOrTorqueTubeId}_${data?.checkpoint_id}`
    } else {
        key = `${data?.tracker_row_id}_${data?.checkpoint_id}`
    }
    if (qc_punchlist_data[key] === undefined) {
        qc_punchlist_data[key] = data
    } else {
        qc_punchlist_data[key].comments = data?.comments;
        qc_punchlist_data[key].punchlist_status = data?.punchlist_status;
        qc_punchlist_data[key].document = data?.photos;
    }
    
    if (parseInt(data?.visibility) === 1) {
        await suryadb().external_punchlist.put({ id: data?.block_id, data: qc_punchlist_data })
    }

    if (parseInt(data?.visibility) === 0) {
        await suryadb().internal_punchlist.put({ id: data?.block_id, data: qc_punchlist_data })
    }

}
export async function insert_qc_d3_color(blockId) {
    let data = await axios.get(`/projects/get_color_for_d3?block=${blockId}`)
    data = data?.data
    if (blockId === "all") {
        for (let i in data) {
            await suryadb().get_qc_d3_color.put({ id: parseInt(i), data: data[i] })
        }
    }
    else {
        await suryadb().get_qc_d3_color.put({ id: parseInt(blockId), data: data })
    }
}

export async function insert_qc_punchlist(blockId, internalPunchlistData, externalPunchlistData) {
    if(Object.keys(internalPunchlistData).length !== 0 && internalPunchlistData !== null){
    await suryadb().internal_punchlist.put({
        id: parseInt(blockId),
        data: internalPunchlistData,
    });
}
if(Object.keys(externalPunchlistData).length !== 0 && externalPunchlistData !== null){
    await suryadb().external_punchlist.put({
        id: parseInt(blockId),
        data: externalPunchlistData,
    });
}
    let qcInternalPunchlist = Object.values(internalPunchlistData)
    let qcPublicPunchlist = Object.values(externalPunchlistData)

    let imageIdsFromServerInternalPunchlist = []
    let imageIdsFromServerPublicPunchlist = []
    for (let i of qcInternalPunchlist) {
        if (i?.document && i?.document.length > 0) {
            for (let img of i?.document) {
                imageIdsFromServerInternalPunchlist.push(img)
            }
        }
    }
    for (let i of qcPublicPunchlist) {
        if (i?.document && i?.document.length > 0) {
            for (let img of i?.document) {
                imageIdsFromServerPublicPunchlist.push(img)
            }
        }
    }
    await internal_image_ids_to_be_downloaded(blockId, imageIdsFromServerInternalPunchlist, 0)
    await public_image_ids_to_be_downloaded(blockId, imageIdsFromServerPublicPunchlist, 1)

    // let internalImageIds = []
    // let publicImageIds = []
    // let imageIdsData = await surya_commondb().imageids_queue.toArray()
    // for (let obj of imageIdsData) {
    //     if (obj["id"] === `internal_${PROJECT_ID}`) {
    //         internalImageIds = obj["data"]
    //     }
    //     if (obj["id"] === `external_${PROJECT_ID}`) {
    //         publicImageIds = obj["data"]
    //     }
    // }


}
export async function insert_qc_custom_response(qcCustomResponse) {
    for (let i in qcCustomResponse) {
        if(Object.keys(qcCustomResponse[i]?.custom_response_external_pier).length !== 0 && qcCustomResponse[i]?.custom_response_external_pier !== null){
        await suryadb().qc_custom_response_piers_external.put({ id: parseInt(i), data: qcCustomResponse[i]?.custom_response_external_pier })
        }
        if(Object.keys(qcCustomResponse[i]?.custom_response_internal_pier).length !== 0 && qcCustomResponse[i]?.custom_response_internal_pier !== null){
        await suryadb().qc_custom_response_piers_internal.put({ id: parseInt(i), data: qcCustomResponse[i]?.custom_response_internal_pier })
        }
        if(Object.keys(qcCustomResponse[i]?.custom_response_external_tt).length !== 0 && qcCustomResponse[i]?.custom_response_external_tt !== null){
        await suryadb().qc_custom_response_tt_external.put({ id: parseInt(i), data: qcCustomResponse[i]?.custom_response_external_tt })
        }
        if(Object.keys(qcCustomResponse[i]?.custom_response_internal_tt).length !== 0 && qcCustomResponse[i]?.custom_response_internal_tt !== null){
        await suryadb().qc_custom_response_tt_internal.put({ id: parseInt(i), data: qcCustomResponse[i]?.custom_response_internal_tt })
        }
        if(Object.keys(qcCustomResponse[i]?.custom_response_internal_tracker_row).length !== 0 && qcCustomResponse[i]?.custom_response_internal_tracker_row !== null){
        await suryadb().qc_custom_response_trackerrow_internal.put({ id: parseInt(i), data: qcCustomResponse[i]?.custom_response_internal_tracker_row })
        }
        if(Object.keys(qcCustomResponse[i]?.custom_response_external_tracker_row).length !== 0 && qcCustomResponse[i]?.custom_response_external_tracker_row !== null){
        await suryadb().qc_custom_response_trackerrow_external.put({ id: parseInt(i), data: qcCustomResponse[i]?.custom_response_external_tracker_row })
        }
    }

}

export async function insert_qc_progress_qc_punchlist(qcPierTTProgress) {
    for (let i in qcPierTTProgress) {
        if(Object.keys(qcPierTTProgress[i]?.qc_progress_internal_pier).length !== 0 && qcPierTTProgress[i]?.qc_progress_internal_pier !== null){
        await suryadb().qc_progress_internal_piers.put({ id: parseInt(i), data: qcPierTTProgress[i]?.qc_progress_internal_pier })
        }
        if(Object.keys(qcPierTTProgress[i]?.qc_progress_internal_tt).length !== 0 && qcPierTTProgress[i]?.qc_progress_internal_tt !== null){
        await suryadb().qc_progress_internal_torquetubes.put({ id: parseInt(i), data: qcPierTTProgress[i]?.qc_progress_internal_tt })
        }
        if(Object.keys(qcPierTTProgress[i]?.qc_progress_external_pier).length !== 0 && qcPierTTProgress[i]?.qc_progress_external_pier !== null){
        await suryadb().qc_progress_external_piers.put({ id: parseInt(i), data: qcPierTTProgress[i]?.qc_progress_external_pier })
        }
        if(Object.keys(qcPierTTProgress[i]?.qc_progress_external_tt).length !== 0 && qcPierTTProgress[i]?.qc_progress_external_tt !== null){
        await suryadb().qc_progress_external_torquetubes.put({ id: parseInt(i), data: qcPierTTProgress[i]?.qc_progress_external_tt })
        }
        if(Object.keys(qcPierTTProgress[i]?.qc_progress_internal_tracker_row).length !== 0 && qcPierTTProgress[i]?.qc_progress_internal_tracker_row !== null){
        await suryadb().qc_progress_trackerrow_internal.put({ id: parseInt(i), data: qcPierTTProgress[i]?.qc_progress_internal_tracker_row })
        }
        if(Object.keys(qcPierTTProgress[i]?.qc_progress_external_tracker_row).length !== 0 && qcPierTTProgress[i]?.qc_progress_external_tracker_row !== null){
        await suryadb().qc_progress_trackerrow_external.put({ id: parseInt(i), data: qcPierTTProgress[i]?.qc_progress_external_tracker_row })
        }
        
        await insert_qc_punchlist(i, qcPierTTProgress[i]?.qcpunchlist_internal, qcPierTTProgress[i]?.qcpunchlist_external)
    }
}
async function get_qc_punchlist_block_or_project_wise(visibility, blockId) {
    let data = {}
    if (parseInt(visibility) === 1 && blockId !== undefined) {
        let externalPunchlistData = await suryadb().external_punchlist.get(blockId)
        if (externalPunchlistData !== undefined) {
            data = externalPunchlistData?.data
        }
    }
    if (parseInt(visibility) === 0 && blockId !== undefined) {
        let internalPunchlistData = await suryadb().internal_punchlist.get(blockId)
        if (internalPunchlistData !== undefined) {
            data = internalPunchlistData?.data
        }

    }
    if (parseInt(visibility) === 1 && blockId === undefined) {
        let externalPunchlistData = await suryadb().external_punchlist.toArray()
        for (let i of externalPunchlistData) {
            data = { ...data, ...i.data }
        }
    }

    if (parseInt(visibility) === 0 && blockId === undefined) {
        let internalPunchlistData = await suryadb().internal_punchlist.toArray()
        for (let i of internalPunchlistData) {
            data = { ...data, ...i.data }
        }
    }
    return data
}

export const get_punchlist_key = (data) => {

    let pierOrTorqueTubeId 
    let key = ""

    if(data?.pier_id || data?.tt_id){
        if (data?.tt_id !== undefined) {
             pierOrTorqueTubeId  = data?.tt_id
        }else{
             pierOrTorqueTubeId  = data?.pier_id
        }
    }
    if (data?.pier_id !== undefined || data?.tt_id !== undefined) {
        key = `${data?.tracker_row_id}_${pierOrTorqueTubeId}_${data?.checkpoint_id}`
    } else {
        key = `${data?.tracker_row_id}_${data?.checkpoint_id}`
    }
    return key
}

export async function get_qc_punchlist_counts(filteredConditions) {
    let data = await get_qc_punchlist(filteredConditions)
    let statusWiseCounts = []
    let statusWiseFilterConditions = [
        { punchlist_status: 0 },
        { punchlist_status: 1 },
        { punchlist_status: 2 }
    ]
    for (let i of statusWiseFilterConditions) {
        const filteredData = Object.values(data).filter(item =>
            Object.keys(i)
                .every(key => item[key] === i[key]));


        statusWiseCounts.push({ punchlist_status: i?.punchlist_status, counts: filteredData.length })
    }

    return statusWiseCounts

}

function convertDateToTimeZone(epochMillis, timeZone) {
    let date = new Date(epochMillis);
    if (timeZone) {
        let formatter = new Intl.DateTimeFormat('en-US', {
            timeZone: timeZone,
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short'
        });
        let formattedDate = formatter.format(date)

        formattedDate = handleTimeFormater(formattedDate)
        return formattedDate;


    }
}
const handleTimeFormater = (data) => {
    if (data) {
        let formattedValue = ""
        // let temp = data?.split(",")[1]
        let date = data?.split(",")[0]
        let dateFormat = `${date.split("/")[1]}/${date.split("/")[0]}/${date.split("/")[2]}`
        let time = data?.split(",")[1]
        formattedValue = dateFormat
        return formattedValue
    }
}
const areDatesEqual = (date1, date2) => {
    return date1 === date2;
};

export async function get_qc_punchlist(filterConditions) {

    let data = await get_qc_punchlist_block_or_project_wise(filterConditions?.visibility, filterConditions?.block_id)
    let filteredKeyValueData = {}

    const filteredData = Object.values(data).filter(item =>
        Object.keys(filterConditions)
            .every(key => checkIsSame(item, key)));

    function checkIsSame(item, key) {
        let isMatched = false
        if (key === "created_at") {
            let convertedDateToTimeZone = convertDateToTimeZone(item[key] * 1000, filterConditions[key]["timeZone"])
            let isDateEqual = areDatesEqual(convertedDateToTimeZone, filterConditions[key]["date"])
            if (isDateEqual) {
                isMatched = true
            }

        } else {
            if (item[key] === filterConditions[key]) {
                isMatched = true

            }
        }
        return isMatched
    }

    if (filteredData.length > 0) {
        for (let i of filteredData) {
            let key = get_punchlist_key(i)
            filteredKeyValueData[key] = i
        }
    }

    return filteredKeyValueData

}

export async function get_blockId(blockName) {
    let blockDetails = await suryadb().get_blocks.toArray()
    let blockId = "All"
    for (let i of blockDetails) {
        if (i?.data?.name === blockName) {
            blockId = i?.data?.id
        }
    }
    return blockId

}


export async function delete_qc_punchlist(visibility, punchlistKeys, status, qc_type) {
    let isDelete = true

    for (let i in punchlistKeys) {
        let qc_punchlist_data = await get_qc_punchlist_block_or_project_wise(visibility, parseInt(i))
        for (let key of punchlistKeys[parseInt(i)]) {
            if (qc_punchlist_data[key]) {
                await update_qc_queue(qc_punchlist_data[key], isDelete, status, qc_type)
                await update_qc_progress(qc_punchlist_data[key], status)
                delete qc_punchlist_data[key]
                if (parseInt(visibility) === 1) {
                    await suryadb().external_punchlist.put({ id: parseInt(i), data: qc_punchlist_data })
                }

                if (parseInt(visibility) === 0) {
                    await suryadb().internal_punchlist.put({ id: parseInt(i), data: qc_punchlist_data })
                }
            }
        }

    }

}

// export async function update_quality_control(updateSyncStatus) {
//     const pids = [];
//     try {
//         // Fetch data from IndexedDB
//         let dqc = await suryadb().update_qc.toArray();

//         // Group the data by block_id, tracker_row_id, type, and visibility (ignoring checkpoint_id)
//         let groupedQCData = groupByConditions(dqc);

//         let lengthofQcData = Object.keys(groupedQCData).length

//         localStorage.setItem("qc_items", lengthofQcData)

//         // Handle the 'updated-blocks' from localStorage
//         let updatedBlocks = JSON.parse(localStorage.getItem("updated-blocks")) || {};

//         // Loop over grouped data
//         for (let groupKey in groupedQCData) {
//             let dataArr = [];
//             let block_id = groupKey.split('_')[0];  // Extract block_id from groupKey

//             for (let item of groupedQCData[groupKey]) {
//                 updatedBlocks[item.block_id] = new Date().getTime(); // Update timestamp

//                 for (let j of item.data) {
//                     let uploaded_img_ids = [];
//                     let temp = j?.images;

//                     if (temp && Object.keys(temp).length > 0) {
//                         uploaded_img_ids = await upload_add_images(item?.block_id, j?.visibility, temp,updateSyncStatus);
//                     }

//                     if (j?.type === "qc") {
//                         let arr = {
//                             tracker_row_id: j?.tracker_row_id,
//                             checkpoint_id: j?.checkpoint_id,  // Still needed in the payload, but not used for grouping
//                             comment: j?.comments,
//                             status: j?.status,
//                             document: uploaded_img_ids,
//                             visibility: j?.visibility,
//                             punchlist_status: j?.punchlist_status,
//                             type: j?.type,
//                             created_at: Date.now() / 1000,
//                         };

//                         if (j?.punchlist_delete) {
//                             arr.punchlist_delete = j?.punchlist_delete;
//                         }
//                         if (j?.pier_id !== undefined) {
//                             arr.pier_id = j?.pier_id;
//                         } else {
//                             arr.tt_id = j?.tt_id;
//                         }

//                         dataArr.push(arr);
//                     } else {
//                         dataArr.push(j);
//                     }
//                 }
//             }

//             // Post the grouped data for this block_id
//             let resQC = await axios.post(`/projects/pier_or_tt_qc_progress?block_id=${block_id}`, dataArr);
//             pids.push(resQC?.data?.pid);

//             await suryadb().sync_request_details.put({
//                 id: resQC?.data?.pid,
//                 data: { updatedTime: new Date().toLocaleString() }
//             });
//         }

//         // Clear the QC update table after successful sync
//          await suryadb().update_qc.clear();
//         localStorage.setItem("updated-blocks", JSON.stringify(updatedBlocks));

//     } catch (err) {
//         console.log(err);
//     }

//     return pids;
// }
// Helper function to group by block_id, tracker_row_id, type, and visibility (no checkpoint_id)

function groupByConditions(data) {
    let groupedData = {};

    for (let item of data) {
        // Using block_id, tracker_row_id, type, and visibility for grouping (without checkpoint_id)
        const idParts = item.id.split('_');
        let block_id = idParts[0];
        let tracker_row_id = idParts[1];
        // let type = idParts.length === 6 ? idParts[2] : idParts[3];
        let visibility = idParts.length === 6 ? idParts[4] : idParts[3];

        // Create a unique group key based on the four fields
        let groupKey = `${block_id}_${tracker_row_id}_${visibility}`;

        if (!groupedData[groupKey]) {
            groupedData[groupKey] = [];
        }

        groupedData[groupKey].push(item);
    }

    return groupedData;
}

const countUgKeys = async (data) => {
    // Use Object.keys to get an array of keys, filter with startsWith, then count
    return Object.keys(data).filter(key => key.startsWith("ug")).length;
};

async function countTotalImages(groupedQCData) {
    let totalImages = 0;
    for (let groupKey in groupedQCData) {
        for (let item of groupedQCData[groupKey]) {
            for (let j of item.data) {
                let temp = j?.images;
                if (temp && Object.keys(temp).length > 0) {
                    let countOfImagesToBeUploaded = await countUgKeys(temp)
                    totalImages += countOfImagesToBeUploaded
                }
            }
        }
    }
    return totalImages;
}


async function uploadAllImages(groupedQCData, updateSyncStatus, totalImages) {
    let processedImages = 0;
    let processedData = {};

    for (let groupKey in groupedQCData) {
        processedData[groupKey] = [];

        for (let item of groupedQCData[groupKey]) {
            let processedItem = { ...item, data: [] };

            for (let j of item.data) {
                let uploaded_img_ids = [];
                let temp = j?.images;
                let countOfImagesToBeUploaded = {}


                if (temp && Object.keys(temp).length > 0) {
                    countOfImagesToBeUploaded = await countUgKeys(temp)
                    uploaded_img_ids = await upload_add_images(
                        item?.block_id,
                        j?.visibility,
                        temp,
                    );
                    processedImages += countOfImagesToBeUploaded;

                    // Update progress
                    updateSyncStatus({
                        totalUploadImagesCount: totalImages,
                        uploadedImagesCount: processedImages
                    });
                }

                // Create processed data entry
                let processedEntry = j?.type === "qc" ? {
                    tracker_row_id: j?.tracker_row_id,
                    checkpoint_id: j?.checkpoint_id,
                    comment: j?.comments,
                    status: j?.status,
                    document: uploaded_img_ids,
                    visibility: j?.visibility,
                    punchlist_status: j?.punchlist_status,
                    type: j?.type,
                    created_at: Date.now() / 1000,
                    ...(j?.punchlist_delete && { punchlist_delete: j?.punchlist_delete }),
                    ...(j?.pier_id !== undefined ? { pier_id: j?.pier_id } : { tt_id: j?.tt_id })
                } : j;

                processedItem.data.push(processedEntry);
            }

            processedData[groupKey].push(processedItem);
        }
    }

    return processedData;
}

async function makeAPICallsWithProcessedData(processedData) {
    const pids = [];
    const updatedBlocks = JSON.parse(localStorage.getItem("updated-blocks")) || {};
    let currentQcItemsCount = 0;

    for (let groupKey in processedData) {
        const block_id = groupKey.split('_')[0];
        let dataArr = [];



        for (let item of processedData[groupKey]) {
            updatedBlocks[item.block_id] = new Date().getTime();
            dataArr.push(...item.data);
        }

        // Make API call for this group
        try {
            let resQC = await axios.post(
                `/projects/pier_or_tt_qc_progress?block_id=${block_id}`,
                dataArr
            );
            currentQcItemsCount += 1;
            localStorage.setItem("qc_items", currentQcItemsCount);

            pids.push(resQC?.data?.pid);

            // await suryadb().sync_request_details.put({
            //     id: resQC?.data?.pid,
            //     data: { updatedTime: new Date().toLocaleString() }
            // });
            await suryadb().sync_request_details.put({
                id: resQC?.data?.pid,
                data: {
                    updatedTime: new Date().toLocaleString(),
                    status: 0,
                    completedTime: 0 // Initial status
                }
            });
        } catch (error) {
            console.error('API call failed for block_id:', block_id, error);

            // Extract error message from the response
            const errorMessage = error.response?.data?.message || error.response?.data || error.message || "Unknown error";

            // Store failed request in IndexedDB

            await suryadb().sync_request_details.put({
                id: groupKey,
                data: {
                    qc: dataArr,
                    updatedTime: new Date().toLocaleString(),
                    status: 2,
                    completedTime: 0,
                    type: "qc" // Initial status for failed requests
                }
            });

            // Display toast message with block ID and error message
            toast.error(`API call failed for block ID: ${block_id}. Error: ${errorMessage}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    localStorage.setItem("updated-blocks", JSON.stringify(updatedBlocks));
    return pids;
}

export async function update_quality_control(updateSyncStatus) {
    try {
        // Get data from IndexedDB and group it
        let dqc = await suryadb().update_qc.toArray();
        let groupedQCData = groupByConditions(dqc);

        // Count total images
        const totalImages = await countTotalImages(groupedQCData);
        if (totalImages > 0) {
            updateSyncStatus({
                totalUploadImagesCount: totalImages,
                uploadedImagesCount: 0
            });
        }

        // Upload all images and get processed data
        const processedData = await uploadAllImages(
            groupedQCData,
            updateSyncStatus,
            totalImages
        );

        // Make API calls with processed data
        const pids = await makeAPICallsWithProcessedData(processedData);

        // Clear QC update table after successful sync
        await suryadb().update_qc.clear();

        return pids;
    } catch (error) {
        console.error('Error in update_quality_control:', error);
        throw error;
    }
}

// export async function get_label_name(data) {
//     let label = ""
//     if (data) {
//         let bulkPiersTTData = await get_bulk_piers_torquetubes(data?.block_id)
//         console.log(bulkPiersTTData)
//         let currentPierTTData = bulkPiersTTData[data?.tracker_row_id]

//         console.log(currentPierTTData)
//         if (data?.scope === "piers") {
//             let pierData = currentPierTTData?.piers.find((pier) => pier?.id === data?.pier_id);
//             console.log(pierData)
//             if (pierData && pierData?.pier_part_label) {
//                 console.log(pierData?.pier_part_label)
//                 label = pierData?.pier_part_label
//             }
//         }
//         else {
//             let ttData = currentPierTTData?.torque_tube.find((tt) => tt?.id === data?.tt_id);
//             console.log(ttData)
//             if (ttData && ttData.tt_part_label) {
//                 let ttPartLabelNumberOnly = ttData?.tt_part_label.replace(/\D/g, '');
//                 label = ttPartLabelNumberOnly
//             }
//         }

        
        
//     }
//     console.log(label)
//     return label
// }

