import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { getData, updateCpData, updateQcData, cancelGetData, resetCancellation } from "../utils/dataUtil";
import syncStatusAtom from "../data/syncStatusAtom";
import { useAtom } from "jotai";
import PropTypes from "prop-types";
import { updateBlockUpdateValue, updateSyncImages } from "../data/appBarAtom";
import pusher from "./pusher"
import suryadb from "../indexedDB/module";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import authAtom from "../data/authAtom";
import { IS_DEBUG_MODE_ON } from "../featureFlags/featureFlags";
import { IoMdPower } from "react-icons/io";
import Cookies from "js-cookie";
import ProjectProvider from "./project";
import { toast, ToastContainer } from "react-toastify";

function CircularProgressWithLabel(props) {

  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          sx={{ fontSize: "16px" }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

function DefaultDataProvider(props) {
  const IS_PUSHER_ENABLED = true

  const [loading, setLoading] = useState(false);
  const [user] = useAtom(authAtom)
  const [syncStatusData] = useAtom(syncStatusAtom);
  const [, updateSync] = useAtom(syncStatusAtom);
  const [progress, setProgress] = useState(0);
  const [, blockUpdated] = useAtom(updateBlockUpdateValue)
  const [serverSyncProgress, setServerSyncProgress] = useState(0)
  const [isSendingRequestCompleted, setIsSendingRequestCompleted] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [isGoBackClicked, setIsGoBackClicked] = useState(false)
  const [showProjectSelection, setShowProjectSelection] = useState(false);
  const [showHomePage, setShowHomePage] = useState(false)


  const [totalPids, setTotalPids] = useState(0); // Total number of PIDs
  const [processedPids, setProcessedPids] = useState(0); // Number of PIDs processed
  const [startTime, setStartTime] = useState(0)
  const [endTime1, setEndTime1] = useState(0)
  const [endTime2, setEndTime2] = useState(0)
  const [endTime3, setEndTime3] = useState(0)

  const totalQcItemsRef = useRef(0);
  const pidQueue = useRef([]);
  const processingQueue = useRef(false);
  const processedPidsRef = useRef(0);
  const totalPidsRef = useRef(0);
  const hasNavigatedRef = useRef(false);


  const getSyncTimeTaken = async (step) => {
    let currentStep = step
    let totalTimeMs = 0
    let convertTimeToSeconds = 0
    if (currentStep === 1) {
      totalTimeMs = endTime1 - startTime
    } else if (currentStep === 2) {
      totalTimeMs = endTime2 - endTime1
    } else if (currentStep === 3) {
      totalTimeMs = endTime3 - endTime2
    } else {
      totalTimeMs = endTime3 - startTime
    }
    convertTimeToSeconds = `${(totalTimeMs / 1000)}s`
    console.log(`Step ${step} - ${convertTimeToSeconds}`)

  }

  useEffect(() => {
    let getDataProgress = 0
    if (syncStatusData.value !== 0) {
      getDataProgress = Math.round((syncStatusData.value * 100) / 29)
    }
    if (isNaN(getDataProgress)) {
      getDataProgress = 0
    }
    setProgress(getDataProgress);
  }, [syncStatusData.value]);


  useEffect(() => {
    // Check if this is a post-project-selection reload
    const isPostProjectSelection = localStorage.getItem("project_id")

    if (syncStatusData.syncing === true &&
      (!syncStatusData.initialLoadComplete || isPostProjectSelection)) {
      totalQcItemsRef.current = 0;
      init();
    }
  }, [syncStatusData.syncing, syncStatusData.initialLoadComplete]);


  const updateTotalPids = (pids) => {
    setTotalPids(pids);
    totalPidsRef.current = pids;
    processedPidsRef.current = 0; // Reset processed count
    // setServerSyncProgress(0); // Reset progress
    localStorage.setItem("qc_items", pids.toString());
  };

  // Update the PID completion function
  const updatePidCompleted = async (pid) => {
    try {
      const pidExists = await suryadb().sync_request_details.get(pid);
      if (pidExists) {
        await suryadb().sync_request_details.put({
          id: pid,
          data: {
            ...pidExists.data,
            status: 1,
            completedTime: new Date().toLocaleString()
          }
        });

        totalQcItemsRef.current += 1;
        processedPidsRef.current += 1;
        setProcessedPids(processedPidsRef.current);

        // Directly update progress here
        if (totalPidsRef.current > 0) {
          const newProgress = Math.round((processedPidsRef.current * 100) / totalPidsRef.current);
          setServerSyncProgress(newProgress);
        }

        return true;
      }
      return false;
    } catch (error) {
      console.error('Failed to update PID status in IndexedDB:', error);
      return false;
    }
  };
  // Update the queue processing function
  const processQueue = async () => {
    if (processingQueue.current) return;
    processingQueue.current = true;

    try {
      while (pidQueue.current.length > 0) {
        const pid = pidQueue.current.shift();
        const wasUpdated = await updatePidCompleted(pid);

        // Calculate whether we've processed all PIDs
        const allPidsProcessed = processedPidsRef.current === totalPidsRef.current;

        // If all PIDs are processed, proceed with getData
        if (wasUpdated && allPidsProcessed) {

          // Ensure 100% progress is shown
          setServerSyncProgress(100);

          // Small delay to ensure UI shows 100%
          await new Promise(resolve => setTimeout(resolve, 100));

          setEndTime2(Date.now());
          await getSyncTimeTaken(2);
          await getData(updateSync);
          setEndTime3(Date.now());
          await getSyncTimeTaken(3);
          await getSyncTimeTaken(4);

          localStorage.setItem('last-sync', Date.now());
          localStorage.removeItem('updated-blocks');
          localStorage.removeItem("qc_items");

          if (IS_DEBUG_MODE_ON === false) {
            await suryadb().sync_request_details.clear();
          }

          await blockUpdated({ value: false });
          setLoading(false);

          // Reset all counters
          totalQcItemsRef.current = 0;
          processedPidsRef.current = 0;
          setProcessedPids(0);
          setTotalPids(0);
          setIsSendingRequestCompleted(false);
          setProgress(0);
          setServerSyncProgress(0);
          break;
        }
      }
    } finally {
      processingQueue.current = false;
    }
  };

  useEffect(() => {
    if (IS_PUSHER_ENABLED) {
      const channel = pusher.subscribe("sync")
      channel.bind("sync_completed", async function (data) {
        console.log(data["pid"])
        pidQueue.current.push(data["pid"]);
        if (IS_DEBUG_MODE_ON) {
          toast.success(data["pid"])
        }
        setTimeout(processQueue, 0);

      });

      return () => {
        // Unsubscribe when the component is unmounted
        channel.unbind();
        pusher.unsubscribe("sync");
      };
    }

  }, []);

  const handleLogout = () => {
    localStorage.clear()
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    indexedDB.deleteDatabase("suryadb");
    window.location.href = "/login";
  };

  const init = async () => {
    resetCancellation();
    setStartTime(Date.now())
    try {
      if (navigator.onLine && user?.loggedIn) {
        setLoading(true);
        if (syncStatusData?.block_name === "All" && syncStatusData?.sync_mode === "Both") {
          localStorage.setItem("updating_getting_data", false);
          await getData(updateSync);
        } else if (syncStatusData?.sync_mode === "construction") {
          const cppids = await updateCpData();
          updateTotalPids(cppids.length);
          // setTotalPids(cppids.length); // Set total PIDs
          await blockUpdated({ value: true });
          setIsSendingRequestCompleted(true);
        } else if (syncStatusData?.sync_mode === "qc") {
          const qcpids = await updateQcData(updateSync);
          updateTotalPids(qcpids.length);
          // setTotalPids(qcpids.length); // Set total PIDs
          await blockUpdated({ value: true });
          setIsSendingRequestCompleted(true);
        } else if (syncStatusData?.sync_mode === "updateBoth") {
          const cppids = await updateCpData();
          updateTotalPids(cppids.length);
          // setTotalPids(cppids.length); // Set total PIDs
          const qcpids = await updateQcData(updateSync);
          // setTotalPids((prevTotal) => prevTotal + qcpids.length); // Update total PIDs
          updateTotalPids((prevTotal) => {
            const newTotal = prevTotal + qcpids.length;
            totalPidsRef.current = newTotal; // Update ref
            return newTotal;
          });
          await blockUpdated({ value: true });
          setIsSendingRequestCompleted(true);
        }
        setEndTime1(Date.now())
        await getSyncTimeTaken(1)
        updateSync({
          syncing: false,
          status: "Sync Completed",
          value: 0,
          block_name: "All",
          sync_mode: "Both",
          block_id: 0,
          is_background: false,
          sync_text: "Syncing",
          syncInitiated: true,
          totalImages: 0,
          downloadedImages: 0,
          totalUploadImagesCount: 0,
          uploadedImagesCount: 0,
          initialLoadComplete: true
        });
      }

    } catch (err) {
      setErrorMessage(err)
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleRetry = async () => {
    setErrorMessage("")
    init();
  }


  useEffect(() => {
    if (isGoBackClicked) {
      const timer = setTimeout(() => {
        setIsGoBackClicked(false); 
      }, 3000); 

      // Clean up the timeout
      return () => clearTimeout(timer);
    }
  }, [isGoBackClicked]);


  const handleGoBackRetry = async () => {
    setIsGoBackClicked(true)
    await blockUpdated({ value: false });
    setLoading(false);
    setIsSendingRequestCompleted(false);
    setProgress(0);
    totalQcItemsRef.current = 0
    setTotalPids(0)
    setProcessedPids(0)
    setServerSyncProgress(0);
    localStorage.setItem("updating_getting_data", false)
    localStorage.removeItem("qc-items")
    // await suryadb().sync_request_details.clear()
  }

  const handleGoBack = async () => {
    cancelGetData();
    setLoading(false)
    updateSync({
      syncing: false, // Set to false to stop sync
      status: "Sync Cancelled",
      value: 0,
      block_name: "All",
      sync_mode: "Both",
      block_id: 0,
      is_background: false,
      sync_text: "Sync Cancelled",
      syncInitiated: false,
      totalImages: 0,
      downloadedImages: 0,
      totalUploadImagesCount: 0,
      uploadedImagesCount: 0,
      initialLoadComplete: true
    });
    localStorage.removeItem("project_id")
    localStorage.setItem("updating_getting_data", false)

    // Show project selection
    setShowProjectSelection(true);

  }
  const handleGoHome = async () => {
    if (JSON.parse(localStorage.getItem("get-data-initially")) === true) {
      cancelGetData();
      setLoading(false)
      updateSync({
        syncing: false, // Set to false to stop sync
        status: "Sync Cancelled",
        value: 0,
        block_name: "All",
        sync_mode: "Both",
        block_id: 0,
        is_background: false,
        sync_text: "Sync Cancelled",
        syncInitiated: false,
        totalImages: 0,
        downloadedImages: 0,
        initialLoadComplete: true
      });
      hasNavigatedRef.current = true;
      setShowHomePage(true)
    }
  }

  useEffect(() => {
    if (showHomePage && props.children && hasNavigatedRef.current) {
      // Small delay to ensure children are rendered
      const timer = setTimeout(() => {
        setShowHomePage(false);
        hasNavigatedRef.current = false;
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [showHomePage, props.children]);

  if (showProjectSelection) {
    return (
      <Box className="w-screen h-screen">
        <ProjectProvider />
      </Box>
    );
  }

  if (showHomePage) {
    return <>{props.children}</>;
  }

  if (loading && syncStatusData?.is_background === false && JSON.parse(localStorage.getItem("updating_getting_data")) === false && !showHomePage) {
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100vw",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >

          {syncStatusData.value && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100vw",
                height: "100vh",
                alignItems: "center",
                justifyContent: "center",
              }}
            >

              <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                {syncStatusData.status}
              </Typography>
              <CircularProgressWithLabel value={progress} size={60} />
              {(syncStatusData?.totalImages && syncStatusData?.totalImages !== 0) ?
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingY: "10px" }}>
              <Typography variant="h6">Images to be downloaded</Typography>
              <Typography variant="h6" sx ={{paddingLeft:"10px"}} >{`${syncStatusData?.downloadedImages}/${syncStatusData?.totalImages}`}</Typography>
            </Box>
            : null}

              <Box sx={{ paddingY: "10px" }}>
                {JSON.parse(localStorage.getItem("get-data-initially")) === true ?
                  < Button onClick={handleGoHome} variant="contained" sx={{ textTransform: "none" }}>
                    Go Home
                  </Button>

                  :
                  <Button onClick={handleGoBack} variant="contained" sx={{ textTransform: "none" }}>
                    Go Back
                  </Button>
                }
              </Box>
            </Box>
          )}

        </Box>
      </Box >
    );
  }
  if (IS_DEBUG_MODE_ON && errorMessage !== "") {
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100vw",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
            position: "relative"
          }}
        >

          {syncStatusData.value && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100vw",
                height: "100vh",
                alignItems: "center",
                justifyContent: "center",

              }}
            >

              <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                Oops! Something went wrong Please try again
              </Typography>
              <Button variant="contained" sx={{ textTransform: "none", marginY: "10px", borderRadius: "2px" }} onClick={handleRetry}>Retry</Button>
              <Box sx={{
                position: "absolute",
                bottom: 20,
                right: 20,
                cursor: "pointer"
              }}>
                <IoMdPower fontSize={40} onClick={handleLogout} />
              </Box>
            </Box>
          )}
        </Box>
      </Box >
    );
  }
  if (isGoBackClicked === true) {
    return (
      <>{props.children}</>
    )
  }
  
  if (JSON.parse(localStorage.getItem("updating_getting_data")) === true) {
    return (
      <Box sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <Box sx={{ heigth: "600px", width: "450px", }}>
          {syncStatusData.totalUploadImagesCount !== 0 ?
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingY: "10px" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Typography variant="h6">Uploading Images</Typography>
                <Typography variant="h6" >{`${syncStatusData?.uploadedImagesCount}/${syncStatusData?.totalUploadImagesCount}`}</Typography>
              </Box>
              <CircularProgressWithLabel
                value={Math.round((syncStatusData.uploadedImagesCount / syncStatusData.totalUploadImagesCount) * 100)}
                size={40}
              />
            </Box>
            : null}
          {/* {syncStatusData.totalUploadImagesCount !== 0 && syncStatusData.uploadedImagesCount !== syncStatusData.totalUploadImagesCount ?
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingY: "10px" }}>
              <Typography variant="h6" sx={{ fontSize: "22px" }}>Submitting sync request</Typography>
              <CircularProgressWithLabel value={0} size={40} />
            </Box>
            :
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingY: "10px" }}>
              <Typography variant="h6" sx={{ fontSize: "22px" }}>Submitting sync request</Typography>
              {isSendingRequestCompleted ?
                <div className="icon-container">
                  <CheckCircleOutlineOutlinedIcon color="success" fontSize="large" className="icon" />
                </div>
                :
                <CircularProgress size={40} />
              }
            </Box>
          } */}

          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingY: "10px" }}>
            <Typography variant="h6" sx={{ fontSize: "22px" }}>Submitting sync request</Typography>
            {syncStatusData.totalUploadImagesCount !== 0 && syncStatusData.uploadedImagesCount !== syncStatusData.totalUploadImagesCount ? (
              <CircularProgressWithLabel value={0} size={40} />
            ) : isSendingRequestCompleted ? (
              <div className="icon-container">
                <CheckCircleOutlineOutlinedIcon color="success" fontSize="large" className="icon" />
              </div>
            ) : (
              <CircularProgress size={40} />
            )}
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingY: "10px" }}>
            <Typography variant="h6" sx={{ fontSize: "22px" }}>Processing sync request</Typography>
            {isSendingRequestCompleted && serverSyncProgress === 100 ?
              <div className="icon-container">
                <CheckCircleOutlineOutlinedIcon color="success" fontSize="large" className="icon"
                /></div> :
              <CircularProgressWithLabel value={serverSyncProgress} size={40} />}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingY: "10px" }}>
            <Typography variant="h6" sx={{ fontSize: "22px" }}>
              Getting updated data from server
            </Typography>
            {progress === 100 ? <div className="icon-container">
              <CheckCircleOutlineOutlinedIcon color="success" fontSize="large" className="icon"
              /></div> :
              <CircularProgressWithLabel value={progress} size={40} />}
          </Box>

          {(syncStatusData?.totalImages && syncStatusData?.totalImages !== 0) ?
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingY: "10px" }}>
              <Typography variant="h6">Images to be downloaded</Typography>
              <Typography variant="h6" >{`${syncStatusData?.downloadedImages}/${syncStatusData?.totalImages}`}</Typography>
            </Box>
            : null}

          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingY: "20px" }}>
            <Button variant="contained" onClick={handleGoBackRetry} sx={{ textTransform: "none" }} >Go back and retry</Button>
          </Box>

          <ToastContainer
            position="bottom-right"
            autoClose={2000}
            hideProgressBar={true}
            newestOnTop={true}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            style={{ width: "440px" }}
          />

        </Box>

      </Box >
    )
  }
  else {
    return (
      <>{props.children}</>
    )
  }
}

export default DefaultDataProvider;