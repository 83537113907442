import Header from '../../../components/appBar'
import selectedRowsAtom from '../../../data/selectedRowsAtom'
import { IS_PROJECT_TYPE_ZIMMERMAN_FLAG } from '../../../featureFlags/featureFlags'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from 'react-router-dom'
import { get_list_of_piers_torquetubes_ids_with_status_for_all_selected_trackers, get_updated_bom_for_distributed_installed_for_selected_activity_trackerow, getTrackerTypeDetails } from '../../../components/functions/constructionProgress'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import produce from "immer";
import lodash from "lodash";
import {
    TableHead,
    TableRow,
    TableCell,
    Table,
    TableBody,
    TableContainer,
    TextField,
    Box,
    Collapse,
    IconButton,
    CircularProgress,
    Typography,
    Paper,
    Button,
    Modal,
    Divider
} from "@mui/material";
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { ToastContainer, toast } from "react-toastify";
import dashboardAtom, { fetchBulkPiersTorqueTubesBlockDataAtom, fetchExistingDistrbutedInstalledAtom, fetchTrackerTypeNamesAtom, getListOfPiersTorqueTubeIdsWithStatusAtom, getSelectedPiersTorqueTubesDataAtom, updateDistributedAndInstalledQuantityAtom, updateExistingDistrbutedInstalledAtom, updateInprogressDeployedCountAtom, updateSubmitInprogressAtom } from "../../../data/dashboardAtom";
import { useAtom } from "jotai";
import { get_distributed_and_installed_bom_for_activity, updated_progress_quantity_for_all_selected_trackerrows } from "../../../components/functions/constructionProgress";
import SteppedModal from '../../../components/constructionInprogressSteppedModal';
import activitiesAtom, { fetchActivitiesAtom } from '../../../data/activitiesAtom';
import partsAtom, { fetchPartsAtom } from '../../../data/partsAtom';
import constructionProgressTrackerrowWiseAtom from '../../../data/constructionProgressAtom';
import { insertCPQueueAtom } from '../../../data/cpQueueAtom';
import UndoIcon from "@mui/icons-material/Undo";
import CustomNotification from '../../../components/shared/customNotification';


const Msg = () => (
    <Box sx={{ display: "flex" }}>
        <Box
            sx={{
                padding: "0px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                paddingRight: "40px",
            }}
        >
            <Typography variant="h6"> Applied Successfully</Typography>
            <Typography>To all the selected trackers</Typography>
        </Box>
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: " 15px ",
                color: "#F16253",
            }}
        >
            <UndoIcon sx={{ fontSize: "25px" }} />
            <Typography sx={{ fontWeight: "600", fontSize: "18px" }}>UNDO</Typography>
        </Box>
    </Box>
);

const ConstructionInprogress = () => {
    const [dashboard, setDashboard] = useAtom(dashboardAtom)
    const [, insertCPQueue] = useAtom(insertCPQueueAtom);
    const [selectedRows] = useAtom(selectedRowsAtom);
    const [activities] = useAtom(activitiesAtom)
    const [listParts] = useAtom(partsAtom);
    const [constructionProgressTrackerrowWise, setConstructionProgressTrackerrowWise] = useAtom(constructionProgressTrackerrowWiseAtom)
    const [, fetchActivities] = useAtom(fetchActivitiesAtom)
    const [, fetchBulkPiersTorqueTubesBlockData] = useAtom(fetchBulkPiersTorqueTubesBlockDataAtom)
    const [, fetchTrackerTypeNames] = useAtom(fetchTrackerTypeNamesAtom)
    const [, updateDistributedAndInstalledQuantity] = useAtom(updateDistributedAndInstalledQuantityAtom)
    const [, fetchExistingDistrbutedInstalled] = useAtom(fetchExistingDistrbutedInstalledAtom)
    const [, updateInprogressDeployedCount] = useAtom(updateInprogressDeployedCountAtom)
    const [, fetchParts] = useAtom(fetchPartsAtom);
    const [, updateExistingDistrbutedInstalled] = useAtom(updateExistingDistrbutedInstalledAtom);
    const [, getSelectedPiersTorqueTubesData] = useAtom(getSelectedPiersTorqueTubesDataAtom)
    const [, getListOfPiersTorqueTubeIdsWithStatus] = useAtom(getListOfPiersTorqueTubeIdsWithStatusAtom)

    const [currentTrackerTypeId, setCurrentTrackerTypeId] = useState(0)
    const [selectedActivity, setSelectedActivity] = useState(localStorage.getItem("construction-act-id"))
    const [selectedTrackerTypeId, setSelectedTrackerTypeId] = useState(null)
    const [openRows, setOpenRows] = useState({});
    const [bomDetailss, setBomDetailss] = useState([])
    const [updateQuantity, setUpdateQuantity] = useState({});
    const [innerTableLoading, setInnerTableLoading] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [piersTorqueTubesDetails, setPiersTorqueTubesDetails] = useState({})
    const [trackerRows, setTrackerRows] = useState([])
    const [openSteppedModal, setOpenSteppedModal] = useState(false);
    const [selectedCurrentRow, setSelectedCurrentRow] = useState(null);
    const [blockDetails, setBlockDetails] = useState({})
    const [isZimmerman, setIsZimmerman] = useState(false)
    const [inprogressSelectedTrackerDetails, setInprogressSelectedTrackerDetails] = useState([])
    const [inprogressTotalPartCount, setInprogressTotalPartCount] = useState(0)
    const [btnEnable, setBtnEnable] = useState(false)
    const [saveBtnEnable, setSaveBtnEnable] = useState(false)
    const [inprogressDeployedPartCount, setInprogressDeployedPartCount] = useState({});
    const [status, setStatus] = useState(1)
    const [isAnyTrackerCompleted, setIsAnyTrackerCompleted] = useState(false);
    const [isQtyExceeded, setIsQtyExceeded] = useState(false);
    const [inprogressPierTTStatusForAllSelectedTrackers, setInprogressPierTTStatusForAllSelectedTrackers] = useState({})

    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate()
    const params = useParams()
    const updatedAtomRef = useRef({});

    useEffect(() => {
        if (localStorage.getItem("construction-act-id") !== "All" && localStorage.getItem("construction-act-id") !== "None Selected") {
            let activityId = JSON.parse(localStorage.getItem("construction-act-id"))
            let tracker_type_id = parseInt(selectedCurrentRow?.id);
            let partId_trackertypeId_key = `${activities?.construction[activityId]?.part_id}_${tracker_type_id}`
            let totalPartCount = 0
            if (dashboard?.part_per_tracker[partId_trackertypeId_key]) {
                totalPartCount = dashboard?.part_per_tracker[partId_trackertypeId_key]
            }
            setInprogressTotalPartCount(parseInt(totalPartCount))
        }
    }, [activities, dashboard?.trackerTypeNames, selectedCurrentRow])

    useEffect(() => {
        async function fetchProjectTypeFlag() {
            let is_zimmeran = await IS_PROJECT_TYPE_ZIMMERMAN_FLAG()
            setIsZimmerman(is_zimmeran)
        }
        fetchProjectTypeFlag();
    }, [])

    const isRowCompleted = (row) => {
        let hasDeployedCount = false;
        if (dashboard?.inprogressDeployedCount && dashboard?.inprogressDeployedCount[row?.id]) {
            hasDeployedCount = dashboard?.inprogressDeployedCount[row?.id] !== 0;
        }

        let hasColorsEntered = false;
        if (dashboard?.listOfPiersTorqueTubeIdsWithStatus &&
            dashboard?.listOfPiersTorqueTubeIdsWithStatus[row?.id] &&
            dashboard?.listOfPiersTorqueTubeIdsWithStatus[row?.id]?.piers &&
            dashboard?.listOfPiersTorqueTubeIdsWithStatus[row?.id]?.torque_tubes) {

            const allPiersCompleted = dashboard?.listOfPiersTorqueTubeIdsWithStatus[row?.id].piers.some(pier => pier.status === 1);
            const allTorqueTubesCompleted = dashboard?.listOfPiersTorqueTubeIdsWithStatus[row?.id].torque_tubes.some(tt => tt.status === 1);
            hasColorsEntered = allPiersCompleted || allTorqueTubesCompleted;
        }

        return hasDeployedCount && hasColorsEntered;
    };

    useEffect(() => {
        setIsAnyTrackerCompleted(false);
    }, [inprogressSelectedTrackerDetails]);

    useEffect(() => {
        init()
        groupByTrackerType()
    }, [])

    useEffect(() => {
        if (dashboard?.listOfPiersTorqueTubeIdsWithStatus && dashboard?.listOfPiersTorqueTubeIdsWithStatus[selectedCurrentRow?.id]) {
            const { piers, torque_tubes } = dashboard?.listOfPiersTorqueTubeIdsWithStatus[selectedCurrentRow?.id]; // Assuming "3104" is the key you're working with

            // Check if any piers or torque_tubes have status 1
            const isPiersStatusOne = piers.some(pier => pier.status === 1);
            const isTorqueTubesStatusOne = torque_tubes.some(tube => tube.status === 1);
            // Enable button if any status is 1
            if (isPiersStatusOne || isTorqueTubesStatusOne) {
                setSaveBtnEnable(true)
            }
            else {
                setSaveBtnEnable(false)
            }
        }

    }, [dashboard?.listOfPiersTorqueTubeIdsWithStatus])

    const init = async () => {
        try {
            await fetchBulkPiersTorqueTubesBlockData(parseInt(params?.id))
            await fetchTrackerTypeNames()
            await fetchExistingDistrbutedInstalled()
            await fetchActivities()
            await fetchParts()
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getConstructionProgressOuterRowsDetails()
    }, [dashboard?.trackerTypeNames, selectedCurrentRow])

    const getConstructionProgressOuterRowsDetails = async () => {
        if (selectedRows.length > 0 && dashboard?.trackerTypeNames) {
            const result = await getTrackerTypeDetails(selectedRows, dashboard?.trackerTypeNames);
            setInprogressSelectedTrackerDetails(result)
        }
    }

    useEffect(() => {
        if (isZimmerman === true) {
            if (dashboard?.zimmermanBulkPiers && dashboard?.zimmermanBulkPiers.length !== 0) {
                let blockPierData = dashboard?.zimmermanBulkPiers
                if (blockPierData?.length !== 0) {
                    setBlockDetails(blockPierData[0])
                }
            }
        } else {
            if (dashboard?.bulkPiersTorqueTubesBlockData && dashboard?.bulkPiersTorqueTubesBlockData?.piers && Object.keys(blockDetails).length === 0 && selectedRows.length > 0) {
                let blockPierData = dashboard?.bulkPiersTorqueTubesBlockData?.piers
                let trackerRowId = parseInt(selectedRows[0]?.split("-")[1])
                if (blockPierData[trackerRowId] !== undefined && blockPierData[trackerRowId].length !== 0) {
                    setBlockDetails(blockPierData[trackerRowId][0])
                }
            }
        }
    }, [])

    const handleBackButton = async () => {
        navigate(-1)
    }

    useEffect(() => {
        if (dashboard?.trackerTypeNames && trackerRows) {
            let bulkPiersTTData = dashboard?.bulkPiersTorqueTubesBlockData
            // Set block details (you might need to adjust this based on your data structure)
            if (bulkPiersTTData && bulkPiersTTData?.piers) {
                const trackerRowId = parseInt(trackerRows[0]?.split("-")[1]);
                if (bulkPiersTTData?.piers[trackerRowId] && bulkPiersTTData?.piers[trackerRowId].length > 0) {
                    setBlockDetails(bulkPiersTTData?.piers[trackerRowId][0]);
                }
            }
        }
    }, [trackerRows, dashboard?.trackerTypeNames, dashboard?.bulkPiersTorqueTubesBlockData]);

    const handleOpenSteppedModal = useCallback((row) => async () => {
        setSelectedCurrentRow(row);
        setSelectedTrackerTypeId(row?.id);
        setInnerTableLoading(prev => ({ ...prev, [row.id]: true }));

        let groupData = groupByTrackerType();
        let currentSelectedTrackerTypes = groupData[row.id];
        let bomData = await get_distributed_and_installed_bom_for_activity(
            selectedActivity,
            currentSelectedTrackerTypes[0],
            dashboard?.distributedInstalledBom,
            dashboard?.total_installed_distributed,
            dashboard?.existing_bom_per_tracker_row,
            isZimmerman,
            dashboard?.zimmermanTableWisePierPartBom
        );

        setBomDetailss(prev => ({ ...prev, [row.id]: bomData }));
        setInnerTableLoading(prev => ({ ...prev, [row.id]: false }));
        setTrackerRows(currentSelectedTrackerTypes);

        // Load the stored inprogressCount
        const storedCount = dashboard.inprogressDeployedCount[row.id] || 0;
        setInprogressDeployedPartCount(prev => ({
            ...prev,
            [row.id]: storedCount
        }));

        // Set initial button state
        const isValid = storedCount > 0 && storedCount <= inprogressTotalPartCount;
        setBtnEnable(isValid);

        setOpenSteppedModal(true);
    }, [groupByTrackerType, selectedActivity, dashboard, isZimmerman, get_distributed_and_installed_bom_for_activity, inprogressTotalPartCount]);
    const handleCloseSteppedModal = useCallback(() => {
        setOpenSteppedModal(false);
        setSelectedCurrentRow(null);
        // Don't reset inprogressDeployedPartCount here
    }, []);



    const handleCloseWithoutSaving = useCallback((trackerTypeId) => {

        setShowError(false)
        setErrorMessage("")
        if (trackerTypeId) {

            // Update inprogressDeployedCount

            setDashboard(produce((draft) => {
                let existingInprogressDeployedCountData = { ...draft.inprogressDeployedCount }
                delete existingInprogressDeployedCountData[trackerTypeId]
                draft.inprogressDeployedCount = existingInprogressDeployedCountData
            }))

            // Update listOfPiersTorqueTubeIdsWithStatus

            setDashboard(produce((draft) => {
                let existingPiersTorqueTubeIdsWithStatusData = { ...draft.listOfPiersTorqueTubeIdsWithStatus }
                delete existingPiersTorqueTubeIdsWithStatusData[trackerTypeId]
                draft.listOfPiersTorqueTubeIdsWithStatus = existingPiersTorqueTubeIdsWithStatusData
            }))

            //Update DistributedInstalledQuantity


            setDashboard(produce((draft) => {
                let existingDistributedInstalledQuantityData = { ...draft.updateDistributedAndInstalledQuantity }

                // Get all tracker rows associated with this tracker type
                const associatedTrackerRows = selectedRows.filter(row => row.split('-')[2] === trackerTypeId.toString())
                    .map(row => row.split('-')[1]);

                // Remove entries for all associated tracker rows
                Object.keys(existingDistributedInstalledQuantityData).forEach(key => {
                    const [bomId, trackerRowId] = key.split('_');
                    if (associatedTrackerRows.includes(trackerRowId)) {
                        delete existingDistributedInstalledQuantityData[key];
                    }
                });

                draft.updateDistributedAndInstalledQuantity = existingDistributedInstalledQuantityData
            }))

            // Update local states
            setInprogressDeployedPartCount((prev) => {
                const updated = { ...prev };
                delete updated[trackerTypeId];
                return updated;
            });

            setUpdateQuantity((prev) => {
                const updated = { ...prev };
                delete updated[trackerTypeId];
                return updated;
            });
        }
    }, [updateInprogressDeployedCount, getListOfPiersTorqueTubeIdsWithStatus, updateDistributedAndInstalledQuantity]);

    const getDeployedPartCountOfSelectedTrackerrow = () => {
        let deployedCount = 0
        if (selectedRows?.length === 1 && localStorage.getItem("construction-act-id") !== "None Selected") {
            let trackerrowId_activityId_key = `${selectedRows[0].split("-")[1]}_${JSON.parse(localStorage.getItem("construction-act-id"))}`
            if (constructionProgressTrackerrowWise[trackerrowId_activityId_key]) {
                deployedCount = constructionProgressTrackerrowWise[trackerrowId_activityId_key]["deployed_part_count"]
            }
        }
        return deployedCount
    }

    const getInprogressDefaultValue = () => {
        let deployedCount = 0
        if (Object.keys(dashboard?.inprogressDeployedCount).length > 0 && dashboard?.inprogressDeployedCount[selectedCurrentRow?.id]) {
            deployedCount = dashboard?.inprogressDeployedCount[selectedCurrentRow?.id]
        }
        if (Object.keys(dashboard?.inprogressDeployedCount).length === 0 && dashboard?.inprogressDeployedCount[selectedCurrentRow?.id] === undefined) {
            deployedCount = getDeployedPartCountOfSelectedTrackerrow()
        }

        return deployedCount
    }


    useEffect(() => {
        if (selectedCurrentRow?.id) {
            let existingDeployedCount = getInprogressDefaultValue();

            setInprogressDeployedPartCount(prev => {
                if (prev[selectedCurrentRow.id] !== existingDeployedCount) {
                    return {
                        ...prev,
                        [selectedCurrentRow.id]: existingDeployedCount
                    };
                }
                return prev;
            });

            // Check if the existing value is valid and enable the button if it is
            const isValid = existingDeployedCount > 0 && existingDeployedCount <= inprogressTotalPartCount;
            setBtnEnable(isValid);

            if (!updatedAtomRef.current[selectedCurrentRow.id]) {
                let updatedObj = {
                    ...dashboard.inprogressDeployedCount,
                    [selectedCurrentRow.id]: existingDeployedCount
                };

                updateInprogressDeployedCount(updatedObj);
                updatedAtomRef.current[selectedCurrentRow.id] = true;
            }
        }
    }, [selectedCurrentRow, getInprogressDefaultValue, dashboard.inprogressDeployedCount, updateInprogressDeployedCount, inprogressTotalPartCount]);
    // Reset the ref when selectedCurrentRow changes
    useEffect(() => {
        updatedAtomRef.current = {};
    }, [selectedCurrentRow]);

    useEffect(() => {
        if (openSteppedModal && selectedTrackerTypeId && dashboard?.bulkPiersTorqueTubesBlockData) {
            const result = pierTtDetails(selectedTrackerTypeId);
            if (result) {
                setPiersTorqueTubesDetails(result.pierTTObj);
                setTrackerRows(result.selectedTrackerTypeTrackerRows);
            }
        }
    }, [openSteppedModal, selectedTrackerTypeId, dashboard?.bulkPiersTorqueTubesBlockData]);

    const pierTtDetails = (trackerTypeId) => {
        if (dashboard?.bulkPiersTorqueTubesBlockData) {
            let pierTTObj = {}
            let groupedTrackerRows = groupByTrackerType()
            let selectedTrackerTypeTrackerRows = groupedTrackerRows[trackerTypeId]
            let trackerrowId = parseInt(selectedTrackerTypeTrackerRows[0]?.split("-")[1])
            let piersTorqueTubeData = dashboard?.bulkPiersTorqueTubesBlockData
            if (piersTorqueTubeData["piers"][trackerrowId] && piersTorqueTubeData["tt"][trackerrowId]) {
                pierTTObj["piers"] = piersTorqueTubeData["piers"][trackerrowId]
                pierTTObj["torque_tube"] = piersTorqueTubeData["tt"][trackerrowId]
                return { pierTTObj, selectedTrackerTypeTrackerRows };
            }
        }
        return null;
    };


    const handleToggleRow = async (trackerTypeId) => {
        setInnerTableLoading(prev => ({ ...prev, [trackerTypeId]: true }));
        setOpenRows(prev => ({
            ...prev,
            [trackerTypeId]: !prev[trackerTypeId]
        }));
        setCurrentTrackerTypeId(parseInt(trackerTypeId))
        let groupData = groupByTrackerType()
        let currentSelectedTrackerTypes = groupData[trackerTypeId]
        let bomData = await get_distributed_and_installed_bom_for_activity(selectedActivity, currentSelectedTrackerTypes[0], dashboard?.distributedInstalledBom, dashboard?.total_installed_distributed, dashboard?.existing_bom_per_tracker_row, isZimmerman, dashboard?.zimmermanTableWisePierPartBom)
        setBomDetailss(prev => ({ ...prev, [trackerTypeId]: bomData }));
        setInnerTableLoading(prev => ({ ...prev, [trackerTypeId]: false }));
    };



    function groupByTrackerType() {
        const groupedData = {};

        selectedRows?.forEach(row => {
            // Split the string to get the tracker type ID (second index in the array)
            const trackerTypeId = parseInt(row?.split('-')[2]);

            // If the key (trackerTypeId) already exists in the object, push the row string to the array
            if (groupedData[trackerTypeId]) {
                groupedData[trackerTypeId].push(row);
            } else {
                // Otherwise, create a new array with the row string
                groupedData[trackerTypeId] = [row];
            }
        });

        return groupedData;
    }


    const areAllValuesValid = useCallback(() => {
        const allQuantitiesValid = Object.entries(updateQuantity).every(([trackerTypeId, trackerData]) =>
            Object.entries(trackerData).every(([key, item]) => {
                const [bomId, trackerRowId] = key.split('_');
                const maxVal = bomDetailss[trackerTypeId]?.distributed?.find(i => i.bom_id === parseInt(bomId))?.total_quantity ||
                    bomDetailss[trackerTypeId]?.installed?.find(i => i.bom_id === parseInt(bomId))?.total_quantity;
                return Object.values(item).every(value => value <= maxVal);
            })
        );


        const unitOfCountValid = selectedCurrentRow?.id ?
            (inprogressDeployedPartCount[selectedCurrentRow.id] || 0) > 0 &&
            (inprogressDeployedPartCount[selectedCurrentRow.id] || 0) <= inprogressTotalPartCount
            : false;

        return allQuantitiesValid && unitOfCountValid;
    }, [updateQuantity, bomDetailss, inprogressDeployedPartCount, selectedCurrentRow, inprogressTotalPartCount]);

    // Add this new effect to handle quantity validation
    useEffect(() => {
        let hasInvalidQuantity = false;
        let maxExceededValue = null;
        let maxAllowedValue = null;
        let quantityType = null;

        // Check all quantities
        Object.entries(updateQuantity).forEach(([trackerTypeId, trackerData]) => {
            Object.entries(trackerData).forEach(([key, item]) => {
                const [bomId, trackerRowId] = key.split('_');
                const distributedMaxVal = bomDetailss[trackerTypeId]?.distributed?.find(i => i.bom_id === parseInt(bomId))?.total_quantity;
                const installedMaxVal = bomDetailss[trackerTypeId]?.installed?.find(i => i.bom_id === parseInt(bomId))?.total_quantity;

                Object.entries(item).forEach(([type, value]) => {
                    const maxVal = type === 'distributed' ? distributedMaxVal : installedMaxVal;
                    if (value > maxVal) {
                        hasInvalidQuantity = true;
                        maxExceededValue = value;
                        maxAllowedValue = maxVal;
                        quantityType = type;
                    }
                });
            });
        });

        // Show quantity error if present
        if (hasInvalidQuantity) {
            setErrorMessage(`Please enter a value less than or equal to the total quantity`);
            setShowError(true);
            return; // Exit early if quantity error exists
        }

        // Check unit count only if quantities are valid
        const unitCount = selectedCurrentRow?.id ? (inprogressDeployedPartCount[selectedCurrentRow.id] || 0) : 0;
        if (selectedCurrentRow?.id && unitCount > inprogressTotalPartCount) {
            setErrorMessage("Please enter a value less than or equal to the Total Part Count");
            setShowError(true);
            return;
        }

        // Clear error message if everything is valid
        if (!hasInvalidQuantity && (!selectedCurrentRow?.id || unitCount <= inprogressTotalPartCount)) {
            setShowError(false);
            setErrorMessage('');
        }

    }, [updateQuantity, bomDetailss, inprogressDeployedPartCount, selectedCurrentRow, inprogressTotalPartCount]);

    const handleQtyOnChange = useCallback((e, item, maxVal, type) => {
        const trackerTypeId = parseInt(item?.tracker_type_id);
        const value = parseInt(e.target.value) || 0; // Use 0 if the input is empty

        setUpdateQuantity(prev => ({
            ...prev,
            [trackerTypeId]: {
                ...prev[trackerTypeId],
                [`${item.bom_id}_${item.tracker_row_id}`]: {
                    ...prev[trackerTypeId]?.[`${item.bom_id}_${item.tracker_row_id}`],
                    [type]: value
                }
            }
        }));

        setIsQtyExceeded(value > maxVal);

        // Use setTimeout to ensure state updates have occurred
        setTimeout(() => {
            setBtnEnable(areAllValuesValid());
        }, 0);

    }, [areAllValuesValid]);


    useEffect(() => {
        let isValid = areAllValuesValid()
        setBtnEnable(isValid)
    }, [inprogressDeployedPartCount])

    const getLinkedPartName = () => {
        let partName = ""
        if (activities?.construction && localStorage.getItem("construction-act-id") !== "All" && localStorage.getItem("construction-act-id") !== "None Selected" && activities?.construction[JSON.parse(localStorage.getItem("construction-act-id"))]) {
            for (let i of listParts) {
                if (i.id === activities?.construction[JSON.parse(localStorage.getItem("construction-act-id"))]?.part_id) {
                    partName = i?.data?.part_name
                }

            }
        }
        return partName
    }
    const handleSubmit = () => {
        setIsSubmitting(true)
        handleClick()
    };

    useEffect(() => {
        if (Object.keys(dashboard?.listOfPiersTorqueTubeIdsWithStatus).length > 0)
            updateListOfPiersTorqueTubeIdsWithStatusForAllTrackers()
    }, [dashboard?.listOfPiersTorqueTubeIdsWithStatus])

    const updateListOfPiersTorqueTubeIdsWithStatusForAllTrackers = () => {
        let updatedListOfPiersTorqueTubeIdsWithStatusForAllTrackers = get_list_of_piers_torquetubes_ids_with_status_for_all_selected_trackers(dashboard?.bulkPiersTorqueTubesBlockData, selectedRows, dashboard?.listOfPiersTorqueTubeIdsWithStatus)
        setInprogressPierTTStatusForAllSelectedTrackers(updatedListOfPiersTorqueTubeIdsWithStatusForAllTrackers)
    }

    const handleClick = async () => {
        try {
            let cpdata = [];
            let checkActivityType = "";

            for (let i of selectedRows) {
                if (
                    activities.construction[selectedActivity]?.parent_activity_id === null
                ) {
                    checkActivityType = "parent_activity";
                } else {
                    checkActivityType = "sub_activity";
                }
                if (checkActivityType === "parent_activity") {
                    for (let actId in activities?.construction) {
                        if (activities.construction[actId].parent_activity_id === parseInt(selectedActivity) || parseInt(actId) === parseInt(selectedActivity)) {
                            let temp = await UpdateCp(actId, i)
                            cpdata.push(temp);
                        }
                    }
                } else {
                    let parentActivityId = activities?.construction[selectedActivity]?.parent_activity_id
                    let selectedActivityData = await UpdateCp(selectedActivity, i)
                    cpdata.push(selectedActivityData);
                    let parentActivityData = await UpdateCp(selectedActivity, i, checkActivityType, parentActivityId)
                    cpdata.push(parentActivityData);
                }
            }
            let copyOfTrackerRowWise = lodash.cloneDeep(constructionProgressTrackerrowWise);
            for (let j of cpdata) {
                let trackerActivityKey = `${parseInt(j?.tracker_row_id)}_${j?.activity_id}`;

                if (constructionProgressTrackerrowWise[trackerActivityKey]) {
                    copyOfTrackerRowWise[trackerActivityKey].status = j?.status;
                    copyOfTrackerRowWise[trackerActivityKey].completion_percentage = j?.completion_percentage;
                    copyOfTrackerRowWise[trackerActivityKey].deployed_part_count = j?.deployed_part_count;
                    copyOfTrackerRowWise[trackerActivityKey].total_part_count = j?.total_part_count;
                    if (parseInt(j?.status) === 1) {
                        copyOfTrackerRowWise[trackerActivityKey].piers = j?.piers ? j?.piers : []
                        copyOfTrackerRowWise[trackerActivityKey].torque_tubes = j?.torque_tubes ? j?.torque_tubes : []
                    }

                } else {
                    copyOfTrackerRowWise[trackerActivityKey] = {
                        activity_id: j?.activity_id,
                        completion_percentage: j?.completion_percentage,
                        deployed_part_count: j?.deployed_part_count,
                        status: parseInt(j?.status),
                        total_part_count: j?.total_part_count,
                        selectedRows: j?.tracker_row_id,
                        piers: j?.piers ? j?.piers : [],
                        torque_tubes: j?.torque_tubes ? j?.torque_tubes : []
                    }
                }
            }
            for (let i in dashboard?.updateDistributedAndInstalledQuantity) {
                if (!dashboard?.existing_bom_per_tracker_row[i]) {
                    setDashboard(
                        produce((draft) => {
                            draft.existing_bom_per_tracker_row[i] = {
                                distributed: dashboard?.updateDistributedAndInstalledQuantity[i].Distributed
                                    ? dashboard?.updateDistributedAndInstalledQuantity[i].Distributed
                                    : null,
                                installed: dashboard?.updateDistributedAndInstalledQuantity[i].Installed
                                    ? dashboard?.updateDistributedAndInstalledQuantity[i].Installed
                                    : null,
                            };
                        })
                    );
                }
                if (dashboard?.updateDistributedAndInstalledQuantity[i]["installed"]) {
                    setDashboard(
                        produce((draft) => {
                            draft.existing_bom_per_tracker_row[i]["installed"] =
                                dashboard?.updateDistributedAndInstalledQuantity[i]["installed"];
                        })
                    );
                }
                if (dashboard?.updateDistributedAndInstalledQuantity[i]["distributed"]) {
                    setDashboard(
                        produce((draft) => {
                            draft.existing_bom_per_tracker_row[i]["distributed"] =
                                dashboard?.updateDistributedAndInstalledQuantity[i]["distributed"];
                        })
                    );
                }
            }
            let cpObj = {}
            let blockId = params.id
            cpObj[blockId] = cpdata
            await insertCPQueue(cpObj);
            await updateExistingDistrbutedInstalled(dashboard?.updateDistributedAndInstalledQuantity);
            setConstructionProgressTrackerrowWise(copyOfTrackerRowWise)
            updateInprogressDeployedCount({})
            setInprogressDeployedPartCount({})
            getSelectedPiersTorqueTubesData({})
            getListOfPiersTorqueTubeIdsWithStatus({})
            setIsSubmitting(false)
            toast.success(<Msg />, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            navigate(-1)
        } catch (err) {
            console.log(err);
        }
    };


    const isValueExceeded = (data) => {
        // Get the value for the specific type (distributed/installed)
        const key = `${data?.bom_id}_${parseInt(data?.tracker_row_id)}`;
        let value = null;

        if (dashboard?.updateDistributedAndInstalledQuantity[key] &&
            dashboard?.updateDistributedAndInstalledQuantity[key][data?.found_type]) {
            value = dashboard?.updateDistributedAndInstalledQuantity[key][data?.found_type];
        } else if (dashboard?.existing_bom_per_tracker_row[key] &&
            dashboard?.existing_bom_per_tracker_row[key][data?.found_type]) {
            value = dashboard?.existing_bom_per_tracker_row[key][data?.found_type];
        }

        return value > data?.total_quantity;
    };

    const UpdateCp = async (selected_activity_id, selectedTrackerrow, activityType, parentActivityId) => {

        let updatedData = {}
        let bomData = await get_distributed_and_installed_bom_for_activity(selected_activity_id, selectedTrackerrow, dashboard?.distributedInstalledBom, dashboard?.total_installed_distributed, dashboard?.existing_bom_per_tracker_row, isZimmerman, dashboard?.zimmermanTableWisePierPartBom)
        let foundType = ""
        if (bomData) {
            if (Object.keys(bomData).length === 1) {
                for (let i in bomData) {
                    foundType = i

                }
            } else {
                foundType = "distributed_installed"

            }
        }
        if (isZimmerman === true) {
            updatedData = await get_updated_bom_for_distributed_installed_for_selected_activity_trackerow(dashboard?.zimmermanBulkPiers, blockDetails, bomData, selected_activity_id, dashboard?.updateDistributedAndInstalledQuantity, parseInt(status), foundType, dashboard?.part_per_tracker, activities?.construction, selectedTrackerrow, dashboard?.inprogressDeployedCount, dashboard?.listOfZimmermanPierIdsWithStatus, isZimmerman, activityType, parentActivityId, constructionProgressTrackerrowWise)
        } else {
            updatedData = await get_updated_bom_for_distributed_installed_for_selected_activity_trackerow(dashboard?.bulkPiersTorqueTubesBlockData, blockDetails, bomData, selected_activity_id, dashboard?.updateDistributedAndInstalledQuantity, parseInt(status), foundType, dashboard?.part_per_tracker, activities?.construction, selectedTrackerrow, dashboard?.inprogressDeployedCount, inprogressPierTTStatusForAllSelectedTrackers, isZimmerman, activityType, parentActivityId, constructionProgressTrackerrowWise)
        }
        return updatedData

    };

    useEffect(() => {
        const updateQuantities = async () => {
            const groupedData = groupByTrackerType();
            let allUpdates = {};
            for (const [trackerTypeId, quantities] of Object.entries(updateQuantity)) {
                const updatedForTrackerType = await updated_progress_quantity_for_all_selected_trackerrows(
                    quantities,
                    groupedData[trackerTypeId]
                );
                allUpdates = { ...allUpdates, ...updatedForTrackerType };
            }
            if (Object.keys(allUpdates).length > 0) {
                await updateDistributedAndInstalledQuantity(allUpdates);
            }
        };

        updateQuantities();
    }, [updateQuantity]);

    const getExistingDistributedAndInstalledQuantity = (data) => {
        let existingValue = null
        let bomId_trackerrowId_key = `${parseInt(data?.bom_id)}_${parseInt(data?.tracker_row_id)}`
        if (dashboard?.existing_bom_per_tracker_row[bomId_trackerrowId_key]) {
            if (dashboard?.existing_bom_per_tracker_row[bomId_trackerrowId_key][data?.found_type] !== 0) {
                existingValue = dashboard?.existing_bom_per_tracker_row[bomId_trackerrowId_key][data?.found_type]
            }
        }
        return existingValue
    }

    const getCurrentDistributedAndInstalledQuantity = (data) => {
        let currentValue = null
        let key = `${data?.bom_id}_${parseInt(data?.tracker_row_id)}`
        if (Object.keys(dashboard?.updateDistributedAndInstalledQuantity).length > 0) {
            if (dashboard?.updateDistributedAndInstalledQuantity[key] && dashboard?.updateDistributedAndInstalledQuantity[`${data?.bom_id}_${parseInt(data?.tracker_row_id)}`][data?.found_type])
                currentValue = dashboard?.updateDistributedAndInstalledQuantity[key][data?.found_type]
        }
        return currentValue
    }

    const handleInprogressDeployedPartCount = useCallback((e) => {
        const value = parseInt(e.target.value) || 0;

        if (selectedCurrentRow?.id) {
            setInprogressDeployedPartCount(prev => ({
                ...prev,
                [selectedCurrentRow.id]: value
            }));

            let updatedObj = {
                ...dashboard.inprogressDeployedCount,
                [selectedCurrentRow?.id]: value
            };
            updateInprogressDeployedCount(updatedObj);

            const isExceeded = value > inprogressTotalPartCount;
            setIsQtyExceeded(isExceeded);

            // Use setTimeout to ensure state updates have occurred before checking validity
            setTimeout(() => {
                const isValid = false
                let currentTrackerTypeBomDetails = bomDetailss[selectedCurrentRow?.id]
                if (Object.keys(currentTrackerTypeBomDetails).length === 0) {
                    isValid = value > 0 && value <= inprogressTotalPartCount;
                } else {
                    isValid = areAllValuesValid();
                }

                setBtnEnable(isValid);
            }, 0);
        }
    }, [selectedCurrentRow, inprogressTotalPartCount, updateInprogressDeployedCount, areAllValuesValid]);

    const renderTable = (data, type) => (
        <Table size="small" sx={{ marginBottom: 2 }}>
            <TableHead>
                <TableRow>
                    <TableCell sx={{ backgroundColor: "rgb(254 202 202)", width: '20%' }}>Part Number</TableCell>
                    <TableCell sx={{ backgroundColor: "rgb(254 202 202)", width: '50%' }}>Part Description</TableCell>
                    <TableCell sx={{ backgroundColor: "rgb(254 202 202)", width: '15%' }}>Quantities Per Tracker</TableCell>
                    <TableCell sx={{ width: '15%' }}>{type.charAt(0).toUpperCase() + type.slice(1)} Quantities</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item) => (
                    <TableRow key={item?.bom_id}>
                        <TableCell>{item?.part_name}</TableCell>
                        <TableCell>{item?.description}</TableCell>
                        <TableCell>{item?.total_quantity}</TableCell>
                        <TableCell>
                            <TextField
                                name="qty"
                                defaultValue={dashboard?.updateDistributedAndInstalledQuantity[`${item?.bom_id}_${parseInt(item?.tracker_row_id)}`] && dashboard?.updateDistributedAndInstalledQuantity[`${item?.bom_id}_${parseInt(item?.tracker_row_id)}`][item?.found_type] ? getCurrentDistributedAndInstalledQuantity(item) : getExistingDistributedAndInstalledQuantity(item)}
                                onChange={(e) => handleQtyOnChange(e, item, item?.total_quantity, type)}
                                sx={{
                                    width: 160,
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: isValueExceeded(item) ? 'red' : 'rgba(0, 0, 0, 0.23)',
                                            borderWidth: isValueExceeded(item) ? 2 : 1,
                                        },
                                    },
                                }}
                                error={isValueExceeded(item)}
                                InputProps={{
                                    inputProps: {
                                        type: "number",
                                        max: item?.total_quantity,
                                        min: 0,
                                    },
                                }}
                            />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
    const InnerTable = ({ row }) => {
        const isOpen = openRows[row.id] || false;
        const completed = isRowCompleted(row);
        return (
            <>
                <TableRow>
                    <TableCell>{row?.tracker_type}</TableCell>
                    <TableCell>{row?.count}</TableCell>
                    <TableCell>
                        <Button variant="contained" sx={{ textTransform: "none" }} onClick={handleOpenSteppedModal(row)}>Mark Progress</Button>
                    </TableCell>
                    <TableCell>
                        {completed && <CheckCircleIcon sx={{ color: 'green' }} />}
                    </TableCell>

                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                        <Collapse in={isOpen} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                {innerTableLoading[row.id] ? (
                                    <CircularProgress />
                                ) : (
                                    <>
                                        {bomDetailss[row?.id]?.distributed && renderTable(bomDetailss[row?.id]?.distributed, "distributed")}
                                        {bomDetailss[row?.id]?.installed && renderTable(bomDetailss[row?.id]?.installed, "installed")}

                                    </>
                                )}

                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    };

    const innerTableComponent = (

        <Box>
            {selectedCurrentRow && (
                <>
                    {innerTableLoading[selectedCurrentRow?.id] ? (
                        <CircularProgress />
                    ) : (
                        <>
                            {bomDetailss[selectedCurrentRow?.id]?.distributed && (
                                <>
                                    <Typography variant="h6">Distributed Quantities</Typography>
                                    {renderTable(bomDetailss[selectedCurrentRow?.id].distributed, "distributed")}
                                </>
                            )}
                            {bomDetailss[selectedCurrentRow?.id]?.installed && (
                                <>
                                    <Typography variant="h6">Installed Quantities</Typography>
                                    {renderTable(bomDetailss[selectedCurrentRow.id].installed, "installed")}
                                </>
                            )}
                            {/* {!bomDetailss[selectedCurrentRow?.id]?.distributed && !bomDetailss[selectedCurrentRow?.id]?.installed && (
                                <Typography>No data available for this tracker type.</Typography>
                            )} */}
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start", }}>
                                <Typography sx={{ fontSize: "14px", paddingRight: "5px" }}>Unit of Count <br />{getLinkedPartName()}</Typography>
                                <TextField
                                    defaultValue={getInprogressDefaultValue()}
                                    name="unit of counts"
                                    onChange={(e) => handleInprogressDeployedPartCount(e)}
                                    sx={{
                                        width: "150px",
                                        paddingLeft: "10px",
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: getInprogressDefaultValue() > inprogressTotalPartCount ? 'red' : 'rgba(0, 0, 0, 0.23)',
                                                borderWidth: getInprogressDefaultValue() > inprogressTotalPartCount ? 2 : 1,
                                            },
                                        },
                                    }}
                                    error={getInprogressDefaultValue() > inprogressTotalPartCount}
                                    InputProps={{
                                        inputProps: {
                                            type: "number",
                                            max: inprogressTotalPartCount,
                                            min: 0,
                                        },
                                    }}
                                />
                                <Typography sx={{
                                    fontSize: "55px",
                                    fontWeight: 100,
                                    marginX: "10px",
                                }}>/</Typography>
                                <Typography sx={{ fontSize: "18px" }}>{inprogressTotalPartCount}</Typography>
                            </Box>
                        </>
                    )}
                </>
            )}
        </Box>
    );

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Header />
            <Box sx={{ display: "flex", alignItems: "center", }}>
                <Box
                    sx={{
                        backgroundColor: "#262223",
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "12px 5px 8px",
                        height: "50px",
                        width: "50px",
                        cursor: "pointer",
                        marginRight: 2,
                    }}
                    onClick={handleBackButton}
                >
                    <ArrowBackIcon />
                </Box>
                <Box>
                    <Typography variant="h6" component="h2">
                        {isZimmerman ? blockDetails?.block : "Block 1"}
                    </Typography>
                    <Typography sx={{ color: "#696F79", fontSize: "16px" }}>
                        Number of trackers selected - {selectedRows.length}
                    </Typography>
                </Box>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
                <TableContainer component={Paper} sx={{ mb: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Tracker Type Name</TableCell>
                                <TableCell>Quantities</TableCell>
                                <TableCell />
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {inprogressSelectedTrackerDetails?.map((row) => (
                                <InnerTable key={row.id} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box sx={{ flexGrow: 1 }} /> {/* This pushes the button to the bottom */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isSubmitting || !inprogressSelectedTrackerDetails.some(isRowCompleted)}
                    sx={{
                        borderRadius: '20px',
                        textTransform: 'none',
                        cursor: "pointer"
                    }}
                >
                    {isSubmitting ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : (
                        'Submit'
                    )}
                </Button>
            </Box>

            <SteppedModal
                open={openSteppedModal}
                handleClose={handleCloseSteppedModal}
                innerTableComponent={innerTableComponent}
                piersTorqueTubesDetails={piersTorqueTubesDetails}
                trackerRows={trackerRows}
                enableNextButton={btnEnable}
                enableSaveButton={saveBtnEnable}
                onCloseWithoutSaving={handleCloseWithoutSaving}
                selectedTrackerDetails={selectedCurrentRow}
                inprogressCount={inprogressDeployedPartCount[selectedCurrentRow?.id] || 0}
                isQtyExceeded={isQtyExceeded}
            />

            {showError === true &&
                <CustomNotification
                    message={errorMessage}
                    isVisible={showError}
                />}
        </Box>
    );
}

export default ConstructionInprogress





