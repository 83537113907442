import { Box, Typography } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const CustomNotification = ({ message, isVisible }) => (
    <Box
      sx={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: 'black',
        color: 'white',
        padding: '12px 20px',
        borderRadius: '4px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        zIndex: 9999,
        transform: isVisible ? 'translateY(0)' : 'translateY(200%)',
        opacity: isVisible ? 1 : 0,
        transition: 'all 0.3s ease-in-out',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      <ErrorOutlineIcon fontSize="small" color="error" />
      <Typography sx={{ fontSize: '14px' }}>{message}</Typography>
    </Box>
  );


  export default CustomNotification