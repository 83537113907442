import {
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  Box,
  FormControlLabel,
  Typography,
  CircularProgress,
} from "@mui/material";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import React, { useEffect, useState } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import dashboardAtom, { fetchZimmermanTableCoordinatesAtom, getListOfPiersTorqueTubeIdsWithStatusAtom, getListOfZimmermanPierIdsWithStatusAtom, getSelectedPiersTorqueTubesDataAtom } from "../data/dashboardAtom";
import { useAtom } from "jotai";
import IndividualTrackerRow from "../pages/pierList/individualTrackerRow";
import { IS_PROJECT_TYPE_ZIMMERMAN_FLAG } from "../featureFlags/featureFlags";
import ZimmerBlock from "../pages/pierList/individualTableZimmerman";
import { useParams } from "react-router-dom";
import constructionProgressTrackerrowWiseAtom, { fetchConstructionProgressTrackerrowWiseAtom } from "../data/constructionProgressAtom";

const ConstructionProgressPierTTSelection = ({ pierTorqueTubeData, selectedTrackerrow, activityId, clearState }) => {

  const [dashboard] = useAtom(dashboardAtom)
  const [constructionProgressTrackerrowWise] = useAtom(constructionProgressTrackerrowWiseAtom)
  const [, fetchConstructionProgressTrackerrowWise] = useAtom(fetchConstructionProgressTrackerrowWiseAtom)
  const [, getListOfPiersTorqueTubeIdsWithStatus] = useAtom(getListOfPiersTorqueTubeIdsWithStatusAtom)
  const [selectedPiersTorqueTubes, setSelectedPiersTorqueTubes] = useState({ piers: [], torque_tubes: [] })
  const [piersTorqueTubeIdsWithStatus, setPiersTorqueTubeIdsWithStatus] = useState({})
  const [checkPreviousStatus, setCheckPreviousStatus] = useState(false)
  const [checkPreviousStatusZimmerman, setCheckPreviousStatusZimmerman] = useState(false)
  const [isZimmerman, setIsZimmerman] = useState(false)
  const [selectedZimmermanPiers, setSelectedZimmermanPiers] = useState([])
  const [, fetchZimmermanTableCoordinates] = useAtom(fetchZimmermanTableCoordinatesAtom)
  const [, getListOfZimmermanPierIdsWithStatus] = useAtom(getListOfZimmermanPierIdsWithStatusAtom)
  const [selectedTableZimmermanTableCoordinates, setSelectedTableZimmermanTableCoordinates] = useState({})
  const [selectedZimmermanPiersWithStatus, setSelectedZimmermanPiersWithStatus] = useState([])
  const params = useParams()


  useEffect(() => {
    async function fetchProjectTypeFlag() {
      let is_zimmeran = await IS_PROJECT_TYPE_ZIMMERMAN_FLAG()
      setIsZimmerman(is_zimmeran)
    }
    fetchProjectTypeFlag();
  }, [])
  useEffect(() => {
    if (isZimmerman === true) {
      getZimmermanTableCoordinates()
    }
  }, [isZimmerman])

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    try {
      await fetchConstructionProgressTrackerrowWise(parseInt(params?.id))
    } catch (err) {
      console.log(err)
    }
  }

  const getZimmermanTableCoordinates = async () => {
    try {
      await fetchZimmermanTableCoordinates(parseInt(params.id))
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (dashboard?.zimmermanTableCoordinates) {
      let tableId = selectedTrackerrow[0]?.split("-")[1]
      let selectedTableCoordinates = dashboard?.zimmermanTableCoordinates[tableId]
      setSelectedTableZimmermanTableCoordinates(selectedTableCoordinates)
    }

  }, [dashboard?.zimmermanTableCoordinates])

  useEffect(() => {
    if (Object.keys(piersTorqueTubeIdsWithStatus) !== 0) {
      getCompletedPiersAndTorqueTubes()
    }
  }, [piersTorqueTubeIdsWithStatus])

  useEffect(() => {
    getZimmermanCompletedPiersWithStatus()
  }, [selectedZimmermanPiersWithStatus])

  const getZimmermanCompletedPiersWithStatus = async () => {
    try {
      await getListOfZimmermanPierIdsWithStatus(selectedZimmermanPiersWithStatus)
    } catch (err) {
      console.log(err)
    }
  }
  const getCompletedPiersAndTorqueTubes = async () => {
    let data = {}
    if (Object.keys(dashboard?.listOfPiersTorqueTubeIdsWithStatus)?.length === 0) {
      data = piersTorqueTubeIdsWithStatus
    } else {
      data = { ...dashboard?.listOfPiersTorqueTubeIdsWithStatus, ...piersTorqueTubeIdsWithStatus }
    }
    try {
      await getListOfPiersTorqueTubeIdsWithStatus(data)
    } catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {
    if (isZimmerman === false && selectedTrackerrow.length !== 0) {
      let PiersTorqueTubes = {}
      let piers = []
      let torqueTubes = []
      let pierIds = []
      let torqueTubeIds = []
      let trackerTypeId = parseInt(selectedTrackerrow[0]?.split("-")[2])
      let trackerrowId_activityId_key = `${selectedTrackerrow[0]?.split("-")[1]}_${parseInt(activityId)}`
      if (Object.keys(dashboard?.listOfPiersTorqueTubeIdsWithStatus).length !== 0 && dashboard?.listOfPiersTorqueTubeIdsWithStatus[trackerTypeId]) {
        let prData = dashboard?.listOfPiersTorqueTubeIdsWithStatus[trackerTypeId]["piers"]
        PiersTorqueTubes["piers"] = prData
        if (prData !== undefined) {
          for (let i of prData) {
            if (i?.status === 1) {
              pierIds.push(i?.pier_id)
            }
          }
        }
        let ttData = dashboard?.listOfPiersTorqueTubeIdsWithStatus[trackerTypeId]["torque_tubes"]
        PiersTorqueTubes["torque_tubes"] = ttData
        if (ttData !== undefined) {
          for (let j of ttData) {
            if (j?.status === 1) {
              torqueTubeIds.push(j?.torque_tube_id)
            }
          }
        }

      } else {
        if (selectedTrackerrow?.length === 1 && constructionProgressTrackerrowWise !== undefined && constructionProgressTrackerrowWise[trackerrowId_activityId_key] && constructionProgressTrackerrowWise[trackerrowId_activityId_key]["piers"]?.length > 0) {
          let pierData = constructionProgressTrackerrowWise[trackerrowId_activityId_key]["piers"]
          PiersTorqueTubes["piers"] = pierData
          for (let i of pierData) {
            if (i?.status === 1) {
              pierIds.push(i?.pier_id)
            }
          }
        }
        else {
          if (pierTorqueTubeData["piers"]) {
            for (let i of pierTorqueTubeData["piers"]) {
              piers.push({ pier_id: i?.id, status: 0 })

            }
            PiersTorqueTubes["piers"] = piers
          }
        }

        if (selectedTrackerrow?.length === 1 && constructionProgressTrackerrowWise !== undefined && constructionProgressTrackerrowWise[trackerrowId_activityId_key] && constructionProgressTrackerrowWise[trackerrowId_activityId_key]["torque_tubes"]?.length > 0) {
          let ttData = constructionProgressTrackerrowWise[trackerrowId_activityId_key]["torque_tubes"]
          PiersTorqueTubes["torque_tubes"] = ttData
          for (let j of ttData) {
            if (j?.status === 1) {
              torqueTubeIds.push(j?.torque_tube_id)
            }
          }
        } else {
          if (pierTorqueTubeData["torque_tube"]) {
            for (let i of pierTorqueTubeData["torque_tube"]) {
              torqueTubes.push({ torque_tube_id: i?.id, status: 0 })
            }
            PiersTorqueTubes["torque_tubes"] = torqueTubes
          }
        }
      }
      let pierTTStatusObj = {}
      pierTTStatusObj[trackerTypeId] = PiersTorqueTubes
      setPiersTorqueTubeIdsWithStatus(pierTTStatusObj)
      setSelectedPiersTorqueTubes({ piers: pierIds, torque_tubes: torqueTubeIds })
      setCheckPreviousStatus(true)
    }

  }, [isZimmerman, selectedTrackerrow, constructionProgressTrackerrowWise])

  useEffect(() => {
    if (isZimmerman === true) {
      let piersWithStatus = []
      let pierIds = []
      let trackerrowId_activityId_key = `${selectedTrackerrow[0]?.split("-")[1]}_${parseInt(activityId)}`
      if (constructionProgressTrackerrowWise[trackerrowId_activityId_key] && constructionProgressTrackerrowWise[trackerrowId_activityId_key]["piers"]?.length > 0) {
        let pierData = constructionProgressTrackerrowWise[trackerrowId_activityId_key]["piers"]
        piersWithStatus = pierData
        for (let i of pierData) {
          if (i?.status === 1) {
            pierIds.push(i?.pier_id)

          }
        }
      }
      else {
        if (pierTorqueTubeData && pierTorqueTubeData.length !== 0) {
          for (let i of pierTorqueTubeData) {
            let tableId = parseInt(selectedTrackerrow[0].split("-")[1])
            if (i?.tracker_row_id === tableId) {
              piersWithStatus.push({ pier_id: i?.id, status: 0 })
            }
          }
        }
      }
      setSelectedZimmermanPiersWithStatus(piersWithStatus)
      setSelectedZimmermanPiers(pierIds)
      setCheckPreviousStatusZimmerman(true)
    }
  }, [isZimmerman])

  useEffect(() => {
    if (isZimmerman === false && selectedTrackerrow.length !== 0) {
      let trackerTypeId = selectedTrackerrow[0]?.split("-")[2]
      if (Object.keys(dashboard?.selectedPiersTorqueTubesData).length > 0 && (dashboard?.selectedPiersTorqueTubesData["piers"] || dashboard?.selectedPiersTorqueTubesData["torque_tubes"])) {
        if (piersTorqueTubeIdsWithStatus[trackerTypeId]) {
          if ((piersTorqueTubeIdsWithStatus[trackerTypeId]["piers"] && piersTorqueTubeIdsWithStatus[trackerTypeId]["torque_tubes"]) && (piersTorqueTubeIdsWithStatus[trackerTypeId]["piers"].length > 0 && piersTorqueTubeIdsWithStatus[trackerTypeId]["torque_tubes"].length > 0)) {
            setSelectedPiersTorqueTubes(dashboard?.selectedPiersTorqueTubesData)
            handleSelectPierAndTorqueTubeItem()
            setCheckPreviousStatus(true)
          }
        }
      }
    }
  }, [dashboard?.selectedPiersTorqueTubesData])

  useEffect(() => {
    if (isZimmerman === true) {
      if (dashboard?.zimmermanSelectedPiersData) {
        if (selectedZimmermanPiersWithStatus && selectedZimmermanPiersWithStatus.length !== 0) {
          setSelectedZimmermanPiers(dashboard?.zimmermanSelectedPiersData)
          handleZimmermanSelectPierItem()
          setCheckPreviousStatusZimmerman(true)
        }
      }
    }
  }, [dashboard?.zimmermanSelectedPiersData, isZimmerman])

  const handleZimmermanSelectPierItem = async () => {
    let temp = []
    if (dashboard?.zimmermanSelectedPiersData) {
      let piersDataWithStatus = selectedZimmermanPiersWithStatus
      let selectedPierData = dashboard?.zimmermanSelectedPiersData
      let updatedPiers = []
      for (let i of piersDataWithStatus) {
        if (selectedPierData.includes(i?.pier_id)) {
          updatedPiers.push({ ...i, status: 1 })
        } else {
          updatedPiers.push({ ...i, status: 0 })
        }
      }
      temp = updatedPiers
    }

    setSelectedZimmermanPiersWithStatus(temp)

  }
  const handleSelectPierAndTorqueTubeItem = async () => {
    let temp = {}
    let trackerTypeId = selectedTrackerrow[0]?.split("-")[2]
    if (dashboard?.selectedPiersTorqueTubesData["piers"]) {
      let piersDataWithStatus = piersTorqueTubeIdsWithStatus[trackerTypeId]["piers"]
      let selectedPierData = dashboard?.selectedPiersTorqueTubesData["piers"]
      let updatedPiers = []
      for (let i of piersDataWithStatus) {
        if (selectedPierData.includes(i?.pier_id)) {
          updatedPiers.push({ ...i, status: 1 })
        } else {
          updatedPiers.push({ ...i, status: 0 })
        }
      }
      temp["piers"] = updatedPiers
    }

    if (dashboard?.selectedPiersTorqueTubesData["torque_tubes"]) {
      let torqueTubesDataWithStatus = piersTorqueTubeIdsWithStatus[trackerTypeId]["torque_tubes"]

      let selectedTorqueTubeData = dashboard?.selectedPiersTorqueTubesData["torque_tubes"]
      let updatedTorqueTubes = []
      for (let i of torqueTubesDataWithStatus) {
        if (selectedTorqueTubeData.includes(i?.torque_tube_id)) {
          updatedTorqueTubes.push({ ...i, status: 1 })
        } else {
          updatedTorqueTubes.push({ ...i, status: 0 })
        }
      }

      temp["torque_tubes"] = updatedTorqueTubes

    }
    let sortedData = sortPierAndTTData(temp)
    let pierTTStatusObj = {}
    pierTTStatusObj[trackerTypeId] = sortedData
    setPiersTorqueTubeIdsWithStatus(pierTTStatusObj)

  }


  function sortPierAndTTData(data) {
    // Sort piers based on pier_id
    data.piers.sort((a, b) => a.pier_id - b.pier_id);

    // Sort torque_tubes based on torque_tube_id
    data.torque_tubes.sort((a, b) => a.torque_tube_id - b.torque_tube_id);

    return data;
  }
  useEffect(() => {
    if (dashboard?.selectedPiersTorqueTubesData && Object.keys(dashboard?.selectedPiersTorqueTubesData).length > 0) {
      setSelectedPiersTorqueTubes(dashboard?.selectedPiersTorqueTubesData)
    }
  }, [])

  return (
    <Box sx={{
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden"  // Hide overflow
    }}>
      {isZimmerman === false ? (
        <Box>
          {isZimmerman === false && Object.keys(pierTorqueTubeData).length > 0 && dashboard?.pierLegends && Object.keys(selectedPiersTorqueTubes).length !== 0 && selectedPiersTorqueTubes["piers"] && selectedPiersTorqueTubes["torque_tubes"] && checkPreviousStatus === true && pierTorqueTubeData?.torque_tube !== undefined && pierTorqueTubeData?.piers !== undefined ? (
            <Box sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",  // Set height to 100%
              overflow: "hidden"  // Hide overflow
            }}>
              <IndividualTrackerRow
                type="cp"
                data={pierTorqueTubeData}
                pierData={pierTorqueTubeData?.piers}
                torqueTubeData={pierTorqueTubeData?.torque_tube}
                pierLegend={dashboard?.pierLegends}
                selectedItem={selectedPiersTorqueTubes}
                clearState={clearState}
              />
            </Box>
          ) : <CircularProgress size="3rem" />}
        </Box>
      ) : (
        <Box>
          {isZimmerman === true && pierTorqueTubeData.length !== 0 && dashboard?.pierLegends && selectedZimmermanPiers && checkPreviousStatusZimmerman === true && Object.keys(selectedTableZimmermanTableCoordinates).length !== 0 && (
            <Box sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",  // Set height to 100%
              overflow: "hidden"  // Hide overflow
            }}>
              <ZimmerBlock
                data={pierTorqueTubeData}
                pierLegend={dashboard?.pierLegends}
                tableCoordinates={selectedTableZimmermanTableCoordinates}
                selectedItem={selectedZimmermanPiers}
                clearState={clearState}
                selectedTable={selectedTrackerrow}
                type="CP"
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default ConstructionProgressPierTTSelection
