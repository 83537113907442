import suryadb from "../indexedDB/module";
import axios from "axios";

const projectId = localStorage.getItem("project_id")
export async function insert_tracker_types_name() {
    if (!localStorage.getItem("get-data-initially")) {
        let trackerTypesName = await axios.get("/tracker/get_tracker_types");
        await suryadb().get_tracker_type_names.put({ id: 1, data: trackerTypesName.data });
    }
}

export async function insert_tracker_types_for_project(trackerTypeData) {
    await suryadb().get_tracker_types_for_project.put({ id: `projectId_${projectId}`, data: trackerTypeData });
}
export async function insert_tracker_types_for_block(trackerTypeBlockData) {
    for (let i in trackerTypeBlockData) {
        await suryadb().get_tracker_types_for_blocks.put({ id: parseInt(i), data: trackerTypeBlockData[i] });
    }
}

export async function get_tracker_types_name() {
    let data = []
    data = await suryadb().get_tracker_type_names.toArray();
    if (data.length !== 0) {
        return data[0].data
    }
}
export async function get_tracker_types_for_project() {
    let data = []
    data = await suryadb().get_tracker_types_for_project.toArray();
    if (data.length !== 0) {
        return data[0].data
    }
}

export async function get_tracker_types_for_block(blockId) {
    let data = []
    data = await suryadb().get_tracker_types_for_blocks.toArray();
    let TrackerTypeForSelectedBlock = []
    if (data.length !== 0) {
        for (let i of data) {
            if (parseInt(i?.id) === parseInt(blockId)) {
                TrackerTypeForSelectedBlock = i?.data
            }
        }
        return TrackerTypeForSelectedBlock
    }
}