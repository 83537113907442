// import { useAtom } from 'jotai';
// import { toast } from 'react-hot-toast';
// import suryadb from '../indexedDB/module';
// import syncStatusAtom from '../data/syncStatusAtom';
// import { updateBlockUpdateValue, updateSyncImages } from '../data/appBarAtom';

// export const useDataSyncCheck = () => {
//     const [syncStatus, updateSync] = useAtom(syncStatusAtom);
//     const [, blockUpdated] = useAtom(updateBlockUpdateValue);
//     const [, syncImages] = useAtom(updateSyncImages);

//     const checkAndInitiateSync = async () => {
//         // Skip if initial load is already complete or sync is in progress
//         if (syncStatus.initialLoadComplete || syncStatus.syncing) {
//             return;
//         }

//         // try {
//         //     const updatecp = await suryadb().update_construction_progress.toArray();
//         //     const updateqc = await suryadb().update_qc.toArray();
//         //     const hasData = updatecp.length > 0 || updateqc.length > 0;

//         //     // Check if this is first load after login
//         //     const isFirstLoad = !localStorage.getItem("last-sync");

//         //     if (!hasData && isFirstLoad) {
//         //         // First time load with no data - trigger getData
//         //         await initiateGetData();
//         //     } else if (!hasData) {
//         //         // Has sync history but no data - trigger getData
//         //         await initiateGetData();
//         //     } else {
//         //         // Has data - show sync reminder
//         //         toast.error("Please sync data to continue", {
//         //             duration: 3000,
//         //             position: "bottom-center"
//         //         });
//         //     }

//         //     // Mark initial load as complete
//         //     updateSync(prev => ({
//         //         ...prev,
//         //         initialLoadComplete: true
//         //     }));

//         // } catch (error) {
//         //     console.error('Error checking data sync status:', error);
//         //     toast.error("Error checking data status");
//         // }

//         try {
//             const updatecp = await suryadb().update_construction_progress.toArray();
//             const updateqc = await suryadb().update_qc.toArray();
//             const hasData = updatecp.length > 0 || updateqc.length > 0;

//             // Check if this is first load after login
//             const isFirstLoad = !localStorage.getItem("last-sync");

//             // Instead of initiating getData, just update the sync status
//             if (!hasData && isFirstLoad) {
//                 // First time load with no data - just mark as complete
//                 updateSync(prev => ({
//                     ...prev,
//                     initialLoadComplete: true
//                 }));
//             } else if (!hasData) {
//                 // Has sync history but no data - just mark as complete
//                 updateSync(prev => ({
//                     ...prev,
//                     initialLoadComplete: true
//                 }));
//             } else {
//                 // Has data - show sync reminder
//                 toast.warning("Please sync data to continue", {
//                     duration: 3000,
//                     position: "bottom-center"
//                 });
//                 // Mark as complete
//                 updateSync(prev => ({
//                     ...prev,
//                     initialLoadComplete: true
//                 }));
//             }

//         } catch (error) {
//             console.error('Error checking data sync status:', error);
//             toast.error("Error checking data status");
//             // Even on error, mark as complete to prevent reload
//             updateSync(prev => ({
//                 ...prev,
//                 initialLoadComplete: true
//             }));
//         }
//     };

//     const initiateGetData = async () => {
//         // Only initiate if not already syncing
//         if (!syncStatus.syncing) {
//             updateSync({
//                 syncing: true,
//                 status: "Syncing",
//                 block_name: "All",
//                 value: 0,
//                 syncInitiated: false,
//                 sync_mode: "Both",
//                 is_background: false,
//                 sync_text: "Getting Data",
//                 totalImages: 0,
//                 downloadedImages: 0,
//                 totalUploadImagesCount: 0,
//                 uploadedImagesCount: 0,
//                 initialLoadComplete: true
//             });

//             await blockUpdated({ value: false });
//             await syncImages({ value: true });
            
//             localStorage.setItem("last-sync", Date.now().toString());
//             localStorage.removeItem("updated-blocks");
//             localStorage.setItem("updating_getting_data", "false");
//         }
//     };

//     return checkAndInitiateSync;
// };



import { useAtom } from 'jotai';
import { toast } from 'react-hot-toast';
import suryadb from '../indexedDB/module';
import syncStatusAtom from '../data/syncStatusAtom';
import authAtom from '../data/authAtom';
import { updateBlockUpdateValue, updateSyncImages } from '../data/appBarAtom';

export const useDataSyncCheck = () => {
    const [syncStatus, updateSync] = useAtom(syncStatusAtom);
    const [auth] = useAtom(authAtom);
    const [, blockUpdated] = useAtom(updateBlockUpdateValue);
    const [, syncImages] = useAtom(updateSyncImages);

    const checkAndInitiateSync = async () => {
        
        // Skip if initial load is already complete or sync is in progress
        if (syncStatus.initialLoadComplete || syncStatus.syncing) {
            return;
        }

        try {
            const updatecp = await suryadb().update_construction_progress.toArray();
            const updateqc = await suryadb().update_qc.toArray();
            const hasData = updatecp.length > 0 || updateqc.length > 0;
            

            // Check if this is a fresh login session
            const loginSessionId = localStorage.getItem("loginSessionId");
            const currentSessionId = auth.loggedIn ? (auth.user?.id || "default") + Date.now() : null;


            if (auth.loggedIn && (!loginSessionId || loginSessionId === "null")) {
                // Fresh login detected
                localStorage.setItem("loginSessionId", currentSessionId);
                await initiateGetData();
            } else if (hasData) {
                toast.warning("Please sync data to continue", {
                    duration: 3000,
                    position: "bottom-center"
                });
                updateSync(prev => ({
                    ...prev,
                    initialLoadComplete: true
                }));
            } else {
                updateSync(prev => ({
                    ...prev,
                    initialLoadComplete: true
                }));
            }

        } catch (error) {
            console.error('Error details:', error);
            toast.error("Error checking data status");
            updateSync(prev => ({
                ...prev,
                initialLoadComplete: true
            }));
        }
    };

    const initiateGetData = async () => {
        if (!syncStatus.syncing) {
            updateSync({
                syncing: true,
                status: "Syncing",
                block_name: "All",
                value: 0,
                syncInitiated: false,
                sync_mode: "Both",
                is_background: false,
                sync_text: "Getting Data",
                totalImages: 0,
                downloadedImages: 0,
                totalUploadImagesCount: 0,
                uploadedImagesCount: 0,
                initialLoadComplete: true
            });

            await blockUpdated({ value: false });
            await syncImages({ value: true });
            
            // localStorage.setItem("last-sync", Date.now().toString());
            localStorage.removeItem("updated-blocks");
            localStorage.setItem("updating_getting_data", "false");
        }
    };

    return checkAndInitiateSync;
};