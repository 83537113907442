// dexieWrapper.js
import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography, 
  Box,
  CircularProgress 
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import originalSuryadb from './module';
import originalSuryaCommonDb from './imageModule';

let suryadbInstance = null;
let commondbInstance = null;

// Test button component - Commented out
// const TestButton = ({ onTest }) => (
//   <Button
//     onClick={onTest}
//     variant="contained"
//     color="secondary"
//     sx={{
//       position: 'fixed',
//       bottom: 20,
//       right: 20,
//       zIndex: 9999
//     }}
//   >
//     Test DB Error
//   </Button>
// );

// Wrap the original database functions with error handling
const wrapDatabase = (dbCreator, dbName, showError) => {
  return () => {
    try {
      let instance = dbName === 'suryadb' ? suryadbInstance : commondbInstance;
      
      if (!instance) {
        instance = dbCreator();
        if (dbName === 'suryadb') {
          suryadbInstance = instance;
        } else {
          commondbInstance = instance;
        }

        // Add error event listeners
        instance.on('close', () => {
          console.log(`${dbName} was closed`);
          if (dbName === 'suryadb') {
            suryadbInstance = null;
          } else {
            commondbInstance = null;
          }
          showError(true);
        });
      }

      // Proxy the database instance to catch errors
      return new Proxy(instance, {
        get(target, prop) {
          const originalValue = target[prop];
          
          if (typeof originalValue === 'function') {
            return new Proxy(originalValue, {
              apply: async function(target, thisArg, args) {
                try {
                  return await target.apply(thisArg, args);
                } catch (error) {
                  console.log(`Database error caught for ${dbName}:`, error);
                  if (
                    error.name === 'UnknownError' || 
                    error.name === 'DatabaseClosedError' ||
                    error.message?.includes('IndexedDB') ||
                    error.message?.includes('database connection')
                  ) {
                    console.log(`Showing error dialog for ${dbName}`);
                    if (dbName === 'suryadb') {
                      suryadbInstance = null;
                    } else {
                      commondbInstance = null;
                    }
                    showError(true);
                  }
                  throw error;
                }
              }
            });
          }
          return originalValue;
        }
      });
    } catch (error) {
      console.error(`Error with ${dbName}:`, error);
      showError(true);
      throw error;
    }
  };
};

export const DatabaseProvider = ({ children }) => {
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [retryStatus, setRetryStatus] = useState('initial'); // 'initial', 'retrying', 'success', 'error'

  const handleRetry = async () => {
    try {
      setRetryStatus('retrying');
      console.log('Retrying database connection...');
      
      // Reset instances to force recreation
      suryadbInstance = null;
      commondbInstance = null;

      // Try to connect to both databases
      const db1 = originalSuryadb();
      const db2 = originalSuryaCommonDb();
      
      // Test the connections
      await Promise.all([
        db1.open(),
        db2.open()
      ]);

      console.log('Database connection successful');
      setRetryStatus('success');
      
      // Close the dialog after showing success message
      setTimeout(() => {
        setShowErrorDialog(false);
        setRetryStatus('initial');
      }, 1500); // Show success message for 1.5 seconds

    } catch (error) {
      console.error('Retry failed:', error);
      setRetryStatus('error');
    }
  };

  // Test function to simulate database error - Commented out
  // const testDatabaseError = async () => {
  //   console.log('Testing database error...');
  //   try {
  //     const db = originalSuryadb();
  //     // Force close the database
  //     await db.close();
  //     console.log('Database closed');
      
  //     // Try to use the closed database
  //     await db.project_details.toArray();
  //   } catch (error) {
  //     console.log('Test error caught:', error);
  //     // Ensure the error dialog shows
  //     setShowErrorDialog(true);
  //   }
  // };

  // Error Dialog with status messages
  const ErrorDialog = () => {
    const getStatusContent = () => {
      switch (retryStatus) {
        case 'retrying':
          return (
            <Box sx={{ textAlign: 'center', py: 2 }}>
              <CircularProgress size={30} sx={{ mb: 2 }} />
              <Typography>
                Retrying database connection...
              </Typography>
            </Box>
          );
        case 'success':
          return (
            <Box sx={{ textAlign: 'center', py: 2, color: 'success.main' }}>
              <CheckCircleIcon sx={{ fontSize: 40, mb: 1 }} />
              <Typography>
                Connected to database successfully!
              </Typography>
            </Box>
          );
        case 'error':
          return (
            <Box sx={{ textAlign: 'center', py: 2, color: 'error.main' }}>
              <ErrorOutlineIcon sx={{ fontSize: 40, mb: 1 }} />
              <Typography>
                Connection failed. Please try again.
              </Typography>
            </Box>
          );
        default:
          return (
            <Typography variant="body1" align="center" color="text.secondary">
              Lost connection to database. Would you like to retry?
            </Typography>
          );
      }
    };

    return (
      <Dialog 
        open={showErrorDialog} 
        fullWidth 
        maxWidth="sm"
        onClose={() => {}} // Prevent closing by clicking outside
      >
        <DialogTitle sx={{ textAlign: 'center' }}>
          <ErrorOutlineIcon color="error" sx={{ fontSize: 40, mb: 1 }} />
          <Typography variant="h6">Database Connection Error</Typography>
        </DialogTitle>
        <DialogContent>
          {getStatusContent()}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
          {(retryStatus === 'initial' || retryStatus === 'error') && (
            <Button
              variant="contained"
              onClick={handleRetry}
              color="primary"
              size="large"
            >
              Retry Connection
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  // Wrap the original database functions
  const wrappedSuryadb = wrapDatabase(originalSuryadb, 'suryadb', setShowErrorDialog);
  const wrappedSuryaCommonDb = wrapDatabase(originalSuryaCommonDb, 'surya_commondb', setShowErrorDialog);

  // Override the global database functions
  window.suryadb = wrappedSuryadb;
  window.surya_commondb = wrappedSuryaCommonDb;

  return (
    <>
      {children}
      <ErrorDialog />
    </>
  );
};

// Add global error handler for unhandled rejections
if (typeof window !== 'undefined') {
    window.addEventListener('unhandledrejection', (event) => {
      if (
        event.reason?.name === 'UnknownError' ||
        event.reason?.name === 'DatabaseClosedError' ||
        event.reason?.message?.includes('IndexedDB') // Changed error to event.reason
      ) {
        console.log('Unhandled database error:', event.reason);
        suryadbInstance = null;
        commondbInstance = null;
      }
    });
  }