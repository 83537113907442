import Dexie from "dexie";

const suryadb = () => {

    const db = new Dexie("suryadb");

    db.version(11.13).stores({
      get_all_activities: `id,data`,
      get_construction_progress_activitywise_for_project: `id,data`,
      get_tracker_types_for_project: `id,data`,
      get_construction_progress_activitywise_for_blocks: `id,data`,
      get_tracker_types_for_blocks: `id,data`,
      update_construction_progress: `id,data`,
      update_qc: `id,data`,
      get_blocks: `id,data`,
      get_parts_name: `id,data`,
      get_activity_milestone_for_blocks: `id,data`,
      get_activity_milestone_for_project: `id,data`,
      get_svg_map: `id,data`,
      get_distributed_and_installed_bom_for_project: `id,data`,
      get_total_installed_distributed_quantity: `id,data`,
      get_existing_distributed_installed_quantity_for_trackerrow: `id,data`,
      get_part_per_tracker_type: `id,data`,
      get_pier_legend: `id,data`,
      get_construction_progress_tracker_row_wise: `id,data`,
      get_tracker_type_names: `id,data`,
      get_checklist_activity_construction: `id,data`,
      get_deployed_part_for_activity: `id,data`,
      get_deployed_part_for_overall_project_block: `id,data`,
      get_svg_block_progress_activitywise: `id,data`,
      get_user_details: `id,data`,
      external_punchlist: `id,data`,
      internal_punchlist: `id,data`,
      project_details: `id,data`,
      sync_request_details: `id,data`,
      qc_checklist_mapping: `id,data`,
      qc_progress_internal_piers: `id,data`,
      qc_progress_internal_torquetubes: `id,data`,
      qc_progress_external_piers: `id,data`,
      qc_progress_external_torquetubes: `id,data`,
      qc_progress_trackerrow_internal: `id,data`,
      qc_progress_trackerrow_external: `id,data`,
      get_pier_torquetube_parts_bulk: `id,data`,
      get_bulk_piers_torquetubes: `id,data`,
      d3_xscale_yscale_blockwise: `id,data`,
      qc_custom_response_piers_internal: `id,data`,
      qc_custom_response_piers_external: `id,data`,
      qc_custom_response_tt_internal: `id,data`,
      qc_custom_response_tt_external: `id,data`,
      qc_custom_response_trackerrow_internal: `id,data`,
      qc_custom_response_trackerrow_external: `id,data`,
      get_qc_d3_color: `id,data`,
      zimmerman_table_coordinates: `id,data`,
      get_zimmerman_bulk_piers: `id,data`,
      get_table_wise_pier_part_bom_id_number_wise_count: `id,data`,
    });
    
   return db;

};


export default suryadb;
