import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React, { useEffect, useState } from 'react'
import suryadb from '../../indexedDB/module'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Outlet, useNavigate } from "react-router-dom";
import Header from '../../components/appBar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green, yellow } from '@mui/material/colors';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const SyncRequest = () => {
    const [syncRequestData, setSyncRequestData] = useState([])
    const [failedItems, setFailedItems] = useState([])
    const [value, setValue] = React.useState(0);

    const navigate = useNavigate();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getSyncRequestDetails = async () => {
        let PidsData = []
        let failedItemsData = []
        let data = await suryadb().sync_request_details.toArray()
        if (data.length !== 0) {
            data?.forEach((item) => {
                if (item?.data?.qc) {
                    failedItemsData.push(item);  // If 'qc' key exists, add to withQC
                } else {
                    PidsData.push(item);  // If 'qc' key is absent, add to withoutQC
                }
            });
        }
        setSyncRequestData(PidsData)
        setFailedItems(failedItemsData)
    }

    useEffect(() => {
        getSyncRequestDetails()
    }, [])

    return (
        <Box>
            <Header />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "64px",
                    backgroundColor: "#262223",
                    color: "white",
                }}
            >
                <ArrowBackIcon
                    sx={{ padding: " 0px 20px" }}
                    onClick={() => {
                        navigate("/home/dashboard");
                    }}
                />
                <Typography sx={{ fontWeight: "600", fontSize: "20px" }}>
                    Sync Request Details
                </Typography>
            </Box>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', padding: "20px" }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Pids List" {...a11yProps(0)} />
                    <Tab label="Failed Items" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Pids</TableCell>
                            <TableCell>Created Time</TableCell>
                            <TableCell>Completed Time</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {syncRequestData.length !== 0 && syncRequestData.map((item) => {

                            return (
                                <>
                                    <TableRow>
                                        <TableCell>
                                            {item?.id}
                                        </TableCell>
                                        <TableCell>{item?.data?.updatedTime}</TableCell>
                                        <TableCell>{item?.data?.completedTime}</TableCell>
                                        <TableCell>
                                            {item?.data?.status === 1 ? (
                                                <CheckCircleIcon style={{ color: green[500] }} />  // Green tick for status 1
                                            ) : item?.data?.status === 0 ? (
                                                <CheckCircleIcon style={{ color: yellow[500] }} />  // Yellow tick for status 0
                                            ) : (
                                                <span>No status</span>  // Handle other cases or no status
                                            )}
                                        </TableCell>

                                    </TableRow>
                                </>)
                        })

                        }
                    </TableBody>
                </Table>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Created Time</TableCell>
                            <TableCell>Key</TableCell>
                            <TableCell>Data</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {failedItems.length !== 0 && failedItems.map((item) => {
                            return (
                                <>
                                    <TableRow>
                                        <TableCell>
                                            {item?.data?.type}
                                        </TableCell>
                                        <TableCell>
                                            {item?.data?.updatedTime}
                                        </TableCell>
                                        <TableCell>
                                            {item?.id}
                                        </TableCell>
                                        <TableCell>
                                            {Array.isArray(item?.data?.qc) && item?.data?.qc.map((qcItem, idx) => (
                                                <div key={idx} style={{ marginBottom: '10px' }}>
                                                    <strong>Tracker Row ID:</strong> {qcItem.tracker_row_id} <br />
                                                    <strong>Checkpoint ID:</strong> {qcItem.checkpoint_id} <br />
                                                    <strong>Comment:</strong> {qcItem.comment || 'No Comment'} <br />
                                                    <strong>Status:</strong> {qcItem.status} <br />
                                                    <strong>Type:</strong> {qcItem.type} <br />
                                                    <strong>Pier ID:</strong> {qcItem.pier_id} <br />
                                                    <strong>Response:</strong> {qcItem.response || 'N/A'} <br />
                                                    <hr />
                                                </div>
                                            ))}
                                        </TableCell>
                                    </TableRow>
                                </>
                            )
                        })}
                    </TableBody>
                </Table>
            </CustomTabPanel>
        </Box>
    )
}

export default SyncRequest
