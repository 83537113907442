import { Box, Button, Typography, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import LoginContainer from "../../components/login/loginContainer";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Cookies from "js-cookie";
import { decodeJWT } from "../../utils/jwt";
import { useAtom } from "jotai";
import authAtom from "../../data/authAtom";
import { toast } from 'react-hot-toast'
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { get_login_details } from "../../dal/login";

const Login = () => {
  const [, login] = useAtom(authAtom);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false)
  
  const handleClick = async (e) => {

    try {
      setLoading(true);
      let loginData = await get_login_details({ email: email, password: password })
      let loginResponse = loginData?.data
      if (loginResponse !== null || Object.keys(loginResponse).length > 0 || parseInt(loginData.status) === 200) {
        if (loginResponse?.user?.last_login === null || !loginResponse?.user?.last_login) {
          setLoading(false);
          let logindetails = { email: email, password: password }
          localStorage.setItem("login_details", JSON.stringify(logindetails))
          window.location.href = "/resetpassword"
        }
        else {
          setLoading(false);
          let decoded = decodeJWT(loginResponse["access_token"]);
          Cookies.set("access_token", loginResponse["access_token"], {
            expires: new Date(decoded.exp * 1000),
          });
          Cookies.set("refresh_token", loginResponse["refresh_token"], {
            expires: new Date(decoded.exp * 1000),
          });
          // localStorage.setItem("tenant-name", decoded["organizations"][0]["identifier"]);
          login({ loggedIn: true, data: decoded });
          window.location.href = "/home/dashboard";
          // localStorage.setItem(
          //   "last-sync",
          //   Date.now()
          // );
          localStorage.setItem("construction-act-id", "All")
          localStorage.setItem("org-id", loginResponse.user.org_id)
        }
      } else {
        setLoading(false);
        toast.error("Invalid-Credentials")
      }


      // stored for cache reload api to be removed
      // localStorage.setItem("org-id",loginData.user.org_id)

      // stored for cache reload api to be removed after demo 

    } catch (err) {
      console.log(err);
      toast.error("Invalid-Credentials")
    } finally {
      setLoading(false);
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "cloumn",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <LoginContainer />
      <Box>
        <img src="../../Logo.jpg" alt="logo" />
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "30ch" },
            display: "flex",
            flexDirection: "column",
            paddingTop: "15px",

          }}
        >
          <InputLabel sx={{ paddingLeft: "10px" }}>Email</InputLabel>
          <TextField
            type="email"
            placeholder="john@surya123"
            name="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <InputLabel sx={{ paddingLeft: "10px" }}>Password</InputLabel>
          <Box sx={{ display: "flex", position: "relative" }}>
            {showPassword ? <TextField
              placeholder="Enter your Password"
              type="text"
              required
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              name="password"
            /> : <TextField
              placeholder="Enter your Password"
              type="password"
              required
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              name="password"
            />
            }
            {
              showPassword ?
                <AiOutlineEye fontSize={20} style={{ position: "absolute", top: 25, left: 270 }} onClick={handleShowPassword} />
                :
                <AiOutlineEyeInvisible fontSize={20} style={{ position: "absolute", top: 25, left: 270 }} onClick={handleShowPassword} />
            }

          </Box>
          <Button
            variant="contained"
            style={{ textTransform: "none" }}
            sx={{ margin: "15px 10px 10px 10px" }}
            onClick={handleClick}
          >
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CircularProgress size={20} style={{ color: "white" }} />
                <Typography sx={{ marginX: "10px" }}>Logging in</Typography>
              </Box>
            ) : (
              "Log in"
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
